import React from "react";
import GoToTop from "../../GoToTop";
function PrivacyPolicy() {
  return (
    <>
    
      <GoToTop />
      <div
        className="container-fluid"
        style={{ maxWidth: "170vh", marginTop: "10vh" }}
      >
        <div>
          <h2 className="text-center">
            <img
              style={{ width: "40vh" }}
              alt=""
              src="https://img.freepik.com/free-vector/privacy-policy-concept-illustration_114360-7853.jpg?w=740&t=st=1666079731~exp=1666080331~hmac=dc4cace5ee857c35c874e546bd16189d9c6f703d891cbf49b767260517fdcba4"
            />
          </h2>
          <h1
            className="text-center"
            style={{ fontWeight: "bold", color: "#0072bc", marginTop: "-20px" }}
          >
            Privacy Policy
          </h1>
          <br></br>
          <br></br>

          <p>
            <strong>Effective Date: 18-10-2022</strong>
          </p>
          <h3
            style={{
              backgroundColor: "#cce3f2",
              padding: "8px",
              fontWeight: "bold",
              color: "#0072bc",
              maxWidth: "80vh",
            }}
          >
            Your privacy is important to us
          </h3>

          <p>
            It is WakeWithWellness's policy to respect your privacy regarding
            any information we may collect while operating our website. This
            Privacy Policy applies to{" "}
            <a href="https://www.wakewithwellness.in/">
              {" "}
              www.wakewithwellness.in/
            </a>{" "}
            (hereinafter, "us", "we", or "www.wakewithwellness.in/"). We respect
            your privacy and are committed to protecting personally identifiable
            information you may provide us through the Website. We have adopted
            this privacy policy ("Privacy Policy") to explain what information
            may be collected on our Website, how we use this information, and
            under what circumstances we may disclose the information to third
            parties. This Privacy Policy applies only to information we collect
            through the Website and does not apply to our collection of
            information from other sources.
          </p>
          <p>
            This Privacy Policy, together with the Terms of service posted on
            our Website, set forth the general rules and policies governing your
            use of our Website. Depending on your activities when visiting our
            Website, you may be required to agree to additional terms of
            service.
          </p>

          <h5
            style={{
              backgroundColor: "#cce3f2",
              padding: "8px",
              fontWeight: "bold",
              color: "#0072bc",
              maxWidth: "80vh",
            }}
            id="tableofcontents"
          >
            Contents
          </h5>
          <p>Click below to jump to any section of this privacy policy</p>
          <ul>
            <li>
              <a href="#PII">
                <strong>Personally-Identifying Information</strong>
              </a>
            </li>
            <li>
              <a href="#Security">
                <strong>Security</strong>
              </a>
            </li>
            <li>
              <a href="#ExternalLinks">
                <strong>Links To External Sites</strong>
              </a>
            </li>
            <li>
              <a href="#PIIProtection">
                <strong>
                  Protection of Certain Personally-Identifying Information
                </strong>
              </a>
            </li>
            <li>
              <a href="#Affiliates">
                <strong>Affiliate Disclosure</strong>
              </a>
            </li>
            <li>
              <a href="#Changes">
                <strong>Privacy Policy Changes</strong>
              </a>
            </li>
            <li>
              <a href="#Credit">
                <strong>Contact Information &amp; Credit</strong>
              </a>
            </li>
          </ul>
          <br></br>
          <h5
            id="PII"
            style={{
              backgroundColor: "#cce3f2",
              padding: "8px",
              fontWeight: "bold",
              color: "#0072bc",
              maxWidth: "80vh",
            }}
          >
            Personally-Identifying Information
          </h5>
          <p>
            Certain visitors to WakeWithWellness's websites choose to interact
            with WakeWithWellness in ways that require WakeWithWellness to
            gather personally-identifying information. The amount and type of
            information that WakeWithWellness gathers depends on the nature of
            the interaction. For example, we ask visitors who leave a comment at
            https://www.wakewithwellness.in/ to provide a username and email
            address.
          </p>

          <br></br>

          <h5
            id="Security"
            style={{
              backgroundColor: "#cce3f2",
              padding: "8px",
              fontWeight: "bold",
              color: "#0072bc",
              maxWidth: "80vh",
            }}
          >
            Security
          </h5>
          <p>
            The security of your Personal Information is important to us, but
            remember that no method of transmission over the Internet, or method
            of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security.
          </p>
          <br></br>

          <h5
            style={{
              backgroundColor: "#cce3f2",
              padding: "8px",
              fontWeight: "bold",
              color: "#0072bc",
              maxWidth: "80vh",
            }}
            id="ExternalLinks"
          >
            Links To External Sites
          </h5>
          <p>
            Our Service may contain links to external sites that are not
            operated by us. If you click on a third party link, you will be
            directed to that third party's site. We strongly advise you to
            review the Privacy Policy and terms of service of every site you
            visit.
          </p>
          <p>
            We have no control over, and assume no responsibility for the
            content, privacy policies or practices of any third party sites,
            products or services.
          </p>

          <br></br>

          <h5
            style={{
              backgroundColor: "#cce3f2",
              padding: "8px",
              fontWeight: "bold",
              color: "#0072bc",
              maxWidth: "80vh",
            }}
            id="PIIProtection"
          >
            Protection of Certain Personally-Identifying Information
          </h5>
          <p>
            WakeWithWellness discloses potentially personally-identifying and
            personally-identifying information only to those of its employees,
            contractors and affiliated organizations that (i) need to know that
            information in order to process it on WakeWithWellness's behalf or
            to provide services available at WakeWithWellness's website, and
            (ii) that have agreed not to disclose it to others. Some of those
            employees, contractors and affiliated organizations may be located
            outside of your home country; by using WakeWithWellness's website,
            you consent to the transfer of such information to them.
            WakeWithWellness will not rent or sell potentially
            personally-identifying and personally-identifying information to
            anyone. Other than to its employees, contractors and affiliated
            organizations, as described above, WakeWithWellness discloses
            potentially personally-identifying and personally-identifying
            information only in response to a subpoena, court order or other
            governmental request, or when WakeWithWellness believes in good
            faith that disclosure is reasonably necessary to protect the
            property or rights of WakeWithWellness, third parties or the public
            at large.
          </p>
          <p>
            If you are a registered user of https://www.wakewithwellness.in/ and
            have supplied your email address, WakeWithWellness may occasionally
            send you an email to tell you about new features, solicit your
            feedback, or just keep you up to date with what's going on with
            WakeWithWellness and our products. We primarily use our blog to
            communicate this type of information, so we expect to keep this type
            of email to a minimum. If you send us a request (for example via a
            support email or via one of our feedback mechanisms), we reserve the
            right to publish it in order to help us clarify or respond to your
            request or to help us support other users. WakeWithWellness takes
            all measures reasonably necessary to protect against the
            unauthorized access, use, alteration or destruction of potentially
            personally-identifying and personally-identifying information.
          </p>

          <br></br>

          <h5
            style={{
              backgroundColor: "#cce3f2",
              padding: "8px",
              fontWeight: "bold",
              color: "#0072bc",
              maxWidth: "80vh",
            }}
            id="Affiliates"
          >
            Affiliate Disclosure
          </h5>
          <p>
            This site uses affiliate links and does earn a commission from
            certain links. This does not affect your purchases or the price you
            may pay.
          </p>

          <br></br>

          <h5
            style={{
              backgroundColor: "#cce3f2",
              padding: "8px",
              fontWeight: "bold",
              color: "#0072bc",
              maxWidth: "80vh",
            }}
            id="Changes"
          >
            Privacy Policy Changes
          </h5>
          <p>
            Although most changes are likely to be minor, WakeWithWellness may
            change its Privacy Policy from time to time, and in
            WakeWithWellness's sole discretion. WakeWithWellness encourages
            visitors to frequently check this page for any changes to its
            Privacy Policy. Your continued use of this site after any change in
            this Privacy Policy will constitute your acceptance of such change.
          </p>

          <br></br>

          <h5
            style={{
              backgroundColor: "#cce3f2",
              padding: "8px",
              fontWeight: "bold",
              color: "#0072bc",
              maxWidth: "80vh",
            }}
            id="Credit"
          >
            Contact Information &amp; Credit
          </h5>
          <p>
            This privacy policy was created at{" "}
            <a
              href="https://privacyterms.io/privacy-policy-generator/"
              target="_blank"
            >
              privacyterms.io privacy policy generator
            </a>
            . If you have any questions about our Privacy Policy, please contact
            us via <a href="mailto:wakewithwellness@gmail.com">email</a> or{" "}
            <a href="tel:">phone</a>.
          </p>

          <p>
            <a href="#tableofcontents">Back to table of contents</a>
          </p>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
