import React from "react";
import "../assets/css/Home.css";
import { Link } from "react-router-dom";

import GoToTop from "../../GoToTop";

function Cat() {
  return (
    <>
      <GoToTop />
      <section
        id="galleryy"
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: "9vh" }}
      >
        <div className="container" style={{ marginBottom: "30px" }}>
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <h1>Video Gallery</h1>
              {/* <h2>To help with community projects</h2> */}
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features" style={{ marginTop: "5vh" }}>
        <div className="container-fluid" style={{ maxWidth: "200vh" }}>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-4 my-2">
              <iframe
                width="100%"
                height="280"
                src="https://www.youtube.com/embed/5m6kp4SscgU"
                title="Glimpse | 2022-23 |  Wake With Wellness"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 my-2">
              <iframe
                width="100%"
                height="280"
                src="https://www.youtube.com/embed/kLIl8M79_A0"
                title="RJ Paahi &amp; Arghadeep Baruah | Chief guests | NE Organization Convene&#39;22 | Wake With Wellness"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 my-2">
              <iframe
                width="100%"
                height="280"
                src="https://www.youtube.com/embed/GE-deWim7D4"
                title="তুমি মাথো মোৰ | Tumi Mathu Mur | Aliva Kalita | NE Organization Convene&#39;22 | Wake With Wellness"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 my-2">
              <iframe
                width="100%"
                height="280"
                src="https://www.youtube.com/embed/AcRAts6xOZM"
                title="Mental Health   Sprucing up our souls"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 my-2">
              <iframe
                width="100%"
                height="280"
                src="https://www.youtube.com/embed/uP1U9gD2KA0"
                title="Glimpses | 2021-22 | Wake With Wellness"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 my-2">
              <iframe
                width="100%"
                height="280"
                src="https://www.youtube.com/embed/EmqRYOKdmQY"
                title="Wellness Drive : Medical &amp; Health Checkup Camp | WakeWithWellness | Dhemaji |"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 my-2">
              <iframe
                width="100%"
                height="280"
                src="https://www.youtube.com/embed/972P8cEFNTE"
                title="MENTAL HEALTH AWARENESS | Miss Trishna Das | Wake With Wellness"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 my-2">
              <iframe
                width="100%"
                height="280"
                src="https://www.youtube.com/embed/q9PqM6-vEsQ"
                title="PRABHATI- The Dawn of a Vital Outlook"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 my-2">
              <iframe
                width="100%"
                height="280"
                src="https://www.youtube.com/embed/MN5FAKXI3OY"
                title="Wellness Drive-WakeWithWellness"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cat;
