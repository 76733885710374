import React, { useState } from "react";
import "./assets/css/Home.css";
import "../App.css";
import { Link } from "react-router-dom";
import GoToTop from "../GoToTop";

import about from "./assets/image/about.JPG";
import collab from "./assets/image/Collaborators.jpg";
import collab1 from "./assets/image/Collab1.jpg";
import sponsors from "./assets/image/Sponsors.jpg";
import sponsors1 from "./assets/image/Sponsors1.jpg";

import "./assets/css/Team.css";
function Home() {
  return (
    <>
      {" "}
      <GoToTop />
      <div>
        <section
          id="hero"
          className="d-flex align-items-center justify-content-center"
        >
          <div className="container" style={{ marginBottom: "30px" }}>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <h1>WakeWithWellness</h1>
                <h2>We Are People Caring For People</h2>
              </div>
            </div>

            <div className="row gy-4 mt-5 justify-content-center">
              {/* <div className="col-xl-2 col-md-4 my-1">
                <Link to="/recruitment">
                  <div className="icon-box" style={{cursor: "pointer"}}>
                    <i className="fa fa-users" aria-hidden="true"></i>
                    <h3>
                      <Link to="/recruitment">Recruitment</Link>
                    </h3>
                  </div>
                </Link>
              </div> */}

              <div className="col-xl-2 col-md-4 my-1">
                <Link to="/recruitment">
                  <div className="icon-box" style={{ cursor: "pointer" }}>
                    <i className="fa fa-handshake-o" aria-hidden="true"></i>
                    <h3>
                      <Link to="/recruitment">Join Us</Link>
                    </h3>
                  </div>
                </Link>
              </div>

              <div className="col-xl-2 col-md-4 my-1">
                <Link to="/donation">
                  <div className="icon-box" style={{ cursor: "pointer" }}>
                    <i className="fa fa-money" aria-hidden="true"></i>
                    <h3>
                      <Link to="/donation">Contribute</Link>
                    </h3>
                  </div>
                </Link>
              </div>

              <div className="col-xl-2 col-md-4 my-1">
                <Link to="/about">
                  <div className="icon-box" style={{ cursor: "pointer" }}>
                    <i className="fa fa-user" aria-hidden="true"></i>
                    <h3>
                      <Link to="/about">About Us</Link>
                    </h3>
                  </div>
                </Link>
              </div>

              <div className="col-xl-2 col-md-4 my-1">
                <Link to="/gallery">
                  <div className="icon-box" style={{ cursor: "pointer" }}>
                    <i className="fa fa-photo" aria-hidden="true"></i>
                    <h3>
                      <Link to="/gallery">Gallery</Link>
                    </h3>
                  </div>
                </Link>
              </div>

              <div className="col-xl-2 col-md-4 my-1">
                <Link to="/blogs">
                  <div className="icon-box" style={{ cursor: "pointer" }}>
                    <i className="fa fa-book" aria-hidden="true"></i>
                    <h3>
                      <Link to="/blogs">Blogs</Link>
                    </h3>
                  </div>
                </Link>
              </div>

              <div id="hideeee" className="col-xl-2 col-md-4 my-1">
                <Link to="/team">
                  <div className="icon-box" style={{ cursor: "pointer" }}>
                    <i className="fa fa-users" aria-hidden="true"></i>
                    <h3>
                      <Link to="/team">Our Team</Link>
                    </h3>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <div style={{ marginTop: "50px" }}></div>

        {/* ABOUT SECTION */}

        <section id="about" className="about">
          <div className="container-fluid" style={{ maxWidth: "200vh" }}>
            <div className="row">
              <div className="col-lg-2" style={{ width: "2vh" }}></div>
              <div
                className="col-lg-4 order-1 order-lg-2"
                style={{ float: "right" }}
              >
                <img src={about} className="img-fluid" alt="" />
              </div>

              <div
                className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
                id="con"
              >
                <h2
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  WHO WE ARE
                </h2>
                <p style={{ textAlign: "justify", fontSize: "14px" }}>
                  Wake With Wellness is a community-based organisation,
                  established to exercise good actions for the rehabilitation of
                  communities regarding health and social awareness without
                  ascertaining the foundation of legal norms. The focus is to
                  spread and amplify health concerns and conveyance of
                  information crucial to a healthy living.
                </p>
                <p style={{ textAlign: "justify", fontSize: "14px" }}>
                  Working in tandem to our cause of spreading awareness and
                  delivery of palliative and healthcare services beyond bounded
                  limits, we the members of Wake With Wellness intent to serve
                  and care people and generations with a commitment of guiding
                  all fellow beings towards a healthy and robust existence along
                  with an attempt to maximise provisions of health care which
                  effortlessly reaches out to the needful.{" "}
                </p>
                <p style={{ textAlign: "justify", fontSize: "14px" }}>
                  With this vision of steering people for a hale and hearty life
                  form,{" "}
                  <span style={{ fontWeight: "bold" }}>
                    ‘we are People Caring For People’
                  </span>
                </p>

                <br></br>
                <Link
                  id="morebtn"
                  style={{ backgroundColor: "#04C3FF" }}
                  to="/about"
                >
                  KNOW MORE
                </Link>
              </div>
            </div>
          </div>
        </section>

        <div style={{ marginTop: "60px" }}></div>

        <section
          id="about"
          className="about"
          style={{
            background:
              "linear-gradient(95.24deg, #038CB7 0%, #04C3FF 137.16%)",
            color: "white",
            paddingTop: "25px",
            paddingBottom: "25px",
          }}
        >
          <div className="container-fluid" style={{ maxWidth: "200vh" }}>
            <div className="content">
              <p style={{ fontStyle: "italic", fontSize: "14px" }}>
                Human development summary capturing achievements in the HDI and
                complementary metrics that estimate gender gaps, inequality,
                planetary pressures and poverty.
              </p>

              <h4
                style={{
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Human Development Index
              </h4>
              <p style={{ fontSize: "14px", textAlign: "justify" }}>
                The HDI is a summary measure for assessing long-term progress in
                three basic dimensions of human development: a long and healthy
                life, access to knowledge and a decent standard of living.
                India's HDI value for 2021 is 0.633— which put the country in
                the Medium human development category—positioning it at 132 out
                of 191 countries and territories.
                <br></br>
                <br></br>
                Between 1990 and 2021, India's HDI value changed from 0.434 to
                0.633, an change of 45.9 percent.
                <br></br>
                <br></br>
                Between 1990 and 2021, India's life expectancy at birth changed
                by 8.6 years, mean years of schooling changed by 3.9 years and
                expected years of schooling changed by 3.9 years. India's GNI
                per capita changed by about 268.1 percent between 1990 and 2021.
              </p>

              <br></br>
              <a
                style={{ color: "yellow", underline: "true" }}
                href="https://hdr.undp.org/data-center/specific-country-data#/countries/IND"
                target="_blank"
              >
                <i class="fa fa-link" aria-hidden="true"></i> More Insights on
                HDI
              </a>
            </div>
          </div>
        </section>

        <div style={{ marginTop: "60px" }}></div>

        <section id="portfolio" className="portfolio">
          <div
            className="content container-fluid"
            style={{ maxWidth: "200vh" }}
          >
            <div className="section-title" data-aos="fade-left">
              <h2
                style={{
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Gallery
              </h2>
            </div>

            <div className="row">
              {/* <!--1--> */}
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FL2.jpg?alt=media&token=01624696-123b-4097-9626-19dd37ad977f"
                    alt=""
                  />
                </p>
              </div>

              {/* <!--2--> */}
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/prabhati%2F2.jpeg?alt=media&token=7fa1d527-a5ba-4c99-8b1a-ae20aee96047"
                    alt=""
                  />
                </p>
              </div>

              {/* <!--3--> */}
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/clothdonationDhmj%2Fclothdonate1.JPG?alt=media&token=6a55a215-5ccf-43a9-865a-c5b61dc61119"
                    alt=""
                  />
                </p>
              </div>

              {/* <!--4--> */}
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/MedicalCampDhmj%2F5.JPG?alt=media&token=4232082e-75de-4fde-b6e5-a6de6cc8d43d"
                    alt=""
                  />
                </p>
              </div>

              {/* <!--5--> */}
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2Fcleanliness1a.jpeg?alt=media&token=f9ab2aab-67e0-4871-83cb-8d34e182d241"
                    alt=""
                  />
                </p>
              </div>

              {/* <!--6--> */}
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FCleanlinessD2d.jpeg?alt=media&token=6c07a572-430d-47b1-8feb-24ad7a92db97"
                    alt=""
                  />
                </p>
              </div>

              {/* <!--7--> */}
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FHepah_1.jpg?alt=media&token=354e96da-4dae-485b-a762-bceb26fc2725"
                    alt=""
                  />
                </p>
              </div>

              {/* <!--8--> */}
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FYogaWorkshop%2Fyoga23b.jpeg?alt=media&token=93ab811e-73fc-4997-89df-edc9a226eec8"
                    alt=""
                  />
                </p>
              </div>

              <div className="container-fluid">
                <br></br>
                <div className="text-right">
                  <Link
                    id="morebtn"
                    style={{ backgroundColor: "#04C3FF" }}
                    to="/gallery"
                  >
                    SEE MORE PHOTOS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div style={{ marginTop: "50px" }}></div>

        {/* NEConvene SECTION */}

        <section id="neconvene" className="neconvene">
          <div className="container-fluid" style={{ maxWidth: "200vh" }}>
            <div className="section-title" data-aos="fade-left">
              <h2
                style={{
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                NE Organization Convene'22
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-4" style={{ float: "right" }}>
                <div
                  id="carouselExampleIndicators"
                  class="carousel slide"
                  data-ride="carousel"
                >
                  <ol class="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="0"
                      class="active"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="1"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="2"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="3"
                    ></li>
                  </ol>
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img
                        class="d-block w-100"
                        src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F11.JPG?alt=media&token=f06a9116-63d9-4096-a3ce-3d4e91809554"
                        alt="1"
                      />
                    </div>
                    <div class="carousel-item">
                      <img
                        class="d-block w-100"
                        src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F24.JPG?alt=media&token=1850d5bf-c686-4253-8f23-7b17b12afe62"
                        alt="2"
                      />
                    </div>
                    <div class="carousel-item">
                      <img
                        class="d-block w-100"
                        src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F20.JPG?alt=media&token=33cdb2e1-950f-48fb-a36d-36d7fe1342da"
                        alt="3"
                      />
                    </div>
                    <div class="carousel-item">
                      <img
                        class="d-block w-100"
                        src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F9.JPG?alt=media&token=294e4434-0aa6-42ee-9d57-77eeaaadd4bf"
                        alt="4"
                      />
                    </div>
                  </div>
                  <a
                    class="carousel-control-prev"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a
                    class="carousel-control-next"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>

              <div
                className="col-lg-6"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: "14px",
                    marginTop: "5px",
                  }}
                >
                  <i>
                    “If you can visualize it, if you can dream it, there’s some
                    way to do it.”
                  </i>
                  <br></br>
                  <br></br>
                  It is the matter of immense pride that Wake With Wellness,
                  with such a wondrous visuality, successfully conducted and
                  hosted the Mega Event of{" "}
                  <strong>NE Organisation Convene-2022</strong>, on{" "}
                  <strong>27th December, 2022</strong>. The event was an
                  amalgamation of <strong>12 organisations</strong>, working for
                  the upliftment and the betterment of our society.
                  <br></br>
                  <br></br>
                  <span id="textconvene">
                    The event was graced with the auspicious presence of{" "}
                    <strong>Arghadeep Baruah</strong> and{" "}
                    <strong>Pranamika Bhuyan (RJ Pahi)</strong> as guests of
                    honour for the day, and were felicitated with a phulam
                    gamusa, a momentous and sapling along with the other
                    organisations.
                    <br></br>
                    <br></br>
                    <br></br>
                  </span>
                </p>
                <div className="text-right">
                  <Link
                    id="morebtn"
                    style={{ backgroundColor: "#04C3FF" }}
                    to="/neconvene22"
                  >
                    KNOW MORE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div style={{ marginTop: "80px" }}></div>

        <section
          id="features"
          className="features"
          style={{
            paddingTop: "80px",
            paddingBottom: "50px",
          }}
        >
          <div className="container-fluid" style={{ maxWidth: "200vh" }}>
            <div className="section-title">
              <h2
                style={{
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Events
              </h2>
            </div>

            <div className="row">
              <div className="col-lg-3  my-2">
                <div className="blog grid-blog" id="images">
                  <div className="blog-image">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FFloodDrive23%2Frrrr.jpg?alt=media&token=5704747c-62dd-4e94-846e-37f773a5a6af"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div
                    className="blog-content"
                    style={{ backgroundColor: "#fff", marginTop: "-31.5px" }}
                  >
                    <h5
                      style={{
                        fontFamily:
                          "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                        fontWeight: "bold",
                        color: "#038CB7",
                      }}
                    >
                      Flood Distribution Drive - Project Jivanam
                    </h5>
                    <p>
                      Date:{" "}
                      <span style={{ color: "#888888" }}>
                        21st September 2023
                      </span>
                      <br></br>Venue:{" "}
                      <span style={{ color: "#888888" }}>
                        Kalgachia, Barpeta, Assam
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3  my-2">
                <div className="blog grid-blog" id="images">
                  <div className="blog-image">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FYogaWorkshop%2Fyoga23b.jpeg?alt=media&token=93ab811e-73fc-4997-89df-edc9a226eec8"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div
                    className="blog-content"
                    style={{ backgroundColor: "#fff", marginTop: "-31.5px" }}
                  >
                    <h5
                      style={{
                        fontFamily:
                          "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                        fontWeight: "bold",
                        color: "#038CB7",
                      }}
                    >
                      Yoga Workshop : Vasudhaiva Kutumbakam
                    </h5>
                    <p>
                      Date:{" "}
                      <span style={{ color: "#888888" }}>24th June 2023</span>
                      <br></br>Venue:{" "}
                      <span style={{ color: "#888888" }}>
                        Parijat Academy, Pamohi, Guwahati
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3  my-2">
                <div className="blog grid-blog" id="images">
                  <div className="blog-image">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/MentalHealthWorkshopsPhotos%2Fmvm3_a.jpeg?alt=media&token=055db482-d3a7-4e0f-a574-2433ccf0c3b0"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div
                    className="blog-content"
                    style={{ backgroundColor: "#fff", marginTop: "-31.5px" }}
                  >
                    <h5
                      style={{
                        fontFamily:
                          "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                        fontWeight: "bold",
                        color: "#038CB7",
                      }}
                    >
                      Mental Health Awareness Workshop - Project Jivanam
                    </h5>
                    <p>
                      Date:{" "}
                      <span style={{ color: "#888888" }}>6th May 2023</span>
                      <br></br>Venue:{" "}
                      <span style={{ color: "#888888" }}>
                        Maharshi Vidya Mandir-III, Guwahati
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3  my-2">
                <div className="blog grid-blog" id="images">
                  <div className="blog-image">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FHepah_1.jpg?alt=media&token=354e96da-4dae-485b-a762-bceb26fc2725"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div
                    className="blog-content"
                    style={{ backgroundColor: "#fff", marginTop: "-31.5px" }}
                  >
                    <h5
                      style={{
                        fontFamily:
                          "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                        fontWeight: "bold",
                        color: "#038CB7",
                      }}
                    >
                      Hepah : From Welfare to Well-being
                    </h5>
                    <p>
                      Date:{" "}
                      <span style={{ color: "#888888" }}>
                        14th November 2022
                      </span>
                      <br></br>Venue:{" "}
                      <span style={{ color: "#888888" }}>
                        Brahmaputra Children Home, Guwahati
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="container-fluid">
                <br></br>
                <br></br>
                <div className="text-right">
                  <Link
                    id="morebtn"
                    style={{ backgroundColor: "#04C3FF" }}
                    to="/events"
                  >
                    SEE MORE EVENTS
                  </Link>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: "10px" }}></div>
          </div>
        </section>

        <div style={{ marginTop: "20px" }}></div>

        <section
          id="features"
          className="features"
          style={{
            backgroundColor: "#f2f2f2",
            paddingTop: "80px",
            paddingBottom: "50px",
          }}
        >
          <div className="container-fluid" style={{ maxWidth: "200vh" }}>
            <div className="section-title">
              <h2
                style={{
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Video Gallery
              </h2>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-4 my-2">
                <div
                  className="blog grid-blog"
                  style={{ borderRadius: "0" }}
                  id="images"
                >
                  <iframe
                    width="100%"
                    height="280"
                    src="https://www.youtube.com/embed/5m6kp4SscgU"
                    title="Glimpse | 2022-23 |  Wake With Wellness"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>

              <div className="col-sm-6 col-md-6 col-lg-4 my-2">
                <div
                  className="blog grid-blog"
                  style={{ borderRadius: "0" }}
                  id="images"
                >
                  <iframe
                    width="100%"
                    height="280"
                    src="https://www.youtube.com/embed/q9PqM6-vEsQ"
                    title="PRABHATI- The Dawn of a Vital Outlook"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>

              <div className="col-sm-6 col-md-6 col-lg-4 my-2">
                <div
                  className="blog grid-blog"
                  style={{ borderRadius: "0" }}
                  id="images"
                >
                  <iframe
                    width="100%"
                    height="280"
                    src="https://www.youtube.com/embed/uP1U9gD2KA0"
                    title="Glimpses | 2021-22 | Wake With Wellness"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>

              <div className="container-fluid" style={{ marginTop: "7vh" }}>
                <div className="text-right">
                  <Link
                    id="morebtn"
                    style={{ backgroundColor: "#04C3FF" }}
                    to="/videos"
                  >
                    WATCH MORE VIDEOS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div style={{ marginTop: "50px" }}></div>

        <section id="features" className="features">
          <div className="container-fluid" style={{ maxWidth: "200vh" }}>
            {/* <div class="section-title">
              <h2
                style={{
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                TRIBUTE
              </h2>
            </div> */}

            <div className="row" id="pcsponsors">
              <div className="col-1"></div>
              <div className="col-2">
                <img
                  style={{ width: "100%" }}
                  src="https://firebasestorage.googleapis.com/v0/b/files-bf645.appspot.com/o/AnkitaSingh.png?alt=media&token=70c4fbd2-122d-4243-9916-68f7901fad9e"
                />
              </div>
              <div className="col-8 my-2">
                <h3 style={{ color: "#4183c4" }}>
                  ANKITA SINGH <br></br>
                  <span
                    style={{
                      color: "grey",
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                  >
                    4th September, 2023
                  </span>
                </h3>
                <p>
                  A kind heart yet a brave soul, 24 year old Ankita was a part
                  of WWW’s PR team and had done amazing work. She might have
                  left us behind to cradle in the bosom of mother earth but we
                  shall forever etch her memories in our hearts, minds and
                  souls. Let us keep Ankita's family in our thoughts as they go
                  through this difficult time.
                  <br></br>
                  <br></br>God truly only picks the good ones to keep close to
                  him. May her soul rest in eternal peace.
                </p>
              </div>
            </div>
            <div className="row" id="mbsponsors">
              <div className="col-4">
                <img
                  style={{ width: "100%" }}
                  src="https://firebasestorage.googleapis.com/v0/b/files-bf645.appspot.com/o/AnkitaSingh.png?alt=media&token=70c4fbd2-122d-4243-9916-68f7901fad9e"
                />
              </div>
              <div className="col-8">
                <h3 style={{ color: "#4183c4" }}>
                  ANKITA SINGH <br></br>
                  <span
                    style={{
                      color: "grey",
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                  >
                    4th September, 2023
                  </span>
                </h3>
                <p style={{ fontSize: "11px", textAlign: "justify" }}>
                  A kind heart yet a brave soul, 24 year old Ankita was a part
                  of WWW’s PR team and had done amazing work. She might have
                  left us behind to cradle in the bosom of mother earth but we
                  shall forever etch her memories in our hearts, minds and
                  souls. Let us keep Ankita's family in our thoughts as they go
                  through this difficult time.
                  <br></br>
                  <br></br>God truly only picks the good ones to keep close to
                  him. May her soul rest in eternal peace.
                </p>
              </div>
            </div>
          </div>
        </section>

        <div style={{ marginTop: "50px" }}></div>
        {/* BLOG SECTION */}

        <section
          id="features"
          className="features"
          style={{
            backgroundColor: "#f2f2f2",
            paddingTop: "80px",
            paddingBottom: "50px",
          }}
        >
          <div className="container-fluid" style={{ maxWidth: "200vh" }}>
            <div class="section-title">
              <h2
                style={{
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                BLOGS
              </h2>
            </div>

            <div className="row">
              {/* -------------------Blog 1---------------------- */}

              <div class="col-lg-3">
                <div
                  className="blog grid-blog"
                  style={{ backgroundColor: "#fff", borderRadius: "8px" }}
                >
                  <div className="blog-title" style={{ borderRadius: "8px" }}>
                    <h3>
                      <Link
                        style={{ color: "#0072bc", textTransform: "uppercase" }}
                        to="/blog/9"
                      >
                        THRIVE WITH HOPE!
                      </Link>
                    </h3>
                  </div>

                  <div class="blog-image" style={{ marginTop: "-7vh" }}>
                    <img
                      class="img-fluid"
                      src="/assets/images/blog6.jpeg"
                      style={{
                        width: "100%",
                        height: "30vh",
                        objectFit: "cover",
                      }}
                      alt="Not available"
                    />
                  </div>
                  <div
                    class="blog-content"
                    style={{ marginTop: "-40px", backgroundColor: "#fff" }}
                  >
                    <div
                      class="blogItem-desc"
                      style={{
                        textAlign: "justify",
                        height: "90px",
                        overflow: "hidden",
                      }}
                    >
                      <p>
                        We say that it is the strength that keeps us going.
                        <br />
                        It is the will that keeps us going,
                        <br />
                        It is the aim that keeps us going
                        <br />
                        It is the faith that keeps us going!
                      </p>
                    </div>
                    <div className="text-right">
                      <Link
                        style={{ marginTop: "15px", color: "#0072bc" }}
                        to={`/blog/9`}
                        class="read-more"
                      >
                        Continue reading{" "}
                        <i
                          class="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                    <div class="blog-info clearfix">
                      <div class="post-left">
                        <div className="blogItem-author">
                          <p style={{ fontStyle: "italic" }}>- Sangeeta Sen</p>
                        </div>
                      </div>
                      <div class="post-right">
                        <ul>
                          <li>
                            <i
                              style={{ color: "grey" }}
                              class="fa fa-calendar"
                            ></i>
                            <span>April 30, 2022</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* -------------------Blog 2---------------------- */}

              <div class="col-lg-3">
                <div
                  className="blog grid-blog"
                  style={{ backgroundColor: "#fff", borderRadius: "8px" }}
                >
                  <div className="blog-title" style={{ borderRadius: "8px" }}>
                    <h3>
                      <Link
                        style={{ color: "#0072bc", textTransform: "uppercase" }}
                        to="/blog/15"
                      >
                        Breaking the Silence
                      </Link>
                    </h3>
                  </div>

                  <div class="blog-image" style={{ marginTop: "-7vh" }}>
                    <img
                      class="img-fluid"
                      src="/assets/images/blog15.jpg"
                      style={{
                        width: "100%",
                        height: "30vh",
                        objectFit: "cover",
                      }}
                      alt="Not available"
                    />
                  </div>
                  <div
                    class="blog-content"
                    style={{ marginTop: "-40px", backgroundColor: "#fff" }}
                  >
                    <div
                      class="blogItem-desc"
                      style={{
                        textAlign: "justify",
                        height: "90px",
                        overflow: "hidden",
                      }}
                    >
                      <p>
                        <b>
                          How Smoking, Alcohol and Illicit Drug Use Affect Us
                          All
                        </b>
                        <br></br>I know, I know, this is a taboo topic. "This is
                        just some ancient curse that has plagued humanity for
                        centuries and you can’t do anything about it,
                        Chinmoy!"...
                      </p>
                    </div>
                    <div className="text-right">
                      <Link
                        style={{ marginTop: "15px", color: "#0072bc" }}
                        to={`/blog/15`}
                        class="read-more"
                      >
                        Continue reading{" "}
                        <i
                          class="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                    <div class="blog-info clearfix">
                      <div class="post-left">
                        <div className="blogItem-author">
                          <p style={{ fontStyle: "italic" }}>
                            - Chinmoy Baruah
                          </p>
                        </div>
                      </div>
                      <div class="post-right">
                        <ul>
                          <li>
                            <i
                              style={{ color: "grey" }}
                              class="fa fa-calendar"
                            ></i>
                            <span>April 25, 2023</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* -------------------Blog 3---------------------- */}

              <div class="col-lg-3">
                <div
                  className="blog grid-blog"
                  style={{ backgroundColor: "#fff", borderRadius: "8px" }}
                >
                  <div className="blog-title" style={{ borderRadius: "8px" }}>
                    <h3>
                      <Link
                        style={{ color: "#0072bc", textTransform: "uppercase" }}
                        to="/blog/8"
                      >
                        বৃক্ক ৰোগৰ কিছু তথ্য আৰু একান্ত অনুভৱ
                      </Link>
                    </h3>
                  </div>

                  <div class="blog-image" style={{ marginTop: "-7vh" }}>
                    <img
                      class="img-fluid"
                      src="/assets/images/6.jpeg"
                      style={{
                        width: "100%",
                        height: "30vh",
                        objectFit: "cover",
                      }}
                      alt="Not available"
                    />
                  </div>
                  <div
                    class="blog-content"
                    style={{ marginTop: "-40px", backgroundColor: "#fff" }}
                  >
                    <div
                      class="blogItem-desc"
                      style={{
                        textAlign: "justify",
                        height: "90px",
                        overflow: "hidden",
                      }}
                    >
                      <p style={{ textAlign: "justify", marginBottom: "5px" }}>
                        হঠাতে জীৱনলৈ বিপর্যয় নামি আহিল। কি কৰোঁ... কি নকৰোঁ...
                        । মোৰ নিকটতমৰ দুয়োটা বৃক্কই{" "}
                        <span style={{ fontWeight: "600" }}>(Kidney)</span> বিকল
                        হ'ল। তাকে৷ ডাক্তৰৰ ভুল ঔষধৰ বাবে। হে ভগৱান... কি
                        কৰোঁ.......
                      </p>
                    </div>
                    <div className="text-right">
                      <Link
                        style={{ marginTop: "15px", color: "#0072bc" }}
                        to={`/blog/8`}
                        class="read-more"
                      >
                        Continue reading{" "}
                        <i
                          class="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                    <div class="blog-info clearfix">
                      <div class="post-left">
                        <div className="blogItem-author">
                          <p style={{ fontStyle: "italic" }}>
                            - নিবেদিতা বড়া সন্দিকৈ
                          </p>
                        </div>
                      </div>
                      <div class="post-right">
                        <ul>
                          <li>
                            <i
                              style={{ color: "grey" }}
                              class="fa fa-calendar"
                            ></i>
                            <span>July 6, 2021</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* -------------------Blog 4---------------------- */}

              <div class="col-lg-3">
                <div
                  className="blog grid-blog"
                  style={{ backgroundColor: "#fff", borderRadius: "8px" }}
                >
                  <div className="blog-title" style={{ borderRadius: "8px" }}>
                    <h3>
                      <Link
                        style={{ color: "#0072bc", textTransform: "uppercase" }}
                        to="/blog/5"
                      >
                        The Dumpster Girl
                      </Link>
                    </h3>
                  </div>

                  <div class="blog-image" style={{ marginTop: "-7vh" }}>
                    <img
                      class="img-fluid"
                      src="/assets/images/blog3.jpg"
                      style={{
                        width: "100%",
                        height: "30vh",
                        objectFit: "cover",
                      }}
                      alt="Not available"
                    />
                  </div>
                  <div
                    class="blog-content"
                    style={{ marginTop: "-40px", backgroundColor: "#fff" }}
                  >
                    <div
                      class="blogItem-desc"
                      style={{
                        textAlign: "justify",
                        height: "90px",
                        overflow: "hidden",
                      }}
                    >
                      <p style={{ textAlign: "justify", marginBottom: "5px" }}>
                        My name is Tori Morgenstern. And this is my incidents
                        story. <br></br>
                        <br></br>So, yes as I already mentioned my name and I
                        turn 17 this year. I study in St. Peter’s High School. I
                        am a straight studious A1 student . I wear spectacles. I
                        have won many quiz competitions, Olympiads etc...
                      </p>
                    </div>
                    <div className="text-right">
                      <Link
                        style={{ marginTop: "15px", color: "#0072bc" }}
                        to={`/blog/5`}
                        class="read-more"
                      >
                        Continue reading{" "}
                        <i
                          class="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                    <div class="blog-info clearfix">
                      <div class="post-left">
                        <div className="blogItem-author">
                          <p style={{ fontStyle: "italic" }}>
                            - Krishnakshi Majumdar
                          </p>
                        </div>
                      </div>
                      <div class="post-right">
                        <ul>
                          <li>
                            <i
                              style={{ color: "grey" }}
                              class="fa fa-calendar"
                            ></i>
                            <span>June 03, 2021</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid">
                <br></br>
                <br></br>
                <div className="text-right">
                  <Link
                    id="morebtn"
                    style={{ backgroundColor: "#04C3FF" }}
                    to="/blogs"
                  >
                    READ MORE BLOGS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div style={{ marginTop: "50px" }}></div>

        <section id="team" className="team">
          <div class="container-fluid" style={{ maxWidth: "200vh" }}>
            <div class="section-title">
              <h2>Founders</h2>
            </div>

            <div class="container-fluid" style={{ cursor: "pointer" }}>
              <div class="row">
                <div className="col-lg-4">
                  <div className="member" style={{ marginTop: "10px" }}>
                    <div>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/Board%2Fsudhanshu.jpg?alt=media&token=270ca6da-4cd0-4e31-8cd9-ad505126b508"
                        className="rounded"
                        style={{
                          width: "auto",
                          height: "32vh",
                          objectFit: "cover",
                          marginBottom: "10px",
                        }}
                        alt=""
                      />
                    </div>

                    <div className="my-2">
                      <h4>Sudhanshu Borthakur</h4>
                      <span>CO-FOUNDER (COO)</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="member" style={{ marginTop: "10px" }}>
                    <div>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/members-76725.appspot.com/o/users%2Ft9LUYzpzGAQqXz4WHl5QUPXjztv1%2Fprofile?alt=media&token=d2db3f3d-cf4e-4f59-b507-cb0907e6bd91"
                        className="rounded"
                        style={{
                          width: "auto",
                          height: "32vh",
                          objectFit: "cover",
                          marginBottom: "10px",
                        }}
                        alt=""
                      />
                    </div>

                    <div className="my-2">
                      <h4>Ankur Jyoti Dutta</h4>
                      <span>CO-FOUNDER (CTO)</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="member" style={{ marginTop: "10px" }}>
                    <div>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/Board%2FArnav.jpeg?alt=media&token=36ba9f71-5a1c-4534-b94a-64e2d0793218"
                        className="rounded"
                        style={{
                          width: "auto",
                          height: "32vh",
                          objectFit: "cover",
                          marginBottom: "10px",
                        }}
                        alt=""
                      />
                    </div>

                    <div className="my-2">
                      <h4>Arnavraj Baruah</h4>
                      <span>CO-FOUNDER (CFO)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br></br> <br></br>
        </section>

        <div style={{ marginTop: "50px" }}></div>

        <section
          id="collab"
          class="collab"
          style={{
            backgroundColor: "#f2f2f2",
            paddingTop: "80px",
            paddingBottom: "50px",
          }}
        >
          <div class="container-fluid" style={{ maxWidth: "200vh" }}>
            <div class="section-title">
              <h2>Collaborations</h2>
            </div>

            <div class="container-fluid" style={{ cursor: "pointer" }}>
              <img src={collab} id="pcsponsors" style={{ width: "100%" }} />
              <img src={collab1} id="mbsponsors" style={{ width: "100%" }} />
              <div class="swiper-pagination"></div>
            </div>
          </div>

          <div
            class="container-fluid"
            style={{ maxWidth: "200vh", marginTop: "10vh" }}
          >
            <div class="section-title">
              <h2>Our Partners</h2>
            </div>

            <div class="container-fluid" style={{ cursor: "pointer" }}>
              <img src={sponsors} id="pcsponsors" style={{ width: "100%" }} />
              <img src={sponsors1} id="mbsponsors" style={{ width: "100%" }} />
              {/* <div class="row">
                <div class="col-3">
                  <img src={voxela} class="img-fluid" alt="voxela" />
                </div>
                <div class="col-3">
                  <img src={tnec} class="img-fluid" alt="tnec" />
                </div>
                <div class="col-3">
                  <img src={adroit} class="img-fluid" alt="adroit" />
                </div>
                <div class="col-3">
                  <img src={naucera} class="img-fluid" alt="naucera" />
                </div>
                <div class="col-3">
                  <img src={food} class="img-fluid" alt="Gopala" />
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
