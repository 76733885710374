import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useCollectionData } from "react-firebase-hooks/firestore";

function DriveC() {
  const [members, setMembers] = useState([]);

  const ref = db.collection("FloodDrive23");

  //REALTIME GET FUNCTION
  function getMembers() {
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setMembers(items);
    });
  }

  useEffect(() => {
    getMembers();
    // eslint-disable-next-line
  }, []);

  const mapContainerStyle = {
    height: "400px",
    width: "100%",
  };

  const center = {
    lat: 20.5937, // Set to the default center latitude
    lng: 78.9629, // Set to the default center longitude
  };

  const zoom = 4; // Set the default zoom level

  // Replace 'locations' with the name of your Firestore collection
  const query = db.collection("FloodDrive23").orderBy("district");

  const [locations] = useCollectionData(query, { idField: "id" });
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
  };

  const handleInfoWindowClose = () => {
    setSelectedLocation(null);
  };

  return (
    <>
      <section
        id="donation"
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: "10vh" }}
      >
        <div className="container" style={{ marginBottom: "30px" }}>
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <h1>Flood Distribution Drive'23</h1>
              <h2>Kalgachia, Barpeta, Assam</h2>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div
          className="container-fluid"
          style={{ marginTop: "6vh", maxWidth: "200vh" }}
        >
          <div style={{ marginTop: "-20px" }}>
            <LoadScript googleMapsApiKey="AIzaSyDoiXHxMG_vZPHz0eeJj5s661xhfU-Y4_4">
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={zoom}
              >
                {locations &&
                  locations.map((location, index) => (
                    <Marker
                      key={index}
                      data={location}
                      position={{
                        lat: location.latitude, // Replace with your latitude field
                        lng: location.longitude, // Replace with your longitude field
                      }}
                      title={location.district}
                      onClick={() => handleMarkerClick(location)}
                      icon={{
                        url:
                          selectedLocation === location
                            ? "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                            : "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                        scaledSize: new window.google.maps.Size(30, 30),
                      }}
                    />
                  ))}

                {selectedLocation && (
                  <InfoWindow
                    position={{
                      lat: selectedLocation.latitude,
                      lng: selectedLocation.longitude,
                    }}
                    onCloseClick={handleInfoWindowClose}
                  >
                    <div style={{ width: "40vh" }}>
                      <h5>Location: {selectedLocation.district}</h5>
                      <p>Name: {selectedLocation.name}</p>
                      {/* Add other details you want to display */}
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            </LoadScript>
          </div>

          <br />
          <div
            className="card"
            style={{
              backgroundColor: "#d1f2c7",
              padding: "8px",
              maxWidth: "80vh",
            }}
          >
            <h6
              style={{
                color: "green",
                marginTop: "5px",
                fontSize: "17px",
                fontWeight: "bold",
              }}
            >
              ₹3000+ Raised{" "}
              <span
                style={{
                  color: "#acaeb0",
                  fontSize: "10px",
                  fontWeight: "normal",
                }}
              >
                Goal Reached
              </span>
            </h6>
          </div>
          <h3>Our Contributors</h3>

          <div className="row my-1">
            {members
              .filter((data) => data.status === "Approved")
              .sort((a, b) => b.amount - a.amount)
              .map((data, index) => (
                <div className="col-lg-3 my-2" key={data.id}>
                  <div
                    class="card"
                    style={{
                      backgroundColor: "white",
                      border: "1px solid rgb(165 231 252)",
                    }}
                  >
                    <div class="row">
                      <div
                        className="col-3"
                        style={{
                          textAlign: "center",
                          paddingTop: "5px",
                          color: "rgb(4, 195, 255)",
                        }}
                      >
                        {/* <img
                          class="img-fluid"
                          style={{ width: "100%" }}
                          src="https://cdn.icon-icons.com/icons2/1161/PNG/512/1487716857-user_81635.png"
                        /> */}
                        <h2>{index + 1}</h2>
                      </div>
                      <div className="col-9">
                        <div class="content">
                          <a class="header">{data.name}</a>
                          <div
                            class="description"
                            style={{ fontSize: "11.5px" }}
                          >
                            <i>Place: </i>
                            <b>{data.district}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default DriveC;
