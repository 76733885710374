export const blogList = [
  {
    id: 22,
    title: "World Disability Day: Embracing Diversity and Inclusion",
    category: "Blog",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          Every year, on December 3rd, the world comes together to observe
          International Day of Persons with Disabilities, also known as World
          Disability Day. This day is a powerful reminder of the importance of
          recognizing, understanding, and advocating for the rights and
          well-being of people with disabilities.
          <br></br> <br></br>
          The theme of World Disability Day varies from year to year, reflecting
          the diverse challenges and opportunities faced by individuals with
          disabilities worldwide. However, the overarching goal remains
          constant: to promote inclusivity, raise awareness, and foster a more
          accessible and equitable society for all.
          <br></br>
          <br></br> <br></br>
          <b>Embracing Diversity:</b> <br></br>
          <br></br>One of the most profound aspects of World Disability Day is
          its celebration of diversity. Disabilities come in many forms, ranging
          from physical impairments to cognitive, sensory, and intellectual
          challenges. Each person’s experience is unique, shaped by their
          individual circumstances, abilities, and aspirations.
          <br></br> <br></br>
          By acknowledging and embracing this diversity, World Disability Day
          highlights the richness and complexity of the human experience. It
          reminds us that disability is a natural part of the human condition,
          and it does not define a person’s worth or potential.
          <br></br>
          <br></br>
          <br></br>
          <b>Promoting Inclusion:</b>
          <br></br>
          <br></br>
          Inclusion lies at the heart of the disability rights movement. It’s
          about creating environments where everyone, regardless of their
          abilities, can fully participate, contribute, and thrive. This
          involves not only removing physical barriers but also addressing
          social, cultural, and attitudinal obstacles that can marginalize
          people with disabilities.
          <br></br>
          <br></br>
          World Disability Day serves as a catalyst for action, inspiring
          individuals, communities, and policymakers to work towards greater
          inclusivity. It calls attention to the importance of accessible
          infrastructure, inclusive education, equal employment opportunities,
          and meaningful participation in all aspects of society.
          <br></br>
          <br></br>
          <br></br>
          <b>Raising Awareness:</b>
          <br></br>
          <br></br>
          Awareness is a powerful tool for change. World Disability Day provides
          a platform to educate the public about the realities of living with
          disabilities and the barriers that many individuals face on a daily
          basis. It challenges stereotypes, dispels myths, and fosters empathy
          and understanding.
          <br></br>
          <br></br>
          Through storytelling, advocacy campaigns, and educational initiatives,
          World Disability Day amplifies the voices of people with disabilities
          and their allies. It encourages open dialogue and promotes a more
          nuanced understanding of disability as a social and human rights
          issue.
          <br></br>
          <br></br>
          <br></br>
          <b>Building a More Inclusive Future:</b>
          <br></br>
          <br></br>
          As we commemorate World Disability Day, we are reminded of the
          progress that has been made in advancing disability rights and
          accessibility. However, we also recognize that there is still much
          work to be done. <br></br>
          <br></br>
          Building a more inclusive future requires collective effort and
          commitment. It requires us to listen to the needs and perspectives of
          people with disabilities, involve them in decisionmaking processes,
          and ensure that their rights are upheld and respected.
          <br></br>
          <br></br>On this World Disability Day, let us reaffirm our commitment
          to diversity, inclusion, and equality for all. Let us strive to create
          a world where every person, regardless of their abilities, can live
          with dignity, autonomy, and full participation in society. Together,
          we can make a difference and build a more inclusive and equitable
          world for generations to come.
        </p>
      </div>
    ),
    authorName: "Doli Jindal",
    authorDesc: "-",
    authorAvatar: "/assets/images/dolijindal.jpg",
    createdAt: "March 18, 2024",
    cover: "/assets/images/blog22.jpg",
  },

  {
    id: 21,
    title: "Tackling the Global Epidemic: World Obesity Day",
    category: "Blog",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          In a world where fast food joints seem to outnumber grocery stores and
          sedentary lifestyles are becoming the norm, the battle against obesity
          has never been more crucial. World Obesity Day serves as a stark
          reminder of the pressing need to address this global epidemic. With
          rates skyrocketing worldwide, it is imperative that we come together
          to tackle this issue head-on. In this blog, we will delve into the
          complexities of obesity, its far-reaching consequences, and what steps
          we can take to combat it effectively.
          <br></br> <br></br>
          <br></br>
          <b>Understanding Obesity</b> <br></br>
          <br></br>Obesity is not just about carrying a few extra pounds; it is
          a complex medical condition characterized by excessive body fat
          accumulation. While genetics and metabolic factors play a role,
          lifestyle choices such as diet and physical activity levels are major
          contributors. Unfortunately, obesity does not discriminate—it affects
          people of all ages, races, and socioeconomic backgrounds.
          <br></br>
          <br></br> <br></br>
          <b>The Consequences of Obesity</b> <br></br>
          <br></br>The impact of obesity extends far beyond physical appearance.
          It significantly increases the risk of developing serious health
          conditions such as type 2 diabetes, heart disease, stroke, certain
          cancers, and respiratory issues. Moreover, obesity can take a toll on
          mental health, leading to depression, anxiety, and low self-esteem.
          The economic burden is also substantial, with healthcare costs
          associated with obesity soaring into the billions annually.
          <br></br> <br></br>
          <br></br>
          <b>Addressing the Root Causes</b> <br></br>
          <br></br>
          To effectively combat obesity, we must address its root causes. This
          involves implementing comprehensive strategies that promote healthy
          eating habits, regular physical activity, and improved access to
          nutritious foods. Additionally, addressing environmental factors such
          as food deserts and the prevalence of processed foods is crucial in
          creating environments that support healthy choices.
          <br></br> <br></br> <br></br>
          <b>Promoting Healthy Lifestyles</b>
          <br></br> <br></br> Encouraging individuals to adopt healthier
          lifestyles is key to preventing and managing obesity. Educating people
          about nutrition, portion control, and the importance of regular
          exercise empowers them to take control of their health. Moreover,
          promoting policies that make healthy foods more accessible and
          affordable can have a significant impact on community health.<br></br>
          <br></br>
          <br></br>
          <b>Building Supportive Communities</b>
          <br></br>
          <br></br>Creating supportive environments that foster healthy
          behaviours is essential in the fight against obesity. This includes
          initiatives such as building safe spaces for physical activity,
          implementing workplace wellness programs, and providing education and
          resources to schools and communities. By working together, we can
          create environments that make the healthy choice the easy choice for
          everyone.
          <br></br>
          <br></br>
          <br></br>
          <b>Empowering Individuals</b>
          <br></br>
          <br></br>At the heart of the obesity epidemic are individuals
          struggling to make healthier choices in a world filled with obstacles.
          Empowering individuals to take charge of their health is paramount.
          This involves providing access to resources such as nutrition
          counselling, exercise programs, and support groups. By offering
          guidance and encouragement, we can help individuals overcome barriers
          and achieve their health goals.
          <br></br>
          <br></br>
          <br></br>
          <b>Conclusion</b>
          <br></br>
          <br></br>As we observe World Obesity Day, let us reaffirm our
          commitment to combating this global epidemic. By raising awareness,
          advocating for policy change, and empowering individuals, we can make
          significant strides towards a healthier future for all. Together, let
          us take a stand against obesity and build a world where everyone has
          the opportunity to lead a happy, healthy life.
        </p>
      </div>
    ),
    authorName: "Pallav Jyoti Buragohain",
    authorDesc: "-",
    authorAvatar: "/assets/images/pallav.jpg",
    createdAt: "March 7, 2024",
    cover: "/assets/images/blog21.jpg",
  },

  {
    id: 20,
    title: "Cancer: What is it and how does this spread?",
    category: "Research",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          Cancer, a term that echoes with both fear and resilience, is a complex
          and pervasive group of diseases characterized by the uncontrolled
          growth and division of cells. At its core, cancer begins when normal
          cellular processes are disrupted, leading to genetic mutations that
          prompt cells to evade the body's usual checks and balances.
          <br></br>
          <br></br>
          Cancer's diversity is staggering, encompassing over 100 different
          types, each with distinct characteristics and behaviors. As mutations
          accumulate, cells can acquire characteristics that enable them to
          invade surrounding tissues and, in some cases, metastasize to distant
          organs through the bloodstream or lymphatic system. This metastatic
          spread is a hallmark of advanced cancer and poses significant
          challenges in both diagnosis and treatment.
          <br></br>
          <br></br>
          <h3>Developing of cancer:</h3>
          The development of cancer is a complex and multifaceted process
          involving a series of genetic, environmental, and lifestyle factors.
          <ul>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              <b>Genetic Alterations: </b>Cancer often begins with genetic
              mutations that disrupt the normal regulatory mechanisms
              controlling cell growth and division.Certain genes, called
              proto-oncogenes, can transform into oncogenes when mutated,
              promoting uncontrolled cell growth.
            </li>

            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              <b>Cellular Proliferation: </b>Mutated cells experience
              unregulated proliferation due to alterations in signaling pathways
              and cell cycle control.
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              <b>Tumor Formation:</b> Mutated cells accumulate, forming a mass
              known as a tumor. This can be benign (non-cancerous) or malignant
              (cancerous).
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              <b>Metastasis:</b> Cancer cells acquire the ability to invade
              surrounding tissues, breaking through normal barriers. Malignant
              cells may enter the bloodstream or lymphatic system, spreading to
              distant organs and forming secondary tumors.
            </li>

            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              <b>Immune Evasion:</b> Cancer cells can evade detection and
              destruction by the immune system, allowing them to persist and
              proliferate.
            </li>
          </ul>
          <br></br>
          Early detection plays a pivotal role in managing cancer effectively.
          Routine screenings and diagnostic tests can identify abnormalities
          before symptoms become apparent, increasing the chances of successful
          treatment. Advances in medical imaging, genetic testing, and biomarker
          identification contribute to the early diagnosis and targeted
          treatment of specific cancers.
        </p>

        <div
          className="row"
          style={{
            padding: "10px",
            backgroundColor: "#f2f9fc",
          }}
        >
          <div className="col-lg-4 order-2 order-lg-1">
            <p style={{ marginTop: "10px" }}>
              Cancer is caused by certain changes to genes, the basic physical
              units of inheritance. Genes are arranged in long strands of
              tightly packed DNA called chromosomes.
              <br></br> <br></br>
              <i style={{ color: "grey", fontSize: "11px" }}>
                Credit: © Terese Winslow
              </i>
            </p>
          </div>
          <div className="col-lg-8 order-1 order-lg-2">
            <img
              style={{ width: "100%" }}
              src="https://firebasestorage.googleapis.com/v0/b/members-76725.appspot.com/o/blogs%2FDNA-structure-enlarge.jpg?alt=media&token=791cd1c0-c7d3-4a25-8b29-11f7df6ab039"
            />
          </div>
        </div>
        <br></br>
        <p>
          <h3>Cancer guidance screening:</h3>
          Cancer guidance screening is a crucial step for the prevention, early
          detection, and effective management of cancer:
          <ul>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              <b>Early Detection: </b>Cancer guidance screening facilitates the
              early detection of cancer conditions, which often leads to more
              successful treatment outcomes, as interventions can be implemented
              at a stage when the disease is more manageable.
            </li>

            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              <b>Risk Assessment: </b>Through screening, individuals can assess
              their risk factors for specific types of cancer. This allows for
              targeted preventive measures and lifestyle modifications to reduce
              the risk of developing cancer.
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              <b>Personalized Prevention Plans:</b> Cancer guidance screening
              helps in creating personalized prevention plans based on an
              individual's health history, genetics, and lifestyle factors. This
              proactive approach empowers individuals to take steps to minimize
              their cancer risk.
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              <b>Improved Treatment Planning:</b> For individuals diagnosed with
              cancer, guidance screening can provide valuable information about
              the characteristics of the cancer, aiding healthcare professionals
              in devising more targeted and effective treatment plans.
            </li>

            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              <b>Psychosocial Support:</b> A cancer diagnosis can be emotionally
              challenging. Cancer guidance screening may involve counseling and
              support services, helping individuals and their families navigate
              the emotional aspects of cancer and cope with the challenges
              associated with diagnosis and treatment.
            </li>

            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              <b>Public Health Impact:</b> Population-wide cancer guidance
              screening initiatives contribute to public health by identifying
              trends, risk factors, and areas where targeted interventions are
              needed. This information informs public health policies and
              strategies for cancer prevention and control.
            </li>
          </ul>
        </p>
      </div>
    ),
    authorName: "Upashana Talukdar",
    authorDesc:
      "I love penning down my thoughts and feelings into microtales and poetries cause it provides me with a way of sharing my contemplation with the world.",
    authorAvatar: "/assets/images/upasana.jpeg",
    createdAt: "November 16, 2023",
    cover: "/assets/images/blog20.jpg",
  },

  {
    id: 19,
    title:
      "Research links smartphone use to low sperm count and male infertility.",
    category: "Research",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          A large population-based study showed that increased mobile phone use
          reduces sperm concentration and total sperm count.
          <br></br>
          <br></br>
          <ul>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> For
              over a half-century, medical experts have been looking for
              environmental and lifestyle factors that contribute to decreasing
              male sperm count.
            </li>

            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> Some
              experts suggest that low-level radiofrequency electromagnetic
              fields emitted by mobile phones may have an effect on male
              fertility.
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> A
              large Swiss population study revealed decreased sperm
              concentration and total sperm count with increased mobile phone
              use.
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> The
              findings indicate that advanced phone technologies may have less
              impact on sperm quality.
            </li>
          </ul>
          <br></br>
          <br></br>
          Male infertility is considered to account for almost half of all
          clinical infertility cases.
          <br></br> <br></br>
          Male infertility is becoming a rising public health concern, with
          doctors observing a 50% drop in male sperm counts over several
          decades.
          <br></br>
          <br></br>
          Searching for answers, researchers have been investigating various
          environmental factors for their potential influence on sperm quality.
          These may include:
          <br></br>
          <ul>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              radiation exposure
            </li>

            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              endocrine disruptors
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              lifestyle habitsTrusted Source (i.e., diet, stress, alcohol,
              drugs, and smoking)
            </li>
          </ul>
          <br></br> <br></br>
          <b>
            Researchers from the University of Geneva and the Swiss Tropical and
            Public Health Institute investigated the impact of mobile phone use
            and location on male infertility in a new study.
          </b>
          <br></br> <br></br>
          <b>
            According to the 13-year study, smartphone use is connected with
            reduced sperm concentration and total sperm count (TSC) in young
            adult males.
          </b>
          <br></br> <br></br>
          Transitions to 3G and 4G, on the other hand, may have lessened the
          impact on sperm count, possibly due to the decreased transmission
          power of newer phones.
          <br></br> <br></br>
          The findings were recently published in{" "}
          <a
            href="https://www.fertstert.org/article/S0015-0282(23)01875-7/fulltext"
            target="_blank"
          >
            <i>Fertility and Sterility</i>
          </a>
          <br></br>
          <br></br>
          <h3>Do cellphones have an impact on male infertility?</h3>
          Between 2005 and 2018, researchers recruited 2,886 men ages 18 to 22
          at military recruitment centers for the study. The study periods were
          from 2005 to 2007, 2008 to 2011, and 2012 to 2018.
          <br></br> <br></br>
          The laboratory specialists captured semen samples and recorded sperm
          concentration, total sperm count (TSC), and motility.
          <br></br> <br></br>
          Participants were asked about their reproductive and overall health,
          education, and lifestyle habits, as well as how frequently they use
          their devices. The frequency of use ranged from once a week to more
          than 20 times per day.
          <br></br> <br></br>
          The questions were answered by a total of 2,764 people. The
          researchers divided the participants into five groups based on their
          frequency of cell phone use.
          <br></br> <br></br>
          <b>
            Men who reported using their phones once weekly had significantly
            higher median sperm concentrations than men who used their phones
            more than 20 times per day.
          </b>
          <br></br> <br></br>
          Moreover, the first study period showed a "more pronounced"
          relationship between smartphone use and sperm concentration than
          subsequent periods. The trajectory appears to correspond to the
          progression of new technologies from 2G to 3G to 4G, corresponding to
          a decrease in the output power of mobile phones.
          <br></br>
          <br></br>
          <h3>Does it matter where you carry your phone?</h3>
          Researchers also inquired about where participants kept their phones
          when not in use. Non-use phone locations included pants, jacket, belt
          carrier, or anywhere else not on the body.
          <br></br> <br></br>
          When not in use, 85.7% of the study participants — 2,368 men — kept
          their phones in their pants pockets.
          <br></br> <br></br>
          The remaining males kept their phones in their jackets (4.6%) or
          elsewhere (9.7%).
          <br></br> <br></br>
          <b>
            The study models found no link between carrying phones in one's
            pants and lower sperm quality parameters.
          </b>
          <br></br> <br></br>
          This lack of correlation was also visible in the stratified analysis
          by recruitment period.
          <br></br>
          <br></br>
          <h3>Why is male sperm count going down?</h3>
          According to the World Health Organization (WHO)Trusted Source, a male
          with a sperm concentration below 15 million per milliliter may take
          more than a year to conceive a child with a partner.
          <br></br> <br></br>
          Moreover, sperm concentrations below 40 million per milliliter reduce
          the chances of pregnancy.
          <br></br> <br></br>
          According to research, sperm count has decreased from 99 million to an
          average of 47 million sperm per milliliter. This is especially true in
          Western countries.
          <br></br> <br></br>
          Dr. Hussain Ahmad, a consultant practitioner in the United Kingdom,
          spoke with Medical News Today about the Swiss study. He was not a
          participant in the study.
          <br></br> <br></br>
          Dr. Ahmad shared that myriad factors affect sperm count, such as:
          <br></br>
          <ul>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> drug
              use (prescription and illegal)
            </li>

            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              alcohol use
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              tobacco use
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              stress
            </li>

            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              physical inactivity
            </li>
          </ul>
          <br></br>
          <br></br>
          <h3>Does cell phone radiation have long-term effects?</h3>
          This large-scale, cross-sectional study on mobile phone use and sperm
          quality was conducted by the researchers in collaboration. Their
          research gathered data on thousands of Swiss men over a decade.
          <br></br> <br></br>
          Depending on self-reported data is a major limitation. To address this
          in future research, the authors launched another study in which
          participants downloaded a smartphone app.
          <br></br> <br></br>
          <b>
            This study only included men aged 18 to 22. The effects of cell
            phone radiofrequency electromagnetic fields (RF-EMF) on older men
            have yet to be investigated.
          </b>
          <br></br>
          <br></br>
          <h3>Older cell phones may be harmful to reproductive health.</h3>
          "While the exact mechanism of impact remains a subject of debate, the
          correlation highlights the need for cautious use, particularly with
          older phone models associated with higher emissions," he said.
          <br></br> <br></br>
          The authors of the study concluded: "The lack of clear evidence for a
          negative association between mobile phone use and male fertility, as
          well as the dramatic increase in cell phone use over the past decade,
          underscores the need for further research in this area."
          <br></br> <br></br>
          The researchers also stated that "prospective observational studies"
          to assess RF-EMF exposure to the testicles and the
          hypothalamic-pituitary-gonadal axis are needed.
          <br></br> <br></br>
          <b>
            "This would allow us to examine the association between cell phone
            use, RF-EMF exposure, and semen quality and to better understand the
            mode of action of RF-EMF on the male reproductive system," the
            researchers wrote in their paper.
          </b>
        </p>
        <br></br>
        <br></br>

        <i style={{ color: "grey", fontSize: "11px" }}>
          Source:{" "}
          <a
            href="https://www.medicalnewstoday.com/articles/cell-phone-use-male-infertility"
            target="_blank"
          >
            medicalnewstoday.com
          </a>
        </i>
      </div>
    ),
    authorName: "Ankur J. Dutta",
    authorDesc: "...",
    authorAvatar: "/assets/images/Ankur.png",
    createdAt: "November 11, 2023",
    cover: "/assets/images/blog19.jpg",
  },

  {
    id: 18,
    title:
      "Anthrax: Unmasking the Menace - A Deep Dive into the Deadly Bacterium",
    category: "Blog",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          Anthrax is a serious infectious disease caused by gram-positive,
          rod-shaped bacteria known as Bacillus anthracis. It occurs naturally
          in soil and commonly affects domestic and wild animals around the
          world. People can get sick with anthrax if they come in contact with
          infected animals or contaminated animal products. Anthrax can cause
          severe illness in both humans and animals. Anthrax is not contagious,
          which means you can’t catch it from another person like the cold or
          flu.
          <br></br>
          <br></br>
          The symptoms of anthrax depend on the type of infection and can take
          anywhere from 1 day to more than 2 months to appear. All types of
          anthrax have the potential, if untreated, to spread throughout the
          body and cause severe illness and even death.
          <br></br>
          <br></br>
          <b>Cutaneous anthrax symptoms can include:</b>
          <br></br>
          <ul>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> A
              group of small blisters or bumps that may itch
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Swelling can occur around the sore
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> A
              painless skin sore (ulcer) with a black centre that appears after
              the small blisters or bumps
              <ul>
                <li>
                  • Most often the sore will be on the face, neck, arms, or hand
                </li>
              </ul>
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Fever and chills
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Chest Discomfort
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Shortness of breath
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Confusion or dizziness
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Cough
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Nausea, vomiting, or stomach pains
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Headache
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Sweats (often drenching)
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Extreme tiredness
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> Body
              aches
            </li>
          </ul>
          <br></br>
          <br></br>
          <img
            src="/assets/images/blog18A.jpg"
            alt="img"
            style={{ width: "100%", height: "90%", marginBottom: "5px" }}
          />
          <br></br>
          People get infected with anthrax when spores get into the body. When
          anthrax spores get inside the body, they can be “activated.” The
          bacteria can then multiply, spread out in the body, produce toxins,
          and cause severe illness.
          <br></br> <br></br>This can happen when people breathe in spores, eat
          food or drink water contaminated with spores, or get spores in a cut
          or scrape in the skin. It is very uncommon for people in the United
          States to get infected with anthrax.
          <br></br> <br></br>Domestic and wild animals can become infected when
          they breathe in or ingest spores in contaminated soil, plants, or
          water. These animals can include cattle, sheep, goats, antelope, and
          deer. In areas where domestic animals have had anthrax in the past,
          routine vaccination can help prevent outbreaks.
          <br></br> <br></br>Imported animal hides have been associated with a
          number of anthrax cases in the United States. Cases have occurred in
          drum makers using these hides. Cases have also occurred in people who
          have handled or been near the drums or in the environment where they
          were made. Some imported hides may contain anthrax spores, and
          although this is rare, there is no way to test for the presence of
          spores on hides.
          <br></br>
          <br></br>
          <b>Where anthrax is found</b>
          <br></br>
          <ul>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Central and South America
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Sub-Saharan Africa
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Central and southwestern Asia
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Southern and eastern Europe
            </li>
            <li>
              {" "}
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> The
              Caribbean
            </li>
          </ul>
          <br></br> <br></br>
          <b>
            To protect against anthrax spores, be sure to use hides that came
            from:
          </b>
          <br></br>
          <ul>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Animals from the United States
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Animals that were imported with an international veterinary
              certificate showing that they have undergone the appropriate
              government inspection
            </li>
          </ul>
          <br></br>
          Visitors to areas where anthrax is common or where an outbreak is
          occurring in animals can get sick with anthrax if they have contact
          with infected animal carcasses or eat meat from animals that were sick
          when slaughtered. They can also get sick if they handle animal parts,
          such as hides, or products made from those animal parts, such as
          animal hide drums. If you are visiting these areas, do not eat raw or
          undercooked meat and avoid contact with livestock, animal products,
          and animal carcasses.
        </p>
      </div>
    ),
    authorName: "Doli Jindal",
    authorDesc: "...",
    authorAvatar: "/assets/images/Dolijindal.jpg",
    createdAt: "July 2, 2023",
    cover: "/assets/images/blog18.JPG",
  },

  {
    id: 17,
    title: "POLIO: THE DISEASE THAT ONCE TERRORIZED THE WORLD",
    category: "Blog",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          Have you ever heard of polio? If you are living in a developed
          country, chances are you have not. But polio was once a dreaded
          disease that paralyzed and killed millions of people around the world,
          especially children under 5 years of age. In this blog post, we will
          explore what polio is, how it spreads, what its symptoms are, how it
          can be diagnosed, prevented and treated, and what the current status
          of polio eradication is.
          <br></br>
          <br></br>
          <b>What is polio?</b>
          <br></br>
          Polio, short for poliomyelitis, is an infectious disease caused by a
          virus called poliovirus. It belongs to a group of diseases known as
          enteroviruses, which infect the gastrointestinal tract and can
          sometimes invade the nervous system. Polio has been around for a long
          time, dating back to ancient times. There are records of polio
          outbreaks in ancient Egypt, Greece and Rome. The disease was also
          called Heine-Medin disease or infantile paralysis because it mainly
          affected young children and caused muscle weakness and paralysis.
          <br></br>
          <br></br>
          Polio became a major public health problem in the 20th century, when
          it caused large-scale epidemics in many countries. The disease reached
          its peak incidence in the United States in 1952, with 20,000 cases of
          paralytic poliomyelitis. In developing countries today polio remains a
          major cause of disability.
          <br></br>
          <br></br>
          There are three types of poliovirus: type 1, type 2 and type 3. All
          three types can cause paralysis, but type 1 is the most common and
          virulent. Type 2 was eradicated in 1999 and type 3 was eradicated in
          2020. As of 2022, only type 1 remains endemic in two countries:
          Pakistan and Afghanistan.
          <br></br>
          <br></br>
          <b>How does polio spread?</b>
          <br></br>
          Polio is highly contagious and spreads mainly through person-to-person
          contact. The virus lives in the throat and intestines of infected
          people and can be transmitted through fecal-oral routes or oral-oral
          routes. This means that people can get infected by swallowing
          contaminated food or water, or by coming into contact with the saliva
          or feces of an infected person. The virus can also spread through
          respiratory droplets from coughs or sneezes.
          <br></br>
          <br></br>
          People who are infected with polio can spread the virus for up to six
          weeks even if they do not show any symptoms. About 90% of people who
          are infected with polio do not develop any symptoms at all. This makes
          it harder to detect and stop the transmission of the virus.
          <br></br>
          <br></br>
          <b>What are the symptoms of polio?</b>
          <br></br>
          Polio can cause different types of symptoms depending on whether it
          affects the central nervous system (CNS) or not. The CNS consists of
          the brain and spinal cord and controls most of the body's functions.
          <br></br>
          <br></br>
          The two major types of polio are abortive polio and paralytic or
          nonparalytic polio. Abortive polio does not affect the CNS and usually
          causes mild flu-like symptoms such as sore throat, fever, headache,
          vomiting, weakness and fatigue. These symptoms tend to be temporary
          and the patient can make a full recovery within two weeks.
          <br></br>
          <br></br>
          Paralytic or nonparalytic polio affects the CNS and can cause more
          serious complications. Nonparalytic polio causes inflammation of the
          membranes that cover the brain and spinal cord (meningitis), which can
          lead to symptoms such as headache, neck, back, abdominal and extremity
          pain, fever, vomiting, stomach pain, lethargy and irritability.
          <br></br>
          <br></br>
          Paralytic polio causes damage to the nerve cells that control muscle
          movement (motor neurons), which can result in muscle weakness, loss of
          reflexes and paralysis. Paralysis usually affects the legs but can
          also affect the arms, trunk or face. In some cases, paralysis can
          affect the muscles that control breathing (respiratory muscles), which
          can be fatal. Paralysis can be temporary or permanent.
          <br></br>
          <br></br>
          About one in 200 infections leads to irreversible paralysis. Among
          those paralyzed, 5–10% die when their breathing muscles become
          immobilized.
          <br></br>
          <br></br>
          <img
            src="https://epe.brightspotcdn.com/2c/9b/4975b61f4a128ba5eca11cbc9e1c/093021-polio-vaccine-3-ap-bs.jpg"
            alt="img"
            style={{ width: "100%", height: "90%", marginBottom: "5px" }}
          />
          <br></br>
          Some people who have had paralytic polio may develop a condition
          called post-polio syndrome (PPS) years or decades later. PPS is
          characterized by new or worsening muscle weakness, fatigue, pain and
          joint problems. The exact cause of PPS is not known, but it may be
          related to the aging of the nerve cells that survived the initial
          infection.
          <br></br>
          <br></br>
          <b>How is polio diagnosed?</b>
          <br></br>
          Polio can be diagnosed by testing samples of throat washings, stool or
          spinal fluid for the presence of the virus. This can be done by
          culture or by polymerase chain reaction (PCR), which amplifies the
          genetic material of the virus. Another way to diagnose polio is by
          measuring the level of antibodies against the virus in the blood.
          Antibodies are proteins that the immune system produces to fight off
          infections. A high level of antibodies indicates a recent or past
          exposure to the virus.
          <br></br>
          <br></br>
          <b>How can polio be prevented?</b>
          <br></br>
          The best way to prevent polio is by vaccination. There are two types
          of polio vaccines available: oral polio vaccine (OPV) and inactivated
          polio vaccine (IPV).
          <br></br>
          <br></br>
          OPV is a live attenuated vaccine, which means it contains a weakened
          form of the virus that can stimulate immunity but cannot cause
          disease. OPV is given as drops in the mouth and can provide protection
          for both the individual and the community. OPV can prevent most cases
          of paralytic polio, but in rare cases it can revert to a virulent form
          and cause vaccine-associated paralytic poliomyelitis (VAPP) or
          circulating vaccine-derived poliovirus (cVDPV).
          <br></br>
          <br></br>
          IPV is a killed vaccine, which means it contains an inactivated form
          of the virus that cannot cause disease but can still induce immunity.
          IPV is given as an injection in the arm or leg and can provide
          protection for the individual but not for the community. IPV can
          prevent all types of polio, but it cannot stop the transmission of the
          virus.
          <br></br>
          <br></br>
          <img
            src="https://www.cdc.gov/polio/images/whatispolio/india_polio_wide.jpg?_=33485"
            alt="img"
            style={{ width: "100%", height: "90%", marginBottom: "5px" }}
          />
          <br></br>
          Both OPV and IPV are safe and effective, and both are used in
          different combinations worldwide, depending on local epidemiological
          and programmatic circumstances, to ensure the best possible protection
          to populations. Multiple doses of either vaccine are required for
          lifelong immunity.
          <br></br>
          <br></br>
          Other ways to prevent polio include improving public sanitation,
          maintaining proper personal hygiene, avoiding contact with infected
          people or contaminated objects, and seeking medical attention if
          symptoms occur.
          <br></br>
          <br></br>
          <b>How is polio treated?</b>
          <br></br>
          There is no cure for polio, only supportive care. The aim of treatment
          is to prevent or reduce the symptoms and complications of the disease.
          Treatment may include:
          <br></br>
          <ul>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Antibiotics to prevent secondary infections in weakened muscles.
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Analgesics to relieve pain and inflammation.
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Antispasmodics to relax muscle spasms.
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Ventilators to assist breathing if respiratory muscles are
              paralyzed.
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Physical therapy to maintain muscle function and prevent
              deformities.
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Occupational therapy to help with daily activities and self-care.
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Braces, corrective shoes and orthopedic surgery to correct limb
              deformities.
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> A
              balanced and nutritious diet to support recovery and health.
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> Bed
              rest to ease the symptoms associated with the condition.
            </li>
          </ul>
          <br></br>
          <br></br>
          <b>What is the current status of polio eradication?</b>
          <br></br>
          The world has made remarkable progress in eradicating polio since
          1988, when the World Health Assembly adopted a resolution for the
          worldwide eradication of polio, marking the launch of the Global Polio
          Eradication Initiative (GPEI). The GPEI is a global partnership led by
          national governments, WHO, Rotary International, CDC, UNICEF, Bill &
          Melinda Gates Foundation and Gavi, the Vaccine Alliance. The GPEI has
          mobilized millions of health workers, volunteers, donors and partners
          to deliver polio vaccines to every child in every corner of the world.
          <br></br>
          <br></br>
          As a result of these efforts, the incidence of polio worldwide has
          been reduced by 99%, from an estimated 350 000 cases in more than 125
          endemic countries in 1988, to 6 reported cases in 2021. More than 19
          million people who would otherwise have been paralyzed by polio are
          walking today. More than 1.5 million lives have been saved by
          preventing deaths from polio-related complications. The world stands
          on the threshold of eradicating a human disease globally for only the
          second time in history, after smallpox in 1980.
          <br></br>
          <br></br>
          However, challenges remain. Polio still persists in two countries:
          Pakistan and Afghanistan. These countries face complex political and
          security situations that hamper access to children for vaccination.
          They also face social and cultural barriers that affect community
          acceptance and demand for immunization. Moreover, outbreaks of cVDPV
          continue to occur in under-immunized populations due to low routine
          immunization coverage and gaps in surveillance.
          <br></br>
          <br></br>
          To overcome these challenges, the GPEI has developed a new strategy
          for 2022–2026 that aims to end all forms of poliovirus transmission
          and deliver a lasting legacy for global health. The strategy focuses
          on four main objectives:
          <br></br>
          <ul>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Interrupt transmission of all polioviruses by strengthening
              immunization systems and reaching every child with multiple doses
              of polio vaccine.
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Contain and certify poliovirus eradication by ensuring safe
              handling and destruction of poliovirus materials in laboratories
              and vaccine facilities.
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Integrate polio functions into health systems by transitioning the
              polio assets and infrastructure to support other health priorities
              and emergencies.
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Certify and celebrate a polio-free world by engaging communities,
              leaders and partners to recognize and sustain this historic
              achievement.
            </li>
          </ul>
          <br></br>
          <br></br>
          The success of the strategy depends on the commitment and
          collaboration of all stakeholders, including governments, donors,
          partners, health workers, communities and parents. Everyone has a role
          to play in making polio history and ensuring a healthier future for
          all children.
          <br></br>
          <br></br>
          <b>Conclusion</b>
          <br></br>
          Polio is a devastating disease that can cause paralysis and death, but
          it can be prevented by vaccination. The world has made tremendous
          progress in eradicating polio, but the fight is not over yet. We need
          to keep up the momentum and finish the job. Together, we can end polio
          for good and create a lasting legacy for global health.
        </p>
      </div>
    ),
    authorName: "Ashmita Sharma",
    authorDesc: "...",
    authorAvatar: "/assets/images/ashmita.jpg",
    createdAt: "June 23, 2023",
    cover: "/assets/images/blog17.jpg",
    authorAvatar2: "/assets/images/Chinmoy.jpg",
    authorName2: "Chinmoy Baruah",
    authorDesc2: "...",
  },

  {
    id: 16,
    title: "India's Fight Against Mosquito-Borne Diseases",
    category: "Blog",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          Mosquitoes are more than just a nuisance. They are the deadliest
          animals on the planet, responsible for millions of deaths every year.
          In India, mosquitoes pose a serious threat to public health and
          development, as they transmit diseases such as malaria, dengue,
          chikungunya, Japanese encephalitis, and lymphatic filariasis.
          <br></br>
          <br></br>
          <b>The Numbers are Alarming</b>
          <br></br>
          According to the World Health Organization (WHO), India accounts for
          83% of all malaria cases and 82% of all malaria deaths in the
          South-East Asia region. Malaria is endemic in most parts of the
          country, especially in the northeastern and central regions.
          <br></br>
          <br></br>
          Dengue is another major mosquito-borne disease in India, with an
          estimated 200 thousand cases and 100 deaths in 2022. Dengue outbreaks
          occur frequently during the monsoon season, when rainfall creates
          favorable conditions for mosquito breeding.
          <br></br>
          <br></br>
          Chikungunya is a viral disease that causes fever and joint pain. It is
          transmitted by the same mosquitoes that carry dengue. In 2016, India
          witnessed a massive outbreak of chikungunya, with over 64,000 cases
          reported across the country.
          <br></br>
          <br></br>
          Japanese encephalitis (JE) is a viral infection that affects the brain
          and can cause death or disability. It is transmitted by mosquitoes
          that breed in rice fields and pig farms. JE is endemic in 171
          districts of 19 states and union territories in India, with an
          estimated 10,000 cases and 1,000 deaths every year.
          <br></br>
          <br></br>
          Lymphatic filariasis (LF) is a parasitic infection that causes
          swelling of the limbs and genitals, leading to disability and social
          stigma. It is transmitted by mosquitoes that bite at night. India has
          the highest burden of LF in the world, with about 650 million people
          at risk and 31 million infected.
          <br></br>
          <br></br>
          <b>The Impact is Devastating</b>
          <br></br>
          Mosquito-borne diseases not only affect the health of individuals, but
          also the economy and development of the country. They cause loss of
          productivity, absenteeism from work and school, increased health care
          costs, reduced tourism, and lower quality of life.
          <br></br>
          <br></br>
          According to a study by the Indian Council of Medical Research (ICMR),
          malaria alone costs India about $1.94 billion. The study also
          estimated that if these diseases were eliminated by 2030, India could
          save $10.7 billion in direct costs and $62.8 billion in indirect
          costs.
          <br></br>
          <br></br>
          Mosquito-borne diseases also hamper the achievement of the Sustainable
          Development Goals (SDGs), which aim to end poverty, protect the
          planet, and ensure peace and prosperity for all by 2030. For example,
          malaria alone can reduce economic growth by up to 1.3% per year in
          high-burden countries.
          <br></br>
          <br></br>
          <b>The Balance is Important</b>
          <br></br>
          While mosquitoes are harmful to humans, they are also important for
          the ecosystem. They serve as food for many animals, such as birds,
          bats, frogs, fish, and spiders. They also pollinate some plants, such
          as orchids and cacao. They are part of the natural cycle of life and
          death.
          <br></br>
          <br></br>
          Therefore, we cannot completely eliminate mosquitoes from the
          environment. We can only reduce their numbers and their impact on
          human health. We need to find a balance between protecting ourselves
          from mosquitoes and respecting their role in nature.
          <br></br>
          <br></br>
          <b>The Solution is Possible</b>
          <br></br>
          The good news is that mosquito-borne diseases are preventable and
          treatable. There are effective tools and strategies available to
          control mosquitoes and their diseases, such as larval source
          management, environmental sanitation, vector surveillance, mass drug
          administration, vaccines, and community awareness.
          <br></br>
          <br></br>
          However, these interventions require sustained political commitment,
          adequate funding, intersectoral collaboration, community
          participation, and innovation. India has made significant progress in
          reducing the burden of mosquito-borne diseases in recent years, but
          there is still a long way to go.
          <br></br>
          <br></br>
          The National Vector Borne Disease Control Programme (NVBDCP) is the
          nodal agency for the prevention and control of vector-borne diseases
          in India. It implements various programmes and activities to combat
          malaria, dengue, chikungunya, JE, and LF.
          <br></br>
          <br></br>
          Some of the achievements of NVBDCP include:
          <br></br>
          <ul>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Elimination of malaria from Lakshadweep islands.
            </li>

            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Reduction of malaria cases by 83.34% and deaths by 92% between
              2000 and 2019
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Reduction of LF prevalence by 43% between 2004 and 2018
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Introduction of JE vaccine in endemic districts since 2006
            </li>
            <li>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{" "}
              Launch of National Dengue Day on May 16 every year since 2016
            </li>
          </ul>
          <br></br>
          <br></br>
          As individuals, we need to join hands with the government, the health
          sector, the civil society, the media, and the private sector to fight
          this common enemy. We need to keep our surroundings clean, avoid water
          stagnation, use mosquito repellents, sleep under bed nets, seek timely
          medical care, and report any suspected cases. We need to spread
          awareness, educate others, and support the efforts of NVBDCP.
          <br></br>
        </p>
      </div>
    ),
    authorName: "Chinmoy Baruah",
    authorDesc: "...",
    authorAvatar: "/assets/images/Chinmoy.jpg",
    createdAt: "June 03, 2023",
    cover: "/assets/images/blog16.jpg",
  },

  {
    id: 15,
    title: "Breaking the Silence",
    category: "Blog",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          <b>
            Breaking the Silence: How Smoking, Alcohol and Illicit Drug Use
            Affect Us All
          </b>
          <br></br>
          <br></br>I know, I know, this is a taboo topic. “This is just some
          ancient curse that has plagued humanity for centuries and you can’t do
          anything about it, Chinmoy!” Yeah, yeah, I’ve heard it all before. But
          humor me for a moment, will you?
          <br></br>
          <br></br>
          We have the power to make a difference in the world. Over the years,
          we have used social media to raise our voices for various causes and
          to celebrate our achievements in advancing social justice. But when it
          comes to smoking, alcohol and illicit drug use, we have always stayed
          quiet. All my life, I’ve witnessed only negative things about them.
          Yet they have become a norm for all people, regardless of race, caste
          or gender. Why is that? My elders, my peers, and my juniors, all have
          suffered. Human culture values positivity and yet, they justify such
          poison as a necessity? No. I refuse to accept that. It is a choice.
          And we cannot rely on governments and organizations to solve this
          crisis without our involvement. This is something where we have to
          take charge of our own actions and choices that affect the ones we
          love.
          <br></br>
          <br></br>
          Every year, 11.8 million lives are cut short by smoking, alcohol and
          illicit drug use. This is more than the total deaths from all cancers
          combined. Many of those who succumb to alcohol or drug overdoses are
          in the prime of their lives, under 50 years old. But the damage does
          not end there. Countless families are shattered by the loss of their
          loved ones. Children who grow up with parents who smoke, drink or use
          drugs are more likely to suffer from physical and mental health
          problems, poor academic performance, antisocial behavior and substance
          use disorders themselves. Parents who lose their children to overdoses
          are left with grief, guilt and anger that can last a lifetime. Spouses
          who struggle with their partners’ addiction are faced with stress,
          conflict and violence that can tear apart their relationship. Siblings
          who watch their brothers or sisters fall into the trap of substance
          use are filled with fear, sadness and helplessness that can affect
          their own well-being. And it’s a tragedy that we waste billions of
          dollars on these deadly substances, money that could have been
          invested in a much nobler cause.
          <br></br>
          <br></br>
          So what is the solution? How can we free ourselves and our planet from
          this deadly trap? For an individual, the answer is simple. Just say no
          to smoking, drinking and drugs. That's all you need to do. And yet it
          is one of the most courageous and powerful things one could do. You
          can protect your health, your mood, your relationships, and your goals
          from the harmful effects of these substances. You can also seek
          professional help if you have trouble quitting or reducing your
          substance use. There are medications and therapies that can help you
          cope with cravings, withdrawal symptoms, and underlying issues.
          Meanwhile, as a species, we must unite against its existence. We must
          educate ourselves and others about the dangers of alcohol, smoking and
          drugs. We must support each other in making healthy choices and
          staying away from these harmful substances. We must create a culture
          that values wellness and well-being over addiction and escapism. And
          don't be discouraged. You are not alone in this struggle. Every social
          change in human history started from the passion of a few individuals
          who had a vision of a better world. A world without cigarettes,
          without booze, without any of that poison that clouds our minds and
          bodies. A world where we can have all the fun, all the happiness, all
          the freedom that we dream of without harming ourselves and others. A
          clean and peaceful life with no regrets whatsoever. Don't you want
          that? Don't you want to make a difference? Don't you want to be a
          hero? Then join me in saying no to smoking, alcohol and illicit drug
          use. Together, we can make it happen. Together, we can create a better
          world for ourselves and for generations to come.
          <br></br>
          <br></br>
        </p>
      </div>
    ),
    authorName: "Chinmoy Baruah",
    authorDesc: "...",
    authorAvatar: "/assets/images/Chinmoy.jpg",
    createdAt: "April 25, 2023",
    cover: "/assets/images/blog15.jpg",
  },

  {
    id: 14,
    title: "Sports & Health",
    category: "Sports & Health",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          Society, in the present day, is a lot more different from the society
          our grandparents and probably our parents lived in back in their
          times. The kind of lifestyle they lived included activities which were
          not only beneficial for their physical growth but also made them well
          aware of different sports and knowledge related to them. However, the
          scenario has drastically changed today.
          <br></br>
          <br></br>
          With societies evolving rapidly, people's lifestyle transformation is
          quite observable. Humans are compared to machines for we have been
          running round the clock every day not saving up enough time for our
          “self-care”. The food we eat is usually processed, thus, the amount of
          nutrients is very low in our diets. According to recent data, around
          38% of the Indian population have a desk job, which proves a low
          physical activity involvement.
          <br></br>
          <br></br>
          Living in the digital age has its own set of boons and curses. While
          having access to technology makes our life easier, it also brings
          about some undesirable changes which have long-term effects. Parents
          tend to use mobile phones or other digital devices to let their
          children play or remain distracted to keep themselves free. This is
          where everything goes down the hill. Children become more and more
          engrossed in the screens, playing online games or chatting with their
          peers over different social media platforms, thus, spending the
          majority of their time indoors. It is therefore our responsibility to
          engage ourselves, and also motivate the younger generation, to come
          out of their screens and enjoy the “real” world.
          <br></br>
          <br></br>
          Sports is such an activity which not only improves our physical growth
          but also strengthens our mental health. Through sports, one can learn
          integral values like cooperation, sportsmanship spirit, discipline and
          other important ethics. Being involved in sports also means having a
          proper diet with adequate nutrients. The individuals are conscious of
          their physical and mental health which keeps them aloof from the
          majority of illnesses. Sports prevent various lifestyle diseases like
          hypertension, Diabetes, chronic respiratory diseases, etc, ensuring
          endurance and greater lifespan.
          <br></br>
          <br></br>
          The Covid-19 pandemic is a recent example of how important it is to
          keep ourselves fit and resistant to any diseases. It was a testimony
          of the famous line “survival of the fittest”. Millions of people
          perished due to weak immune systems. Despite having advanced science,
          the mortality rate was at its peak for the poor lifestyle of human
          beings.
          <br></br>
          <br></br>
          It’s high time we understand the value of Sports and Health and pledge
          to make changes in our daily routines and spare at least an hour for
          our fitness to live a healthy and disease-free life.
        </p>
      </div>
    ),
    authorName: "Archita Das",
    authorDesc: "...",
    authorAvatar: "/assets/images/Archita.png",
    createdAt: "December 03, 2022",
    cover: "/assets/images/blog8.jpg",
  },

  {
    id: 13,
    title: "Sports & Health",
    category: "Sports & Health",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          {" "}
          “Chak de India” , “Health is Wealth” are some of the famous saying
          quotes by people which increases the significance/importance of sports
          and health. Sport is the most interesting activities which different
          people love to do in their daily life. It maintains the physical
          fitness of an individual which makes a man/woman fit. A fit person is
          a person which is not suffering from any illness or diseases and is
          physically and mentally strong.<br></br>
          <br></br>
          In recent period of time, maintaining a good health has become the
          first priority of each an individual in these world. Health is the
          most crucial part of a human life. Since our childhood we have been
          taught to eat healthy foods and vegetables to stay healthy. Talking in
          general words, we everybody as an individual love to stay healthy and
          see our belongings healthy too.<br></br>
          Hence, it can be concluded that sports and health, both are equivalent
          to each other.
          <br></br>
          <br></br>
          Sport plays a vital role in increasing the respect of an individual.
          Many sport players who plays for their nation earns respect and faith
          by the society as well as from his/her nation. Now-a-days, there has
          been an increasing craze among all children for participating in and
          playing sports. It increases their efficiency to play in different
          events of sports organized by the school authority, universities, and
          various other Non-profit Organization who organize these sports events
          at different levels for youth to come forward and play for their
          respective school, district, and state. Different types of sports
          played and their importance are discussed as under:
          <ol>
            <li>
              There are some of the sports like chess, table tennis, etc. which
              is called indoor sports which increases the mental efficiency of
              an individual which makes them mentally fit.
            </li>

            <li>
              There are also some of the sports like cricket, hockey, football,
              basketball, volleyball, etc. which increases the physical
              efficiency of an individual.
            </li>

            <li>
              Similarly, there are some sports like martial arts, boxing which
              makes an individual mentally as well as physically strong.
            </li>

            <li>
              Among all other sports, athletics is the most common game played
              by the participants at Olympics, Commonwealth games, etc.
            </li>
          </ol>
          <br></br>
          There are also many other sports which are played which is equally
          important and they include their own significance.
          <br></br>
          <br></br>
          Apart from all the different benefits of playing sports, some of the
          most significance of it is that the Railway Authority have a sports
          quota which provides a fixed a number of vacancies for different
          categories of posts. One of the sports which help in getting railway
          job is cricket, many players have been selected through these sports
          quota, these increases the desire among players to play sports. Other
          importance of playing sports is that it has great significance in
          increasing the economy of the country. People love to watch different
          sports like, cricket, football, basketball, badminton, etc. in their
          T.V. More people will watch those sports channels it increases their
          TRP and greatly increase their revenues. These amount of revenue
          earned by the owner of the channel will equally be payable to the
          Income tax Authority of the country as a tax paid which increases
          economic wealth of the country. Similarly, the tax paid by the big,
          famous, and reputed sports persons also adds wealth to the economy of
          the country.
          <br></br>
          <br></br>
          There has been a great initiative by the government as well as
          undertakings in increasing the craze of playing sports in India.
          Government provides initiative of sum of good amount of money to the
          players who won medals for their country in Olympics and other
          International games they also provide a government job to them. More
          the medals won by a country more it increases the goodwill of the
          country among different countries. On the other hand, the
          non-government undertakings provide initiatives by organizing
          different sports events at different for every age group people. They
          also provide certificates, medals, money, trophies to the wining team.
          These games help to grow the efficiency of the player and the best
          player of the event promoted to next level of it. Hence, they have
          been playing a great significant role in increasing the efficiency
          among the players to play and be healthy.
          <br></br>
          <br></br>
          <strong>Health: </strong>During covid-19 pandemic it was said by the
          expert that in the coming year good health of a person would be
          his/her identity and on the basis of he/she would be hired as an
          employee of an organization. This emphasizes all the youths to be
          physically and mentally fit which will not only enable them to be an
          eligible candidate but it will also increase their age period. Many
          more initiatives were taken by the non-government undertaking through
          conducting webinars or seminars regarding good health, benefits of
          maintain a good health, not to consume products which are harmful for
          your health. Impact of covid-19 pandemic has increased the number of
          hospitals to provide healthcare facilities to every section of the
          society. These has also increased the requirement of number of doctors
          and co-workers and ultimately result in increasing the requirement of
          number the employment in hospital sector. These has also reduced the
          problem of unemployment in the society. Many state government
          including Assam has increased its number of hospital in the state
          which is beneficial for all the section of the society.
          <br></br>
          <br></br>
          Many manufacturing company of different products started giving more
          attention to the producing of products which is healthy and maintains
          good health of a human. For increasing the awareness of health Prime
          Minister of India has introduced the day called Yoga day on 21st June
          every year which acts as a reminder to every individual that how
          important is to be healthy. Some people like to go to gym to be
          physically fit but being physically fit is not the only way to be
          healthy, priority to the mental health is equally necessary for an
          individual.
          <br></br>
          <br></br>
          Being healthy does not simply mean staying physically fit. But it
          should be healthy from mental perspective also. People love to make
          body like their favourite film stars but apart from all of it this
          people should equally remain healthy from the state of mind. Mental
          distress is becoming an increasing cause for suicides. No people like
          to talk about how to remain mentally fit. Till today also at least one
          suicide is attempt by a person. Each year there has been an increasing
          number of suicide attacks. These is the case which requires immediate
          action by the government as this necessarily be eliminated.
          <br></br>
          <br></br>
          Some of the best ways to remain mentally healthy are discussed below:
          <ol>
            <li>
              <strong>Eat healthy food: </strong>More you will eat foods which
              are good for your health more you will feel your inner strength
              and you will be mentally fit, on the other hand, more you will
              consume junk food more there will be the chance of increasing
              diseases.
            </li>
            <li>
              <strong>Listening music: </strong>Music which are pleasant to your
              mind or the music you like to listen will be acting as the best
              medicine to reduce your mental distress, as it calms you down.
            </li>
            <li>
              <strong>Talk to the person you have faith upon: </strong>You will
              surely have at least one person in your life on whom you have a
              faith upon and can trust the most. Try to talk to them, it will
              surely reduce the burden in your mind and you shall feel relief.
            </li>
            <li>
              <strong>Going for a walk: </strong>Precisely there are many people
              who loves to be alone. They like to go for a morning or evening
              time This can also be a possible way to eliminate your mental
              distress.
            </li>
            <li>
              <strong>
                Not to do things which will increase your depression:
              </strong>{" "}
              Try not to think more of what makes you feel depressed. Just be
              calm, and as everything happens for a reason. May be god has
              decided something different for you.
            </li>
            <li>
              <strong>Cry out: </strong>Many people think that men should never
              cry. But crying can also act as one of the best medicine which
              will let your stress out of your mind.
            </li>
            <li>
              <strong>Watching pleasant drawings: </strong>There are various
              types of drawings like abstract drawings, illustrations, sketches,
              paintings which is pleasant to see and which also decrease stress
              of your mind.
            </li>
          </ol>
          <br></br>
          Hence, being calm during depression would be a better option then
          taking too much of stress.
          <br></br>
          <br></br>
          Surroundings also determine an individual’s health. Birth of new
          diseases takes place from dirty surroundings where many biodegradable
          and non-biodegradable resources have been thrown and these resources
          also causes sour and bad smell which is not good for your health. So
          make your surroundings clean as far as possible. It will be beneficial
          for your health as well as for your life.
          <br></br>
          <br></br>
          There are many ways which help you to stay healthy. Some of them are
          discussed as under:
          <ol>
            <li>
              <strong>Do exercise: </strong>Doing exercise as a daily routine
              for almost half-an-hour is a very good thing to do. It will keep
              your mind fresh.
            </li>
            <li>
              <strong>Play outdoor game instead of indoor game: </strong>Today,
              many children love to play game in their phone which is making
              them lazy. They should be taught the benefits of playing outdoor
              games.
            </li>
            <li>
              <strong>Do not take too much of stress: </strong>Now-a-days,
              people are only busy with their works. Among this they take too
              much of stress which is not bearable for all of them. People
              should give importance to their health along with their works.
            </li>
            <li>
              <strong>Good food: </strong>Have good food in a standard time. You
              should not eat food too late or too early. It should be in a
              proper time
            </li>
            <li>
              <strong>Discipline: </strong>Discipline means the good behaviour
              of following the rules and regulations in daily life, as there has
              always been found a new type of diseases till today and there
              always being fear of being attacked by those diseases. You should
              always do everything by making a proper plan.
            </li>
          </ol>
          <br></br>
          <br></br>
          <strong>Conclusion: </strong>Sports and Health both plays an important
          role in daily life of an individual and both will surely equally be
          playing a vital role in future time as both of these are equivalent to
          each other. Also, we can conclude that sports and health necessary to
          stay healthy. And in this world only the person who is physically as
          well as mentally strong and fit can survive for a longer period of
          time. Playing sports and maintaining a good health is a never ending
          activities of an individual in his/her life, even after being too old
          in age human should be careful to their health. Hence, whatever be the
          occupation of an individual he/she can should play sports and one
          should always be conscious to their health.
          <br></br>
          <br></br>
        </p>
      </div>
    ),
    authorName: "Rambabu kumar sah",
    authorDesc: "...",
    authorAvatar:
      "https://assets.stickpng.com/images/585e4bf3cb11b227491c339a.png",
    createdAt: "December 03, 2022",
    cover: "/assets/images/blog7.jpg",
  },

  {
    id: 12,
    title: "Meraki - An article writing competition",
    category: "meraki",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <h6 style={{ fontWeight: "600" }}>
          DECODING MENTAL HEALTH: THE PANDEMIC VERSION
        </h6>
        <p>
          The coronavirus pandemic unleashed extraordinary ways of life on every
          person on this globe. With an entirely new disease unheard of, came a
          string of unpredictable events that disrupted every activity of human
          civilization till date; barely an aspect untouched by the pandemic
          waves. With so much happening at a quick pace, the world outside the
          human body came to a sudden standstill. But the human mind did not. It
          began witnessing an assortment of complex emotions- both existent and
          emerging. These were inescapable states of mind making no person
          exceptions to mental health imbalances.<br></br>
          <br></br>
          <span style={{ fontWeight: "600", fontStyle: "italic" }}>
            Realising the Value
          </span>
          <br></br>
          Mental health is a matter of paramount significance. This subject in
          human health discourses has often been on the underestimated,
          neglected and ignorant side where people forget to take care of the
          very engine that drives the body to any movement. Everything that we
          as human beings experience in our day to day lives has an influence-
          negatively as well as positively on our cognitive processes and
          functions. This hub of masterly operations takes responsibility for
          receiving, extracting, deciphering and responding to every stimulus
          the body is exposed to. The brain engineers the mind, controlling the
          body, which is otherwise mere skin. When vital cognitions fail, the
          mind goes astray followed by mental breakdowns and malfunctions,
          causing the body to behave in the most bizarre and dangerous ways.
          Yet, the importance of mental health is meagre.<br></br>
          <br></br>
          People are always keen on doing the maximum and spending the best on
          everything that keeps their bodies distant from diseases and
          deficiencies; ensuring hygienic environments for cooking, eating and
          storing, maintaining clean homes and surroundings, and going the extra
          mile on additional intake of nutritional supplements separately. But
          for the health-supporting mentally, the pivot of all bodily functions,
          the count stands at little to nothing.<br></br>
          <br></br>
          <span style={{ fontWeight: "600", fontStyle: "italic" }}>
            Mental Health and Pandemic: Hand in Hand
          </span>
          <br></br>
          The Covid-19 pandemic has paved paths for infinite problems. The world
          is in shock and suffering. Humans are not only contracting the
          coronavirus but have also found themselves in fear, anxiety, stress,
          trauma, disorders and depression. For people who test positive with
          the contagion, dreadful times burden them in hospitals. Whereas,
          people staying at home are strained with new realities like total
          confinement, work from home, online classes and harsh ones like cuts
          in salaries, job losses and dropouts. Also, not leaving out fake news,
          rumours and excessive exaggerations being circulated through various
          media. All these are new and difficult adaptations. Thus, both
          physical and mental exhaustion prevail. Every person, if not infected
          by the virus, have experienced mental issues at some point or the
          other, as life sets adrift from normalcy.<br></br>
          <br></br>
          Circumstances as such make people aware, acknowledge and assess their
          mental health state. It is evident how a physical ailment can emanate
          other ailments as well. Mental health is sensitive and fragile to
          stimuli propelling negative emotions, just like the physical body is
          acute to pathogens. Numerous courses, webinars, workshops and
          therapies related to mental healthcare are being organized more than
          ever before. For decades, the prevalence of mental health conditions
          has been broadly unchanged; this trend changed in 2020 with the
          outbreak of the Covid-19 pandemic (2020, OECD). Hence, a genuine
          demand for mental health resources is at rise as people struggle every
          day.<br></br>
          <br></br>
          <span style={{ fontWeight: "600", fontStyle: "italic" }}>
            Mental Health and Pandemic: Hand in Hand
          </span>
          <br></br>
          As days passed and lockdowns extended, staying at home became more
          troublesome. Different age groups experienced different issues to cope
          with. They are still being confronted in the present day.<br></br>
          <br></br>
          At an age when children should be full of energy, socially active and
          spirited, they appear withdrawn. Children are now locked in their
          homes due to school closures having very little room for interaction
          with their fellow friends. Physical activities are compromised. As a
          result, they are experiencing loneliness, lack of companionship,
          boredom, irritation and sadness. During such times, they also fear
          separation from their parents and loved ones on whom they depend
          entirely. Children have become restless due to lack of outdoor
          activities and interactions which they were always accustomed to. In a
          report, findings reveal that children have felt uncertainty,
          isolation, poor appetite, agitation, inattention and
          separation-related anxiety. (2020, PubMed)<br></br>
          <br></br>
          Adolescents are facing frequent and enormous emotional instability
          during the pandemic. Already in an age group, where they pass from
          numerous physical, hormonal and emotional transitions, the pandemic
          exerts greater changes on them. Many important settings like
          classrooms, seminars, competitions and other gatherings have gone
          virtual. The role of social media platforms is now even more
          prominent. Young teenagers are burdened with daily flushes of
          information, academic routines, social isolation, extra-curricular
          courses followed by mood and eating disorders, panic attacks,
          persistent stress of a busier life existing virtually, anxiety over
          balancing every task as a student, inferiority complexes from social
          media platforms depicting perfection and others. Students have also
          been anxious over the cancellation of examinations and other
          disruptions in their educational fields (2020, PubMed). Number of
          cases with depression, disorders and suicides have shot up. The mental
          health of adolescents is seriously severed accruing to all the
          newness.<br></br>
          <br></br>
          The mental health status has also taken an unhealthy leap for adults.
          They are the victims of unemployment and pay cuts. Adults who have
          lost their jobs are going through heavy mental stress. They are in a
          very tough position due to financial insecurity which has affected
          them and also their families. A report by Kaiser Family Foundation
          (KFF) has proved that people who lost their jobs during the pandemic
          reported greater incidence of anxiety and depressive disorders than
          those who still had their jobs. Others who are fortunate to retain
          their jobs with certain pay cuts have been introduced to ‘work from
          home’. While these have been convenient from home desks, they are also
          challenging because the division of workplace and household tasks are
          now converged. Spending hours in front of screens and also making sure
          household chores are properly done simultaneously creates a pressure
          of balance between the two. Mostly, women have endured the brunt of
          this challenge, both working and non-working.<br></br>
          <br></br>
          The last age group which has also faced mental health issues are the
          elderly people. Senior citizens have also suffered from the inability
          to step outside. At such ages of physical and mental weakness, new
          rules and health protocols are difficult to process and follow. They
          depend majorly on external support and require communication. The
          pandemic has disturbed their social connections with selected family
          members and friends due to lack of physical contact, they worry about
          their existing health problems, their medications and treatments along
          with increased fears of illness and death. Such a period has made them
          more vulnerable to helplessness.<br></br>
          <br></br>
          <span style={{ fontWeight: "600", fontStyle: "italic" }}>
            The Warriors and Grievers
          </span>
          <br></br>
          It will be unfair to sideline the mental health conditions of
          frontline workers who are ensuring the safety of every person, people
          who are infected with the virus and families of the covid infected
          patients. Doctors, nurses, medical associates and healthcare workers
          are drained physically and mentally because of their uninterrupted
          pursuit of duty. Many have even lost their lives due to the virus,
          overwork and absence of proper rest. Patients of Covid-19 continuously
          worry over their speedy recovery. Their families also go through the
          mental distress of fear and anxiety.<br></br>
          <br></br>
          <span style={{ fontWeight: "600", fontStyle: "italic" }}>
            Prioritizing Mental Health
          </span>
          <br></br>
          Before the global disease struck, only a section of people prioritized
          their mental health. Mental health centres, helplines and related
          assistance operated minimally in countries. Instances of underfunding
          and zero funding of these provisions were also prominent. With the
          advent of the virus, these loopholes widened. Although the demand for
          mental health services expanded, their initial availability and
          accessibility were not enough. Only with time, has mental health
          support increased at an impressive pace. During this health crisis,
          people should indulge in healthy habits that build self-realisation
          and stay away from any information or habits harming their positive
          energies and beliefs. Focus should be on the good of oneself as well
          as others. If professional psychological intervention is required, one
          must not hesitate to get help. The gravity of mental illnesses and
          their effects needs to be emphasised. They are not simple or silly.
          They are indeed as serious as any other physical problem. In light of
          the pandemic, governments can also play their part in spreading mental
          health awareness. Just like mandatory basic covid protocols are
          imparted through various media and communication networks, the same
          can be done for accentuating mental health advices and details. Thus,
          dealing with mental wellness as eagerly as with physical. This can
          produce substantial impacts in a positive direction.<br></br>
          <br></br>
          <span style={{ fontWeight: "600", fontStyle: "italic" }}>
            Summing up
          </span>
          <br></br>
          The essence of achieving a healthy body will be complete only if one
          equally prioritises mental and physical health components. All
          research, findings and practical experiences support the affirmation
          and adoption mental health awareness. The pandemic is an eye-opener to
          stereotypes, stigmas and taboos related to mental health. Let us be
          wise in disseminating the term health in ‘Health is Wealth’. One who
          embraces good health, nurtures both body and mind.<br></br>
          <br></br>
        </p>
      </div>
    ),
    authorName: "Dolshi Rabha",
    authorDesc: "...",
    authorAvatar:
      "https://assets.stickpng.com/images/585e4bf3cb11b227491c339a.png",
    createdAt: "July 20, 2022",
    cover: "/assets/images/meraki1.JPG",
  },

  {
    id: 11,
    title: "Meraki - An article writing competition",
    category: "meraki",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <h6 style={{ fontWeight: "600" }}>
          DECODING MENTAL HEALTH: THE PANDEMIC VERSION{" "}
        </h6>
        <p>
          Here’s a quick story of my father battling Covid, before I begin
          talking about Mental Health. I do not know how short it’s gonna be,
          but I’m gonna keep it as crisp as possible.
          <br></br>
          <br></br>
          On 10th Oct, 2020 my father was tested positive for Covid-19. It
          wasn’t as severe as we have seen others suffer so we decided to keep
          him in isolation in our guest room. As my mother is a Nurse who
          actively worked on and off on many covid cases, she knew all the
          procedures required to make anyone feel better. And at that time the
          vaccines weren't ready yet, so hospitals used the usual medicines for
          treatment. My father is a very stubborn person by nature, he didn’t
          want to stay in the hospital ward. So we all collectively decided to
          keep him home, isolated. Also home quarantine was something many
          infected people opted for. After a couple of days his body temperature
          started rising, we fed him the medicines as prescribed and even
          injected a bottle of saline at midnight at home. On the very next day,
          his health started deteriorating. We immediately rushed him to the
          hospital where my mother works, Central Hospital Maligaon, Guwahati.
          Fortunately due to having connections with the senior doctors at the
          hospital my father was immediately rushed to the ICU. He was under
          observation for 24 hours. But the next day one of the doctors who was
          working in Covid Ward referred my father to GNRC Medical, Amingaon.
          Stating that it will be quite risky to keep him here for long.
          <br></br>
          <br></br>I was there with my mother when he was being shifted to a
          different hospital. We immediately rushed him to GNRC, in an
          ambulance. It was my first time stepping inside an ambulance. It’s
          frightening and scary. My father sat right in front of me with an
          oxygen mask up on his face. At that moment inside the ambulance I
          heard nothing but the siren of the ambulance. When the ambulance
          rushes through chaotic and busy streets, the eyes of pedestrians are
          on you. I do not remember how I felt at that moment or did I even feel
          anything? My brain blocked all the emotions and tapped out only the
          adrenaline. I carried all the essentials and documentation required
          for the admission with me all the time. I was making calls to my
          relatives and father’s colleagues, informing them about us being at
          the hospital. After all the hassle he was finally shifted inside and
          his treatment began. My body began to ache, I was tired and drenched
          in sweat. As we had to walk up and down to the 5th floor several
          times.<br></br>
          <br></br>
          One of my aunts called me, when I received the call; I wasn’t able to
          even utter a single word. I immediately passed my phone to my mother
          and let her do the talking. Until then I was so busy in fulfilling all
          the necessities of the hospital required to begin the treatment, I
          didn't knew what would happen if something went wrong. Suddenly my
          mind hit me with the thought of living a life ahead without a father.
          And that’s when the first tear rolled down my cheeks. It took me a
          while to calm myself down. After a couple of hours one of my cousins
          showed up at the hospital to be there with us. My mother and cousin
          decided to stay overnight at the hospital. I returned back home. I
          freshened myself and immediately laid down on my bed. The relief,
          stress and tiredness flooded inside, disappeared. After many days of
          treatment he was tested negative, but they didn’t discharge him for
          another 3 days. Then he returned home after triumphing covid. It’s
          been almost 8 months and he is making progress to normal after
          suffering from post covid complications.<br></br>
          <br></br>
          <span style={{ fontWeight: "600", fontStyle: "italic" }}>
            My thoughts on Mental Health during Pandemic
          </span>
          <br></br>
          Waking up in total oblivion isn’t a possibility anymore. We’re flooded
          by the evil tidings. My mind takes endless runs trying to scream and
          escape the chaos inside. We are all drowning into the ocean of loss,
          grief and despair. Unable to fathom the grasp of the situation. The
          stigma of anxiety and apprehension has always been a part throughout
          my whole childhood. And during this time of crisis, the levels have
          only peaked. Failing to express myself from beneath got nothing but
          vague acknowledgement from others. To the point where I start
          questioning my own sanity, doubting my beliefs and trying to make
          sense from the most nonsensical occurrences in the surroundings.
          <br></br>
          <br></br>
          We all lost someone; a guardian, a friend, a neighbour and an
          acquaintance. Witnessing them turn cold in front of our eyes rushed an
          immense amount of emotions and feelings inside us. To the point where
          due to the extremely hectic arrangement of cremation or burying, we
          couldn’t even bid a farewell to them or get a glance through their
          lifeless faces. Due to the constraints and orders from the
          authorities, it is quite burdensome to meet someone physically.
          Especially in these times when you need a shoulder to cry upon, a hug
          to loose upon and a hand to hold on.
          <br></br>
          <br></br>
          Who do we blame for the loss? What could I’ve done? How long will I
          need to bear this?These are some of the questions that I've been
          trying to find answers to. Little did I know, chasing the retort gave
          nothing but more anger, more instability and insaneness. Living within
          the four walls for so long, craving for human interaction, suffocating
          in the chaos, unable to cope with loss and grief made me impatient and
          restless. I avoided any human contact physically and mentally. The
          endless scrolling of aimless social media consumed the little leftover
          inside me.
          <br></br>
          <br></br>
          Fortunately, due to the existence of few people in my life. It wasn't
          as tough for me as others. As I do not seek any answers anymore. I
          want someone to just listen and give me the genuine and worthy
          validation that I want. Before soliciting solutions, the questions
          need to be addressed first in any circumstances. Writing has immensely
          helped me to declutter my mind and understand my thought process. This
          year I started blogging. And I can proudly say that I made some
          progress, it’s definitely minimal. But, I made it till here. I would
          like to shamelessly share a few of my small achievements. Published
          one of my articles on The Assam Tribune and even participated in a
          writing competition organised by VIT. Now, I have realized; life is a
          sequence of parables. At one point you’ll be at the deepest trench but
          the future definitely seems golden. So, just hang on.
        </p>
      </div>
    ),
    authorName: "Ankur J. Basumatary",
    authorDesc: "...",
    authorAvatar:
      "https://assets.stickpng.com/images/585e4bf3cb11b227491c339a.png",
    createdAt: "July 20, 2022",
    cover: "/assets/images/meraki2.JPG",
  },

  {
    id: 10,
    title: "Meraki - An article writing competition",
    category: "meraki",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <h6 style={{ fontWeight: "600" }}>
          DECODING MENTAL HEALTH: THE PANDEMIC VERSION
        </h6>
        <p>
          Here’s a quick story of my father battling Covid, before I begin
          talking about Mental Health. I do not know how short it’s gonna be,
          but I’m gonna keep it as crisp as possible.
          <br></br>
          <br></br>
          <span style={{ textAlign: "center" }}>
            “MENTAL HEALTH IS NOT A DESTINATION BUT A PROCESS IT’S ABOUT HOW YOU
            DRIVE, NOT WHERE YOU ARE GOING.”
          </span>{" "}
          <br></br>
          <br></br>
          Mental health – a topic which does not get talked about as often as is
          needed these days. This concept is still vague for a lot of people
          even in 2021, an illness because of which people are taking their own
          lives. The reason for this may be because of the deep rooted stigma of
          the society that our mental health is not as important as our physical
          health; seeing is believing, what people cannot see, they tend to not
          believe that it exists. No one ever feels the need to talk about
          mental health, “it does not exist”, “i never thought you were so
          weak”, “you are overthinking”, “it’s not like that” are some of the
          common dialogues which we have to hear. But people need to realize
          that our brain, which is the most important organ of our body also
          needs rest, it needs frequent breaks, it needs to be taken care of the
          most.
          <br></br>
          <br></br>
          According to a report in google, 46% of the people who commit suicide
          every year has been suffering mentally and this number is increasng
          day by day. This phenomenon is occuring worldwide so we cannot limit
          the consequences to only one country or blame a specific group. It’s a
          concept which is familiar to all irrespective of age groups but no one
          is willing to talk about it because of the fear
          <br></br>
          <br></br>
          Of being shamed or being called a lunatic. However, even if a few
          people do understand the situation, they will give solutions to seek
          help or to consult a psychiatrist. But most of the times it does not
          work because the person going through problems already knows that
          something is not right with them and they need to go for therapy but
          they cannot because they either fear what the society will think or
          they are not ready for it.
          <br></br>
          <br></br>
          The covid19 pandemic has made it worse for people who were already
          suffering from mental health related issues. Staying cooped up in one
          house with all the family members for almost a year has not been easy
          for most people. True, they can be considered lucky as compared to the
          people fighting the virus but not everyone has a loving or supportive
          family and that has made matters worse. Domestic violence and sexual
          harrasment cases has increased tenfold during this period and the
          ignorance of the society during such cases has not done anything to
          help. People find it hard to express themselves because they will be
          considered as vulnerable and weak or in some cases as seeking
          unnecessary attention. Speaking from personal experience, my aunt’s
          entire family was infected with the virus and her eldest son also went
          through mental trauma which resulted in him being hospitalized. My
          cousin could not understand the situation and commented that she never
          thought he could be so weak. Just because a person does not show does
          not mean it is not there, he/she may be suffering internally and the
          people around him would not have a single clue, he/she could be
          intending self harm and no one would even know. And when we keep
          bottling things up, there may come a point when our emotions will be
          at the peak and we will burst out which may harm us more.
          <br></br>
          <br></br>
          Our schools and colleges have contributed a lot in encouraging mental
          health issues. Coming from a ba student who spent her entire 2nd and
          3rd semesters in online classes, this mode of teaching has caused a
          lot of health related problems (both physical and mental), the latter
          would not have been a severe case if the teachers would have been more
          understanding and less dominating or demanding. I have had teachers
          saying we can go to them if we face any problems, yet at the same time
          they would not stop spreading negativity, calling us lazy and
          uncultured and also burdening us with assignments and tests with no
          regard for our well being. In some cases, i have also seen a few cases
          where the teacher would ask the student to postpone her medical
          (blood) test only because she gave a test of 20 marks to be submitted
          within 45 minutes. All this takes a severe toll on the student. True,
          in some cases, the teachers may also be suffering but they do need to
          realize that we are all in this together and we need to help and
          support each other instead of simply spreading negativity or taking
          our anger out on people whom we consider to be weaker than us.
          <br></br>
          <br></br>
          Mental health has no regards for our wealth and power; a person who
          has everything can also be suffering in silence. We never know the
          inner battles which people have to fight for themselves and many a
          times if we get to know about them, we tend to ignore or ask them to
          stop acting like a kid and grow up. People need to realize that
          anyone, literally anyone regardless Of age, gender, profession, net
          worth can go through this phrase. The best people can do is to be kind
          and not spread unnecessary hate. Even if they do not understand what
          the other person is going through, it is important to ensure that we
          do not guilt trip the other person into thinking that what they are
          going through is unusual or cannot be treated. Gaslighting or not
          letting other people express their opinions freely can also result in
          mental trauma. In such cases, it is
          <br></br>
          <br></br>
          Necessary to seek help from trusted people. Therapy should be sought
          by everyone even if they believe that nothing has happened to them,
          because trust me, everyone is suffering internally and needs help.
          Also try to be a good listener and always be kind to others; kindness
          costs nothing.
        </p>
      </div>
    ),
    authorName: "Ridhwaana Nasser",
    authorDesc: "...",
    authorAvatar:
      "https://assets.stickpng.com/images/585e4bf3cb11b227491c339a.png",
    createdAt: "July 20, 2022",
    cover: "/assets/images/meraki3.JPG",
  },

  {
    id: 9,
    title: "Thrive with Hope!",
    category: "Poem",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          We say that it is the strength that keeps us going.
          <br />
          It is the will that keeps us going,
          <br />
          It is the aim that keeps us going
          <br />
          It is the faith that keeps us going!
          <br />
          <br />
          I wonder! What is the truth?
          <br />
          What to believe, and what not to...
          <br />
          I say it is the hope....
          <br />
          Hope brings the will to life,
          <br />
          Hope gives the strength!
          <br />
          Hope brings in the faith,
          <br />
          Hope is the air we breathe.
          <br />
          <br />
          Hope gives us a new life!
          <br />
          Hope makes the future worth living
          <br />
          The Life, worth living!
          <br />
          Hope! Beginning of always and forever
          <br />
          Have faith with hope!
          <br />
          Bloom and shine!
          <br />
          Till you Rise and Thrive.
        </p>
      </div>
    ),
    authorName: "Sangeeta Sen",
    authorDesc: "...",
    authorAvatar: "/assets/images/sangeeta.png",
    createdAt: "April 30, 2022",
    cover: "/assets/images/blog6.jpeg",
  },

  {
    id: 8,
    title: "বৃক্ক ৰোগৰ কিছু তথ্য আৰু একান্ত অনুভৱ",
    category: "Blog",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          ....হঠাতে জীৱনলৈ বিপর্যয় নামি আহিল। কি কৰোঁ... কি নকৰোঁ... । মোৰ
          নিকটতমৰ দুয়োটা বৃক্কই (Kidney) বিকল হ'ল। তাকে৷ ডাক্তৰৰ ভুল ঔষধৰ বাবে।
          হে ভগৱান... কি কৰোঁ... চকুৰ পানীৰে বাট নেদেখি মই খেপিয়াই ফুৰিছিলো অ'ত
          ত'ত - জীৱনৰ এয়া নির্মম আঘাত... মোক্ষম আঘাত। তিল তিলকৈ শেষ হৈ গ'ল জীৱনৰ
          ৰঙীণ সপোন। অথচ সপোন ভাঙিলেও জীৱনৰ ছন্দ হেৰাই যাবলৈ নিদি ঢাপলি মেলিছিলো
          শুৱাহাটীলৈ.... গুৱাহাটীত চিকিৎসা কৰি সুফল নোপোৱাত গুৱাহাটী চিকিৎসা
          মহাবিদ্যালয়ৰ সহযোগী অধ্যাপক ডাঃ মঞ্জুৰী শৰ্মাৰ পৰামৰ্শ আৰু দিহামতে
          চেন্নাইৰ এপ'ল হস্পিতেলৰ ডাঃ ৰাজীৱ এ. আন্নিগিৰি তত্ত্ৱাৱধানত জৰুৰী
          বিভাগত ভৰ্তি কৰিলো। সেই সময়ৰ কথা – স্মৃতিৰ পেৰাত এনেভাৱে সংৰক্ষণ কৰি
          ৰাখিছো - এই মুহূর্তত য়া প্ৰকাশ কৰা নিষ্প্রয়োজন। এই ছেগতে আমাৰ
          দুৰ্দিনৰ সময়ত সহায়ৰ হাত আগবঢ়োৱা সুহৃদসকলক প্রণাম আৰু শ্ৰদ্ধা তথ্য
          ধন্যবাদ জনালো। একান্ত অভিজ্ঞতাৰ ভিত্তিত এই লেখা প্ৰস্তুত কৰা হৈছে -
          কোনো সদাশয় ব্যক্তিৰ কিঞ্চিত সহায় হ'লে পৰম কৃতাৰ্থ হ'ম।
          <br /> <br />
          বৃক্ক যাক ইংৰাজীত কিডনী (Kidney) বুলি কোৱা হয় - সেয়া মানৱ শৰীৰৰ এনে
          এটা অংগ - যিটোৱে আমাক জীয়াই থকাত অৰিহনা যোগায় আহিছে। আমাৰ শৰীৰত দুটা
          বৃক্ক থাকে। ই লেচেৰা মাহৰ গুটিৰ দৰে আকৃতিৰ আৰু মানুহৰ হাতৰ মুঠিটোৰ
          সমান। মাংসপেশী আৰু কামিহাড়ৰ মাজ আবৃত্ত হৈ থকা বৃক্ক পেটৰ মাজভাগৰ পিছ
          অংশত অৱস্থিত। মানৱ শৰীৰত বৃক্কৰ প্ৰধান কাম হৈছে তেজত জমা হোৱা টক্সিন
          আৰু অলাগতিয়াল পানীবোৰ নিৰ্গত কৰা । আমাৰ তেজ বৃক্ক ধমনীৰে প্ৰবেশ কৰি
          নেফ্ৰনত সোমায় । বৃক্কৰ কাৰ্যকৰী অংশই হৈছে নেফ্ৰন। এই নেফ্ৰনসমূহে
          চেকনিৰ কাম কৰে। বৃক্কত সাধাৰণতে অসংখ্য নেফ্রন থাকে। নেফ্ৰনবোৰৰ জৰিয়তে
          বৃক্কই তেজ পৰিশোধন কৰে আৰু দেহৰ পৰা আবর্জনা, অতিৰিক্ত তৰল পদাৰ্থবোৰ
          মুত্ৰ বা প্ৰস্ৰাৱ হিচাপে দেহৰ পৰা বাহিৰ হয়। এই মূত্ৰ বৃক্কৰ মাজভাগত
          (Medulla) জমা হৈ বৃক্ক নলীৰে মুত্রাশয়ত জমা হৈ থাকে। সময়ে সময়ে
          প্ৰস্ৰাৱ হৈ মুত্ৰানলীৰে বাহিৰ হয়। পৰিশোধিত তেজ বৃক্কৰ শিৰাৰে পুনৰ
          দেহলৈ উভতি যায়। প্ৰস্ৰাৱ বা মুত্ৰ তৈয়াৰ হোৱাতো মানুহৰ দেহৰ অত্যন্ত
          লাগতিয়াল ব্যবস্থা। এজন সুস্থ মানুহৰ দৈনিক এক লিটাৰৰ পৰা দুই লিটাৰ
          পর্যন্ত মূত্ৰ তৈয়াৰ হয়। বৃক্কই তেজ পৰিশোধন কৰাৰ উপৰিও মানুহৰ
          অন্যান্য বছতো প্রয়োজনীয় কার্য নিয়ন্ত্ৰণ কৰে। সাধাৰণতে বৃক্কই
          প্রতিদিনে প্রায় ২০০ লিটাৰ তেজ পৰিশোধন কৰি পৰিষ্কাৰ কৰে। এই পৰিমান
          ২০/২১ বাল্টি তেজৰ সমান। বৃক্কই অন্যান্য কাম কৰাৰ উপৰিও মানুহৰ ৰক্তচাপ
          নিয়ন্ত্রণ কৰে, লোহিত ৰক্ত কনিকা তৈয়াৰ কৰি তেজত হিম'গ্লোবিনৰ পৰিমান
          সঠিককৈ ৰখাৰ উপৰিও ই শৰীৰত কেলচিয়াম আৰু ফচ'ফৰাচৰ ৰাসায়নিক পৰিবৰ্তনত
          সহায় কৰি হাড়ৰ গঠন আৰু কাৰ্য নিয়ন্ত্ৰণ কৰে। বৃক্ক সঁচাকৈয়ে শৰীৰৰ এক
          আচৰিত অংগ – যিয়ে দেহৰ অত্যাৱশকীয় কাম সমাধা কৰে। যদি কোনো কাৰণত এটা
          বৃক্ক নষ্ট হৈ যায় নাইবা উলিয়াই পেলোৱা হয় - তেতিয়া অন্যটো বৃক্কই
          সকলো কার্য সম্পন্ন কৰে। যেতিয়া বৃতৰ ৮০% তকৈ অধিক 'কলা' নষ্ট হয়,
          তেতিয়া কাৰ্য কৰিবলৈ অপাৰগতা আহি পৰে। স্বৰূপাৰ্থত বৃক্ক বিকল হৈ পৰে।
          এনে সময়তে চিকিৎসাৰ জৰুৰী প্ৰয়োজন আহি পৰে। বৃক্কৰ বিকল অৱস্থা তথা ৰোগ
          নিৰ্ণয় কৰিবলৈ বিভিন্ন ধৰণৰ পৰীক্ষা-নিৰীক্ষাৰ প্ৰয়োজন - সাধাৰণতে তেজত
          থকা ইউৰিয়া ক্রিয়েটিনিনৰ পৰিমান নির্ণয় কৰি বৃক্কৰ বিকল অৱস্থা ধৰা
          পেলোৱাত সহায়ক হয়। এজন সুস্থ মানহৰ তেজত সাধাৰণতে ইউৰিয়াৰ পৰিমান
          ২০-৪০ মিঃগ্রাঃ শতাংশ আৰু ক্রিয়েটিনিন পৰিমান ০.৫-১, ১-৫ মিঃগ্রাঃ শতাংশ
          থাকে।
          <br /> <br />
          বৃক্কৰ বিকল দুই ধৰণৰ –<br />
          <span style={{ marginLeft: "30px" }}>১/ হঠাৎ হোৱা বিকল অৱস্থা</span>
          <br />
          <span style={{ marginLeft: "30px" }}>
            ২/ দীর্ঘদিনীয়া বৃক্কৰ বিকল অৱস্থা
          </span>
          <br />
          <br />
          <br />
          <span style={{ fontWeight: "800" }}>১/ হঠাৎ হোৱা বিকল অৱস্থাঃ</span>
          <br />
          সাধাৰণতে হঠাতে হোৱা ৰোগ বা ভিতৰত হোৱা ৰোগৰ বাবে নতুবা ঔষধৰ
          পাশ্ৱক্রিয়া নতুবা অত্যাধিক বিষ নির্মূলৰ{" "}
          <span style={{ fontWeight: "600" }}>(pain killer)</span> বাবে সেৱন কৰা
          ঔষধৰ বাবে বৃক্ক বিকল হ’ব পাৰে। এনে ক্ষেত্ৰত উচিত সময়ত উচিত চিকিৎসা
          গ্ৰহণ কৰিলে পুনৰ বৃক্ক সুস্থ অৱস্থালৈ ঘূৰি যোৱাৰ পূৰামাত্ৰাই সম্ভাৱনা
          থাকে।
          <br />
          <br />
          <span style={{ fontWeight: "800" }}>
            ২/ দীর্ঘদিনীয়া বৃক্কৰ বিকল অৱস্থাঃ
          </span>
          <br />
          আমাৰ দেশত সাধাৰণতে দীর্ঘদিনীয়া বৃক্কৰ বিকল অৱস্থা কৰিব পৰা ৰোগসমূহ
          হ'ল –<br />
          <span style={{ marginLeft: "50px" }}>
            ১/{" "}
            <span style={{ fontWeight: "600" }}>
              Choonic Gomenulo nephrities,
            </span>
          </span>
          <br />
          <span style={{ marginLeft: "50px" }}>
            ২/ দীর্ঘদিনীয়া উচ্চ ৰক্তচাপ,
          </span>
          <br />
          <span style={{ marginLeft: "50px" }}>
            ৩/ দীর্ঘদিনীয়া বহুমূত্ৰ ৰোগ,
          </span>
          <br />
          <span style={{ marginLeft: "50px" }}>
            8/ সঘনাই হোৱা বৃক্কৰ সংক্ৰমন ৰোগ,
          </span>
          <br />‌{" "}
          <span style={{ marginLeft: "50px" }}>
            ৫/ বৃক্কৰ পাথৰজনিত ৰোগ আদি।।
          </span>
          <br />
          <br />
          বৃক্ক ৰোগৰ চিকিৎসা বিশ্বত দুই ধৰণে কৰা হয় – <br />
          <span style={{ marginLeft: "30px" }}>
            ১/ ডায়েলেইছিচ বা পৰিশোধন প্ৰক্ৰিয়া
          </span>
          <br />
          <span style={{ marginLeft: "30px" }}>২/ বৃক্ক সংস্থাপন।</span>
          <br />
          <br />
          ডায়েলেইছিচ (Dialysis) দই প্ৰকাৰৰ -<br />
          <span style={{ marginLeft: "30px" }}>
            ১/ হিমোডায়েলেইছিচ অৰ্থাৎ তেজ পৰিশোধন প্রক্রিয়া
          </span>
          <br />
          <span style={{ marginLeft: "30px" }}>
            ২/ পেৰিটেনিয়েল ডায়েলেইছিচ অর্থাৎ পেটৰ পৰা পৰিশোধন কৰা প্রক্রিয়া।
          </span>
          <br />
          <br />
          তেজ পৰিশোধন প্ৰক্ৰিয়াটো সম্পন্ন কৰিবলৈ যিটো যন্ত্ৰৰ প্ৰয়োজন তাক তেজ
          পৰিশোধন যন্ত্ৰ বোলা হয়। শৰীৰৰ পৰা যন্ত্ৰটোলৈ তেজ চলাচল কৰিবলৈ শৰীৰত
          কিছুমান শিৰাৰ সৈতে পথ সংযোগ কৰিবলগীয়া হয়। যেনে –<br />{" "}
        </p>

        <p style={{ marginLeft: "20px" }}>
          ছাবক্লেভিয়ান কেথেটৰ{" "}
          <span style={{ fontWeight: "600" }}>(Sub Clavian Catheter)</span>
          <br />
          <span style={{ marginLeft: "30px" }}>১/ ফিষ্টুলা (Fistula)</span>
          <br />
          <span style={{ marginLeft: "30px" }}>২/ ছাণ্ট (Shunt)</span>
          <br />
          <br />
          দীঘদিনীয়া বৃক্কৰ বিকল হোৱা ৰোগীসকলৰ ক্ষেত্ৰত সফল বৃক্ক সংস্থাপনেই
          উত্তম মাধ্যম। সকলো ৰোগীৰ বৃক্ক সংস্থাপনৰ বাবে উপযুক্ত নহ'ব পাৰে। বৃক্ক
          সংস্থাপনৰ বাবে চিকিৎসকে কিছুমান যাৱতীয় পৰীক্ষা নিৰীক্ষা কৰি ৰোগীৰ
          বৃক্ক সংস্থাপন উপযুক্ততাৰ বাবে সিদ্ধান্ত গ্রহণ কৰে। বৃক্ক সংস্থাপন
          প্রক্রিয়াটো এক অনন্য প্রক্রিয়া। এগৰাকী সুস্থ মানুহৰ পৰা বৃক্ক আনি
          ৰোগীৰ পেটত সংস্থাপন কৰা কাৰ্য জটিল কর্ম যদিও চিকিৎসকসকলে অধিক
          নিয়াৰিকৈ সমাপন কৰে। ৰোগীৰ নিজৰ বিকল হোৱা দুয়োটা বৃক্ক তেনেকৈ ৰাখি
          অন্য বৃক্ক সংস্পপিত কৰা হয়। আজীৱন ৰোগীগৰাকীয়ে সংস্থাপিত বৃক্ক
          সুন্দৰকৈ ৰাখিবলৈ দৈনিক প্রতিষেধক ঔষধ লোৱাটো একান্ত বাঞ্ছনীয়। ঔষধ সেৱন
          কৰা জৰুৰী ব্যৱস্থাটোক কোৱা হয়{" "}
          <span style={{ fontWeight: "600" }}>Immun osupressive drugs</span> |
          এই <span style={{ fontWeight: "600" }}>Immuano</span> লোৱা ঔষধ
          দুটিমানৰ নাম –{" "}
          <span style={{ fontWeight: "600" }}>
            Cyclosporin, Tacrolimus, My cophonalate Mefetail, Cyclosporin,
            Predmisolone
          </span>{" "}
          আদি। এই ঔষধ প্রত্যেকজন ৰোগীয়ে চিকিৎসকৰ পৰামৰ্শমতে বৃক্ক সংস্থাপনৰ
          পিছত নিয়মীয়াকৈ লব লাগে। ঔষধবোৰ শক্তিশালী। এই ঔষধবোৰ তেওঁলোকৰ{" "}
          <span style={{ fontWeight: "600" }}>Natural Immunity</span> টো দূৰ্বল
          কৰি পেলায়। বিশেষকৈ{" "}
          <span style={{ fontWeight: "600" }}>Pneumonia, Virus Infection</span>{" "}
          যেনে:{" "}
          <span style={{ fontWeight: "600" }}>
            Cytomegalovirus hepatitis B, hepatitis C
          </span>{" "}
          আদি সংক্রামণ ৰোগবিলাকে অতি সহজে তেওঁলোকক আক্ৰমণ কৰিব পাৰে। সেয়েহে এই
          ঔষধবোৰ লৈ থকা সময়ত বহুতো সংক্রামণ ৰোগ হ’ব পাৰে – গতিকে ৰোগীয়ে নিজেই
          সাৱধান হোৱাটো অত্যন্ত প্রয়োজন। ৰোগীয়ে সদায়ে পৰিস্কাৰ পৰিচন্ন তথা
          সুষম আহাৰ গ্ৰহণ কৰিব লাগে। অস্বাস্থ্যকৰ থকা ঠাই, অস্বাস্থ্যকৰ খাদ্য
          আৰু অস্বাস্থ্যকৰ পানী সেৱনৰ পৰা বিৰত থাকিব লাগে। বৃক্ক গ্ৰহণ কৰা
          ৰোগীয়ে মনত ৰখা ভাল – তেওঁ অন্যৰ বৃক্ক লৈ আছে – সেয়েহে সাৱধানে থকাতো
          বাঞ্ছনীয়। বৃক্ক দান দিয়া ৰোগীৰ কোনোধৰণৰ জটিলতা নাথাকে। তেওঁক বিভিন্ন
          ধৰণৰ পৰীক্ষা নিৰীক্ষাৰ অন্ততহে বৃক্ক দানৰ বাবে প্ৰস্তুত কৰা হয়। গতিকে
          ৰোগীগৰাকীয়ে চিকিৎসালয়ৰ পৰা আঁতৰি আহিলেও চিকিৎসকৰ পৰামৰ্শ মানি চলিলে
          জীৱন সুন্দৰ হৈ পৰিব। গতানুগতিক কর্মসূচীৰ মাজত সোমাই পৰিও বহুলোকক সংসাৰ
          যাত্ৰা কৰা দেখা যায়। অৱশ্যে যদিহে দৈনিক প্ৰস্ৰাৱৰ পৰিমান কমি গ'লে
          অথবা ৰোগীয়ে ৪৮ ঘণ্টা বা তাতোধিক জ্বৰত ভোগিলে পনৰ তেজৰ পৰীক্ষা বিশেষকৈ
          ইউৰিয়া ক্রিয়েটিনিন, হিমগ্লোবিন, শ্বেতৰক্ত কনিকা আৰু প্ৰস্ৰাৱ পৰীক্ষা
          কৰি থাকিব লাগে আৰু চিকিৎসকৰ পৰামৰ্শ লব লাগে।
          <br />
          <br />
          <span style={{ fontWeight: "800" }}>
            খাদ্য আৰু খাদ্য খোৱাৰ পৰিমান :
          </span>
          <br />
          সাধাৰণতে খাদ্যৰ তালিকা এগৰাকী খাদ্য বিশেষজ্ঞ{" "}
          <span style={{ fontWeight: "600" }}>(Dietition)</span> ৰ জৰিয়তে
          আলোচনা কৰা যুগুত যদিও সাধাৰণতে -<br />
          <span style={{ fontWeight: "800" }}>শুই উঠাৰ পিছত :</span>
          <br />
          <span style={{ fontWeight: "800", marginLeft: "20px" }}>
            পুৱা ৬.০০ বজাঃ
          </span>{" "}
          চাহ / কফি একাপ (চেনী নোহোৱাকৈ)
          <br />
          <br />
          <span style={{ fontWeight: "800" }}>পুৱাৰ জলপান :</span>
          <br />
          <span style={{ fontWeight: "800", marginLeft: "20px" }}>
            পুৱা ৮.৩০ বজাঃ
          </span>{" "}
          ৰুটি (তিনিখন তেল নোহোৱা), পাচলি ডালিয়া (আধা কাপ), ভাত (ডেৰ কাপ),
          পাচলি (ডেৰ কাপ)
          <br />
          <br />
          <span style={{ fontWeight: "800" }}>দুপৰীয়া (মধ্যম ভাগ) :</span>
          <br />
          <span style={{ fontWeight: "800", marginLeft: "20px" }}>
            ১০.৩০ বজাঃ{" "}
          </span>
          একাপ চাহ (আধা চামুচ চেনীৰে সৈতে), মেৰী গ’ল্ড নতুবা ডাইজেষ্টি মেৰী
          গ’ল্ড (এখন/ দুখন) <br />
          <br />
          <span style={{ fontWeight: "800" }}>দুপৰীয়া আহাৰ ১.০০ বজাঃ</span>
          <br />
          <span style={{ marginLeft: "30px" }}>
            ১/ দাইল, মাংস (মুর্গী ৫০ গ্রাম), মাছ (৫০ গ্রাম), কণী (বগা অংশ এটা)
          </span>
          <br />
          <span style={{ marginLeft: "30px" }}>
            ২/ সেউজীয়া পাচলিৰ লগতে চালাড (সিদ্ধ কৰা)
          </span>
          <br />
          <span style={{ marginLeft: "30px" }}>৩/ অলপ ফলমূল</span>
          <br />
          <span style={{ marginLeft: "30px" }}>8/ ভাত কমকৈ/ ৰুটী</span>
          <br />
          <br />
          <span style={{ fontWeight: "800" }}>আবেলিৰ চাহ ৪.০০ বজাঃ</span>
          <br />
          <span style={{ marginLeft: "30px" }}>
            ১/ চাহ/ কফি (চেনী নোহোৱাকৈ)
          </span>
          <br />
          <span style={{ marginLeft: "30px" }}>
            ২/ সিদ্ধ চানা/ ম আদি (আধা কাপ)
          </span>
          <br />
          <span style={{ marginLeft: "30px" }}> নাইবা </span>
          <br />
          <span style={{ marginLeft: "30px" }}>
            মেৰীগ’ল্ড বিস্কুট/ এৰো’ৰট বিস্কুট
          </span>
          <br />
          <br />
          <span style={{ marginLeft: "30px" }}>নিশাৰ আহাৰ ৮.০০/৯.০০ বজাঃ </span>
          <br />
          <span style={{ marginLeft: "30px" }}>পুৱাৰ আহাৰ/ জলপানৰ দৰে,</span>
          <br />
          <span style={{ marginLeft: "30px" }}>
            পাৰিলে শোৱাৰ সময়ত আধাকাপ গাখীৰ
          </span>
          <br />
          <br />
          <span style={{ fontWeight: "800" }}> বিশেষ উল্লেখযোগ্য :</span>
          <br />
          <span style={{ marginLeft: "30px", fontWeight: "600" }}>
            {" "}
            1 Cup: 150 ml. capacity
          </span>
          <br />
          <span style={{ marginLeft: "30px", fontWeight: "600" }}>
            {" "}
            1 glass 200 ml. capacity
          </span>
          <br />
          <span style={{ marginLeft: "30px", fontWeight: "600" }}>
            {" "}
            1 teaspoon : 5 gm
          </span>
          <br />
          <span style={{ marginLeft: "30px" }}>
            {" "}
            (এই খাদ্যৰ তালিকা চেন্নাই এপ’ল হস্পিতেলৰ খাদ্য বিশেষজ্ঞই দিয়া
            তালিকা। এই তালিকা ৰোগী চাই ঠাইভেদে হীন ভেঢ়িও হ’ব পাৰে।)
          </span>
          <br />
          <br />
          <span style={{ fontWeight: "800" }}>বৃক্ক দান মহৎ দান :</span>
          <br />
          <span style={{ marginLeft: "30px" }}>
            যি গৰাকীয়ে নিজৰ এটা বৃক্ক অন্যক দান কৰে তেওঁক ড’নাৰ (Doner) বুলি
            কোৱা হয়। সাধাৰণতে তেজৰ সম্পৰ্ক থকা জীৱিত অথবা তেজৰ সম্বন্ধ থকা মৃত
            (৪৮ ঘণ্টাৰ ভিতৰত দূৰ্ঘটনা জনিত) নতুবা তেজৰ সম্পৰ্ক নথকা জীৱিত মানুহৰ
            পৰা অথবা সদ্য মৃত মানুহৰ (Cadaver) পৰা বৃক্ক গ্ৰহণ কৰি ৰোগীৰ দেহত
            সংস্থাপন কৰা হয়। সাধাৰণতে তেজৰ সম্পৰ্ক থকা ব্যক্তিৰ পৰা বৃক্ক
            সংস্থাপন কৰিলে ৰোগীয়ে দীর্ঘজীৱন পোৱাৰ উপৰিও স্বাস্থ্য উন্নত হয় আৰু
            স্বাভাৱিক জীৱন-যাপন কৰিব পাৰে। অবিবাহিত সকলেও বিবাহবাৰু কৰিও সন্তান
            জন্ম দি সুন্দৰ গৃহস্থী কৰি জীৱনটো আনন্দময় কৰি তলিব পাৰে। বৃক্ক
            ৰোগীয়ে সাধাৰণতে মাক-দেউতাক, পুত্র-পুত্রী, ভায়েক-ভনীয়েক আদি যিকোনো
            এজনৰ পৰা চিকিৎসকৰ পৰামৰ্শমতে নতুবা অনেক পৰীক্ষা-নিৰীক্ষাৰ পিছত
            উপযুক্ত হ’লে গ্ৰহণ কৰিব পাৰে। বৃক্ক দাতাকো সুন্দৰকৈ বিভিন্নধৰণৰ
            পৰীক্ষা-নিৰীক্ষাৰ অন্তত বৃক্ক দান কৰিবলৈ অনুমতি দিয়া হয়। দাতা আৰু
            ৰোগীৰ মাজত বৃক্কৰ এই মিলক
            <span style={{ fontWeight: "600" }}> HLA </span>মিল বুলি কোৱা হয়,
            যাক ইংৰাজীত{" "}
            <span style={{ fontWeight: "600" }}>
              Tissue matching or HLA Matching
            </span>{" "}
            বুলি কোৱা হয়। এটি বৃক্ক দান কৰিলেও দাতাৰ একো অসুবিধা নহয় — বৰঞ্চ
            স্বাচ্ছ্যন্দৰে জীৱন নিৰ্বাহ কৰিব পাৰে। অৱশ্যে বৃক্ক দান দিবলৈ হ'লে
            কিছুমান আদালতৰ আইনী প্রক্রিয়াও আছে। আপোনসকলৰ ক্ষেত্ৰত কিছু শিথিল
            যদিও পৰিচয়-পত্ৰ আৰু ৰোগী আৰু দাতাৰ যুগ্ম আলোকচিত্ৰৰ প্ৰয়োজন। (যিটো
            মোৰ ক্ষেত্ৰত হৈছিল।)
          </span>
          <br />
          <br />
          <span style={{ marginLeft: "30px" }}>
            অন্যহাতে সদ্য মৃত ব্যক্তিৰ পৰাও বৃক্ক গ্ৰহণ কৰিব পাৰে - ইয়াক{" "}
            <span style={{ fontWeight: "600" }}>Cadaver doner</span> বুলি কোৱা
            হয়। <span style={{ fontWeight: "600" }}>Cadaver doner</span> ৰ
            মগজুৰ মৃত্যু হ'লেও বৃক্ক নতুবা অন্য অংগ ৪৮ ঘণ্টা ভিতৰত সংস্থাপন কৰিব
            পাৰে। সাধাৰণতে ডাঙৰ হস্পিতেল সমূহত{" "}
            <span style={{ fontWeight: "600" }}>Cadaver</span> ৰ বাবে আগতীয়াকৈ
            নাম পঞ্জীয়নৰ ব্যৱস্থা আছে আৰু প্ৰয়োজন সাপেক্ষে সময় সুবিধা হ'লে
            পৰিয়ালক সকাহ দিয়াৰ বাবে হস্পিতেল কর্তৃপক্ষই এনে উৎসৰ পৰা বৃক্ক
            যোগান ধৰি ৰোগী গৰাকীক আৰোগ্য কৰি তোলে। যদিহে{" "}
            <span style={{ fontWeight: "600" }}>Cadaver Doner</span>
            নাপায় - তেতিয়া সম্পৰ্কীয় মাহী, পেহী, খুড়া, ভতিজা, ভায়েক আদিৰ
            পৰা লোৱাৰ ব্যৱস্থা কৰে। বৃক্ক সংস্থাপিত কৰাৰ পিছত ৰোগীসকলে এক নতুন
            জনম লাভ কৰে – যাৰ বাবে তেওঁলোকৰ জীৱন গতিময় হৈ পৰে।
          </span>
          <br />
          <br />
          <span style={{ marginLeft: "30px" }}>
            আমি মনত ৰখা উচিত – ৫০ বছৰৰ উৰ্দ্ধৰ সকলো ব্যক্তিয়ে মাহত এবাৰকৈ হ'লেও{" "}
            <span style={{ fontWeight: "600" }}>
              Blood Pressure, Blood Sugar, Serum Creatinine, Serum urea
            </span>{" "}
            আদি তেজৰ পৰীক্ষাসমূহ কৰি থাকিবলৈ চিকিৎসকে পৰামর্শ দিয়ে। বৃক্ক ৰোগৰ
            পৰা হাত সাৰিবলৈ নিমখ, মিঠা, মাদকদ্রব্য, ধুমপান বৰ্জন কৰাৰ উপৰিও
            শৰীৰৰ ওজন নিয়ন্ত্ৰণ আৰু নিয়মিত ব্যায়াম, খোজকঢ়া আৰু প্ৰাণায়ম
            কৰিবলৈ চিকিৎসকে পৰামৰ্শ আগবঢ়াই সুন্দৰ জীৱন-যাপন কৰিবলৈ অৰিহনা
            যোগায়।{" "}
          </span>
          <br />
          <img
            style={{ width: "100%", height: "auto" }}
            src="https://www.news-medical.net/image.axd?picture=2020%2F2%2Fshutterstock_1363551152.jpg"
            alt=""
          />
          <br />
          <br />
          <br />
          <span style={{ fontWeight: "800" }}>একান্ত অনুভৱঃ</span>
          <br />
          <span style={{ marginLeft: "30px" }}>
            চিকিৎসা শাস্ত্ৰৰ মতে
            <span style={{ fontWeight: "800" }}> বৃক্ক দান মহৎ দান</span> - এই
            কথাষাৰকে গভীৰভাৱে হৃদয়ংগম কৰি ইং ৮ জুন ২০১১ চনত মোৰ নিকটতম আত্মীয়ক
            এটি বৃক্ক দান দি Doner নামেৰে এপ’ল হস্পিতেলত ডাক্তৰৰ মাজত পৰিচিত
            হলো। আমাৰ বৃক্কৰ সম্পৰ্কে একো ধাৰণাই নাছিল। ৰোগীক ধেমাজিৰ দুগৰাকী
            ডাক্তৰে মাত্র ২ ঘণ্টা সময় হাতত আছে – গুৱাহাটীলৈ এতিয়াই লৈ যাওঁক
            বুলি কওঁতে দিশহাৰা হৈ পৰিছিলো যদিও উন্নত মানসিকতাৰ বাবেই হয়তো বৃক্ক
            কি? ই কেনেকৈ সংৰোপন কৰা হয়? বৃক্ক দান দিলে উপকাৰ অপকাৰসমূহ{" "}
            <span style={{ fontWeight: "600" }}>Internet</span> ৰ জৰিয়তে জনাৰ
            পিছত মই যেন কার্যক্ষম হৈ পৰিছিলো। সূদীর্ঘ দিন ডায়েলেইছিচ কৰাৰ পিছত,
            বহু লোকৰ সৈতে আইনী কাকজ-পত্ৰ কৰাৰ পিছতো শেষ সময়ত দেহৰ{" "}
            <span style={{ fontWeight: "600" }}>Tissue</span> ৰোগীৰ লগত নিমিলাৰ
            বাবে তেওঁলোকক বিদায় দিবলগীয়া হৈছিল। তাৰপিছত{" "}
            <span style={{ fontWeight: "600" }}>Cadaver</span> ৰ বাবে নাম
            পঞ্জীয়ন আৰু শেষত নিজে দিয়াৰ সিদ্ধান্ত লোৱাত চিকিৎসকৰ আদৰণীয়
            পদক্ষেপ আদিয়ে সময়বোৰ যদিও দীঘলীয়া হৈছিল তথাপিও নিজে বৃক্ক দান কৰি
            গৌৰৱ অনুভৱ কৰাৰ লগতে চিকিৎসকৰ সৌহাদ্যপূর্ণ ব্যৱহাৰত পোহৰৰ মুখ
            দেখিছিলো। মোৰ চাৰ্জাৰী চিকিৎসক{" "}
            <span style={{ fontWeight: "600" }}>Dr. R. Deepak</span>
            কে মোৰ লগত তেওঁৰ চেম্বাৰত{" "}
            <span style={{ fontWeight: "600" }}>Councelling</span> কৰি মানসিক
            শক্তি যোগাইছিল। মই পেচাত এগৰাকী শিক্ষক তেওঁলোকে এই কথা জানিছিল আৰু
            ঘৰৰ ভিতৰুৱা খবৰ দুই-চাৰিটা জানিছিল সম্ভৱ। চেম্বাৰত মই প্ৰৱেশ কৰাৰ
            লগে লগে সৌজন্যপূৰ্ণভাৱে তেওঁ নিজৰ আসনৰ পৰা উঠি মোক প্রণাম জনাই
            আসনগ্ৰহণ কৰিবলৈ অনুৰোধ জনাই কৈছিল{" "}
            <span style={{ fontWeight: "800" }}>
              আপুনি এগৰাকী শিক্ষক, মই আপোনাক শিক্ষক
            </span>{" "}
            হিচাপে প্ৰণাম জনাইছো। তেতিয়া মই কৈছিলো
            <span style={{ fontWeight: "800" }}>
              {" "}
              আমি সৰু শিক্ষক ডাক্তৰ,
            </span>{" "}
            ডাক্তৰে কৈছিল{" "}
            <span style={{ fontWeight: "800" }}>
              মই মোৰ সন্তানক সদায়ে কওঁ
            </span>{" "}
            -আমি আজি{" "}
            <span style={{ fontWeight: "800" }}>
              শিক্ষকসকলৰ বাবেহে ডাঙৰ মানুহ হ'ব পাৰিছো - গতিকে আপোনাক পুনৰ প্ৰণাম
              জনাইছো। - আপুনি মহান
            </span>
            । দ্বিতীয়তে{" "}
            <span style={{ fontWeight: "800" }}>
              {" "}
              প্রণাম জনাইছো - আপুনি এগৰাকী মাতৃ আপুনি মাতৃ - হৈও বৃক্ক দান দিবলৈ
              আগবাঢ়িছে -{" "}
            </span>
            আপোনাৰ এই মহানতাত
            <span style={{ fontWeight: "800" }}>
              {" "}
              দ্বিতীয়বাৰ পুনৰ প্ৰণাম জনাইছো{" "}
            </span>{" "}
            - ডাক্তৰৰ কথাত মই আবেগিক হৈ পৰি কৈছিলো -{" "}
            <span style={{ fontWeight: "800" }}>
              ডাক্তৰ, মোৰ বৰ ভয় লাগিছে,
            </span>{" "}
            তেতিয়া ডাঃ দীপকে কৈছিল –{" "}
            <span style={{ fontWeight: "800" }}>
              আপুনি ভয় নকৰিব - মই আপোনাৰ বাবে ঘৰত ভগৱানৰ ওচৰত পূজা প্রার্থনা
              কৰি আহিম, আমি মাত্ৰ ইয়াত কেচীহে চলাম.....
            </span>{" "}
            সঁচাকৈয়ে ডাক্তৰসকলৰ উৎসাহপূৰ্ণ প্ৰেৰণা আৰু ভগৱানৰ আশীৰ্ষ নিৰ্মালী
            আৰু শুভাকাংখীসকলৰ শুভকামনাৰ বাবেহ আজি ৯ বছৰে বৃক্ক লওতা আৰু দিওতাই
            কৰ্মঠ হৈ জীয়াই আছো ৷ গতিকে বৃক্ক দান দি আতুৰক সহায় কৰাটো আমাৰ
            প্ৰত্যেকৰে কৰ্তব্য হোৱা উচিত।
          </span>
          <br />
          <br />
          <span style={{ fontWeight: "800" }}>তথ্যৰ উৎস :</span>
          <br />
          <span style={{ marginLeft: "30px" }}>
            চিকিৎসকসকলৰ সৈতে হোৱা কথোপকথন আৰু ইণ্টাৰনেট সেৱাৰ পৰা লোৱা তথ্য ।
          </span>
        </p>
      </div>
    ),
    authorName: "নিবেদিতা বড়া সন্দিকৈ",
    authorDesc: "...",
    authorAvatar: "/assets/images/nivedita.jpeg",
    createdAt: "July 6, 2021",
    cover: "/assets/images/6.jpeg",
  },

  {
    id: 6,
    title: "Agonies vested in progress",
    category: "Blog",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          Sumi gasped for breath as she gulped the water down her throat. The
          chilled air conditioned room and a cool dawn couldn't halt her
          generously forging sweat. As she recalled her dreaded nightmare, she
          dwelt in actuality, hoping to broaden the thin line of divide between
          her dream and reality. Swallowing down her hypertension pill, she had
          a clash of thoughts between overwhelming hopelessness illuminated by
          her nightmare and a tinge of hope from the will to bring a change
          which was interrupted by the ring of her phone. She quickly gathered
          herself and answered the call- “Hello, Dr. Sumi here.” a few seconds
          of silence which was broken by a ‘ yes I will rush’.
        </p>
        <br></br>
        <p>
          Driving amidst the dark with the tiny beam of the car’s fog lights
          guiding the way, Sumi again drowned in her pool of thoughts. The
          silhouettes rushing, commotion of ambulance’s sirens, cries of
          toddlers and screams of men and women over dead bodies from her dream
          again started to haunt her. As she drove across a police checkpoint, a
          policeman shoved his hands gesturing her to stop. Sumi immediately
          showed her Doctor’s ID to which the policeman greeted with folded
          hands and a slight bow, motioning her to drive off. Sumi felt a sudden
          surge of energy within herself. The small gesture from the policeman
          suddenly ignited the flame of hope which was about to die from the
          ghastly rushing wind of fear within her. It was as if that small
          gesture acted as a beam of guiding light amidst all the darkness
          around.
        </p>
        <br></br>
        <p>
          Sumi pulled off her car and rushed to her cabin. Putting on the PPE
          kit, she spent a minute in front of the idol of Krishna placed on her
          table and with a deep sigh raced towards the Intensive Care Unit
          (ICU). She reminded herself, the struggle was not over yet. She had to
          constantly fight with her demons within and the demon outside
          conspiring to bring the whole of humanity to a standstill, as Sumi
          perceived. Medically termed as Covid 19, this virus was not just
          physically ailing humans, but also took a great toll on mental health.
          She thus determined to rest only when the whole world is victorious in
          this battle against the brutal pandemic. She understood the strive was
          tough yet not impossible. However, only constant endeavour from
          everyone could bring an end to the dreaded pandemic. Her motivation
          from successfully treating 18 patients and sending them back home,
          well, made her realise how important her own mental strength was. At a
          time when everyone looks up to the doctors with hope and belief, Sumi
          affirmed herself that she couldn't lose her strength. It was not only
          her mere responsibility to treat, but also a stand of humanity to
          instil hope among the sufferers. She promised herself not to let her
          agonies work as a blockade towards bettering the condition. Rather,
          her agonies along with her skills must now be vested in progress;
          progress towards the cause of the whole of India’s fight against the
          deadly pandemic.
        </p>
      </div>
    ),
    createdAt: "July 05, 2021",
    cover: "/assets/images/blog1.jpg",
    authorName: "Kritanjali Kalita",
    authorAvatar: "/assets/images/kritanjali.jpeg",
    authorDesc:
      "This is Kritanjali Kalita a 4th semester student of Indraprastha college, University of Delhi. Born and brought up in a family of litterateur, writing and reading has always been my happy spot and passion. Being an enthusiast in human mind, mental health and psychology are my favorite topics of interest. Wishing to bring any possible change in my surroundings, here Iam up with some piece of fiction..",
  },

  {
    id: 5,
    title: "The Dumpster Girl",
    category: "Story",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          My name is Tori Morgenstern. And this is my incidents story.<br></br>
          <br></br>
          So, yes as I already mentioned my name and I turn 17 this year. I
          study in St. Peter’s High School. I am a straight studious A1 student
          . I wear spectacles. I have won many quiz competitions, Olympiads etc.
          I like to engage with studies and I am quite sincere towards it. I
          don’t like to party, involved in gatherings and get-togethers , wear
          expensive fashionable clothes and do dozens of makeup to stand out
          confidentially. On weekends I like to spend my time peacefully in my
          room studying or in the library. OK!! So you got a rough picture about
          me. You may be thinking I am a complete geek or nerd and you are
          right. But, is it a crime to be a geek or nerd?? I asked you because
          my fellow mates think that it is a horrible crime. They called me
          ‘nerdy Tom’. They hate me because I don’t match with them. My social
          status is in negative, as I hardy use any. In short yes I don’t have
          friends. I am the school’s punching bag for them. I have been bullied
          and mentally harassed for last eleven years here. Not a single day
          passes when I am not bullied and harassed.<br></br>
          <br></br>
          You must be thinking why I am even in this school or why I don’t tell
          my parents about this. You are right to think that. I even thought
          about it. But it will be worthless, because my talent of being suffer
          through this kind of situations is not limited to my school only but
          the whole city and the neighborhood does it to me. And as far
          complaining I already did that. My parents went to the principal , she
          suspended the students for two weeks. I was very happy, but after they
          came back I faced their wrath. They punched me, kicked me and said
          something that mentally disturbed me a lot. I learnt a lesson thinking
          of not getting into such worse situation and never complained again in
          my life.<br></br>
          <br></br>I nicknamed my bullies as ‘Bulldozers’ just like bulldozers
          demolish everything they also demolished every part of me.<br></br>
          <br></br>
          Once they threw a bucket of paint mixed with rotten eggs. I was now
          named as ‘Smelly Smurf'. Then in another day, they threw me away in a
          dumpster because I won every Track event (yes running away from my
          bulldozers every single day of my life I developed tremendous speed).
          After I escaped from dumpster, I smelled like rotten garbage for few
          days. No amount of scrubbing and perfumes could take away that
          horrible smell from me As expected from then they named me as ‘The
          Dumpster Girl’.<br></br>
          <br></br>I don’t even know till now that what’s wrong with me and why
          that they hate me so much. But deep down the inner me always answered
          me to this questions that I am a lot better then them. They can never
          be like me in every way I am therefore they hate me. But I didn’t
          listen to my inner voice. I am really flattered! I am calm as well as
          dump to be going through all this and letting them harm me.<br></br>
          <br></br>
          But everything was about to change. It was a Friday, I was returning
          home from school. When suddenly a group of 10 boys surrounded me. I
          was the only one helpless girl there and prayed to God to give me
          strength and took two steps for escape. Then one of them came in front
          of me and said “Hey! Dumpster, where are you going?”. I replied “I am
          going to a place called home, bulldozer”. He shouted at me and said “
          mind your language dumpster”. I again answered him “why don’t you all
          people mind your head and go back home? “. That must have pissed him
          as he slapped me hard in my face and came very near to me with some
          bad intentions. It was enough for me, I can’t tolerate this
          harassments more and thought of ending this so I fight back. I don’t
          know at that point what happened to me as I never fight back to such
          situations before but this time I lift my hands on someone for rescue.
          I punched him hard and blood came out from his nose. On queue all boys
          came to attack me. I fought back as much as I could valiantly. But
          seriously I was easily outnumbered and felt numb when one of them hold
          and hit me hard towards a wall. I felt dizzy. I could hear some sounds
          of sirens and see some blurry cops catching the boys.<br></br>
          <br></br>I recalled one of my fellowmate said to me “ I don’t know why
          Tori I hate you or the school hate and bullies you, I think they also
          don’t know. It’s just a sort of entertainment for them and you are an
          easy target for it”. That hit me today. They hated me, bullied me,
          harassed me just because Tori Morgenstern is a nerd and I was an easy
          target for their sort of entertainment.<br></br>
          <br></br>
          Now I am in the hospital bed and my breathing slowing down. I knew my
          time was coming. But I am happy because I went down fighting. My life
          was a hell on earth. I never witnessed any miracle but I am happy
          because despite the hard and painful life I went through I never
          considered of taking my life away and quitting. Today I feel like a
          huge burden has been lifted up from my body. And then I saw only
          darkness but inside I was swept away by a flood of bright and warm
          light. I can’t breath anymore but my last breath was a relief.
          <br></br>
          <br></br>I just wish that my story inspires others not to be like me
          to be in case of getting bullied or harassed instead speak out and
          fight. Also don’t give up on life if you are having hard time to
          survive.<br></br>
          <br></br>
          And please don’t bully or harassed others mentally or physically
          because you now know it hurts and it’s hurts too much. Bullying is
          perhaps the worst form of crime as well as sexual harassments. Not
          only that harass the person physically but mentally too. Breaking the
          person from inside. It is a set back for the person’s life, it’s
          family as well as to the society.<br></br>
          <br></br>
          Tori was a brilliant and talented student. She could have contributed
          a lot to her family and to the society. But her right to life was
          snatched away from her by the bulldozers. We talk greatly about Human
          Rights but do we really follow it ? When someone bullies you don’t be
          silent, fbe brave and fight back. Don’t be a silent observer too. When
          you watch others harassing and bullying others stop them. Because
          Dante said “ The darkest places in Hell are reserved for those who
          maintain their neutrality at the time of Moral Crises”.
        </p>
      </div>
    ),
    authorName: "Krishnakshi Majumdar",
    authorDesc:
      "My name is Krishnakshi Majumdar. I am an Aquarius with ambivert nature. There are many things I like to do, I like to experience and enjoy every little things in life. I proactively believe in Learn from the past, Live in the present, Look to the future. For me mental and physical health are directly proportional to each other. So, trying to contribute something towards humanity and health of man kind in possible ways.",
    authorAvatar: "/assets/images/krishna.jpeg",
    createdAt: "June 03, 2021",
    cover: "/assets/images/blog3.jpg",
  },

  {
    id: 4,
    title: "Strings of Clustered Emotions",
    category: "Blog",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          Shining through the windows of dew drops on the leaves and birds
          chirping in and around marks the fragrance of Ravi. Ravi: The Banyan
          tree isn't just a religious tree for devotees but also, a part of our
          extended family and our hearts. Ravi has been sown by Anni; my
          grandmother, two decades ago. Anni's soft hands would nurture Ravi
          everyday along with an exchange of serene conversation of love.
          <br></br>
          <br></br>
          As seasons flied by; Ravi grew-up into a toddler, and so did Gauri;
          Gauri my sister. Every morning I woke-up to see the trio: Ravi, Anni
          and Gauri playing and giggling together. Anni would make a swing out
          of the ropes and tie it untill the tallest branches of Ravi. There
          were times even, when Anni made Gauri crawl till the highest top of
          Ravi. Ravi, in fact is a home of hundreds of birds and all I saw how
          every evening Anni and Gauri would rejoice together to the mesmerizing
          sight of the beautiful birds resting on the branches of Ravi.<br></br>
          <br></br>
          Ravi acted as an umbrella of shade, safeguarding people from the
          scorching rays of the Sun. Children all around from the neighborhood
          would play under Ravi only to discover that, there were stairs beneath
          the ground. Fascinatingly, no one knew the mystery of those stairs and
          it felt veracious to let the unsolved mystery, undiscovered.<br></br>
          <br></br>
          Now, whenever I'm cold and numb, I turn back to relish the tales of
          Ravi, Anni and Gauri; sometimes I feel that twenty long years has gone
          within a blink of an eye. Gauri has grown-up and isn't my little Gauri
          anymore. More saddening is that, even Anni has gone faraway from us
          but all I could feel her is in between Ravi's arms.<br></br>
          <br></br>
          Now, whenever nostalgia strikes me I find myself beneath Ravi. A
          single touch of Ravi makes me alive and relishes the loving memories
          of Anni and Gauri, leaving behind a bittersweet curve in my heart,
          left to be imprinted forever.
        </p>
      </div>
    ),
    authorName: "Sanjushree Bharadwaj",
    authorDesc:
      "Writing has been always a part of my tranquility. Its not just about scribbling words but placing my emotions altogether. It always takes my mind for a thrilling rollercoaster ride whilst I imagine and my pen writes. I believe, there is a writer in each of us; just that only a ray of sunshine is faraway to reach us.",
    authorAvatar: "/assets/images/sanju.jpg",
    createdAt: "July 15, 2021",
    cover: "/assets/images/blog4.jpg",
  },

  {
    id: 3,
    title: "The mechanics of desire",
    category: "Blog",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          Do you dream? Everyone does…isn’t it?<br></br>
          <br></br>
          But I am not talking about those dreams that you dreamt during your
          sleep. Those are just memory revisions of your brain. I am talking
          about your desires! Yes, in a real sense dream is nothing but a
          desire. You desire to be an actor, to be a doctor, to be a topper in
          the class, to be a scientist, to be a rich countryman, to be a famous
          entrepreneur…endlessly it goes on! It is the desire that makes you do
          all the activities of life. But how? Okay…let me ask you a question!
          What makes you read this article right now? What makes you get up from
          the bed and brush your teeth? What makes you eat your breakfast? What
          makes you go to your workplace? (your job). It’s all your desire that
          makes you alive. Yes! desire is life. Without desire, you cannot do a
          thing. Alright, we understand that. Now let me ask you one more
          question! What is the root cause of all the suffering in human beings?
          The answer is very simple. It is the Unfulfilled Desires that lies
          within you. The damn life is not happening the way you want it to be!
          <br></br>
          <br></br>
          Desire is life but it is the source of all your miseries! Let’s say,
          you seen someone who desired to be a doctor but was unable to do and
          committed suicide as a result of his frustration, or someone who had
          great ambition and worked hard to become an IAS officer or gain
          admission to a prestigious university but was unable to accomplish
          their goal and end their life because they couldn’t handle distress.
          These incidents occur around us, sometimes daily. Isn't it true that
          the root cause of these unfortunate incidents is unfulfilled desire?
          <br></br>
          <br></br>
          Have you ever given a thought to about why we desire? If you see life
          with necessary awareness you will notice that the very process of life
          is seeking in nature. It is seeking for something, a certain urge to
          include, evolve and grow to its ultimate nature. When you analyze your
          most basic desires such as prosperity, fame, traveling, sexuality,
          shopping, knowledge, or love, you will notice that the underlying urge
          is to include something or someone into yourself. Fundamentally, you
          want to expand your way of being, and it will not going to settle for
          any amount of expansion. There is a part of you that longs for a
          boundless experience. It occurs in all aspects of life. For example,
          take a look at the lives of plants. When you sow a seed, it grows into
          a sapling,then a plant, and finally a tree. It continues to rise.
          Would it have been grown upwards if it didn't have the boundlessness?
          <br></br>
          <br></br>
          But why this boundlessness or limitlessness? Because the life you are
          is infinite or limitless. But the problem is, it is identified with
          this limited physical body. This infinite life cannot be confined in
          its limited body therefore, it thirst for more and that is what we
          call a desire. Take a look at the world's billionaires! Isn't it true
          that they aspire to be a bit richer every day than they were
          yesterday? Desire finds different physical expressions. Remember that
          desire is not for more, it is for “all”. When this craving for oneness
          or the desire to embrace everything as a part of yourself manifests as
          a basic physical expression, we can call it sex. When it comes to sex,
          you're frantically seeking to unite with someone. It doesn't happen
          how hard you try. Yes, you may believe for a time that you have become
          one with another person, but you realize that everything is separate.
          When you try this mentally, it is usually described as avarice,
          conquest, or ambition. You desire to conquer the world by
          incorporating everything as a part of yourself. If this finds an
          emotional expression, we call it love. We call it yoga, when it finds
          conscious expression. YOGA means union. The whole game is about
          whether, your desire finds a conscious expression or an unconscious
          expression. When your desire finds a conscious expression, it makes
          your life beautiful and finding an unconscious expression, it makes
          your life ugly.<br></br>
          <br></br>
          You want more and more, and because of your wrong identification with
          your body, you have forgotten that you are infinite in reality. That
          doesn't mean you don't accomplish anything in life and just say to
          yourself, "I am infinite! I am infinite!” No, don’t do this. Do
          everything that you want to do but you just need to desire
          consciously. If you have a conscious desire, such as"I want to be a
          good doctor so that I may provide a social contribution to humanity. I
          will put forth the necessary effort to achieve my goal. And if I don't
          achieve my goal, that's just fine; I'll do something else to help
          humanity” . When you become a little conscious, suddenly you can feel
          the profundity of your life.<br></br>
          <br></br>
          <span style={{ fontStyle: "italic" }}>
            Sadhguru, once said something like this: “When Patanjali wrote the
            yogasutras, he began them in a unique manner, The first chapter of
            the yogasutras begins with ‘...and now, yoga’—a half-sentence. A
            great life document begins with a half-sentence. It makes no sense
            intellectually, but what it means experientially is: "If you still
            feel that buying a new house, finding a new wife, or getting your
            daughter married will settle your life, it is not yet time for
            yoga."<br></br>
            <br></br>
            But, if you've seen money, power, wealth, and pleasure, if you've
            tasted everything in your life, and you've realized that nothing is
            going to work in the genuine sense and eventually please you, it's
            time for yoga.”
          </span>
          <br></br>
          <br></br>
          <span style={{ fontWeight: "500" }}>
            Yoga is the ultimate tool to raise the human consciousness. Do yoga,
            be conscious, and experience the profundity of life.
          </span>
        </p>
      </div>
    ),
    authorName: "Bhargab Deka",
    authorDesc:
      "Hello, My name is Bhargab Deka. I live in Guwahati, Assam. I am a pharmacist by profession and I love to help people with their Pharmaceutical and Medical needs.",
    authorAvatar: "/assets/images/bhargab.jpg",
    createdAt: "July 20, 2021",
    cover: "/assets/images/blog5.jpg",
  },

  {
    id: 1,
    title: "THE PERFECT DIET",
    category: "Poem",
    description: (
      <div class="container" style={{ textAlign: "justify" }}>
        <p>
          Early to bed and early to rise,<br></br>
          May make you wise and fit;<br></br>
          But if you yearn to be, fine and healthy,<br></br>
          Let your diet be perfect and lit.<br></br>
          <br></br>
          Why not to embark the morning,<br></br>
          With a glass of water, warm;<br></br>
          Boosting the functioning of your body,<br></br>
          Thus, engendering to you, no harm.<br></br>
          <br></br>
          Power-packed fruits for the breakfast,<br></br>
          With slices of bread, two or three;<br></br>A glass of juice and a
          hard boiled egg,<br></br>
          How appetizing it sounds, don't you see?!<br></br>
          <br></br>A quarter plate, full of whole grains,<br></br>A quarter,
          inherent of fresh green;<br></br>
          Plenty of daal into a bowl,<br></br>
          Can complete your lunch routine!<br></br>
          <br></br>
          The more the salads you have,<br></br>
          The more the nutrients you acquire;<br></br>A poultry meat or a fish
          curry, to boot,<br></br>
          Escalates your alimentative, you require.<br></br>
          <br></br>A healthy snack is a must,<br></br>
          Can be of a veggie-sandwich or dry fruits;<br></br>
          This helps you from overeating at meal time,<br></br>
          And fulfils your happiness and fancy roots.<br></br>
          <br></br>
          How about a cup of tea or coffee,<br></br>
          With a little sugar and a cookie;<br></br>
          To boost up your performance for the day;<br></br>
          Making you enthusiastic and tension-free.<br></br>
          <br></br>
          Your dinner should be the lightest meal,<br></br>
          With veggies and vitamin-rich food;<br></br>A glass of milk could
          probably,for the supper,<br></br>
          Complete your daily diet, as it should!<br></br>
          <br></br>
          'Skipping meals' is not a good option,<br></br>
          Causing you later, to crave for junks;<br></br>
          Rather, keep yourself sustaining sometimes,<br></br>
          With a mouth-full of yummy chunks.<br></br>
          <br></br>
          Exercise brings to you positivity and energy,<br></br>A sound sleep
          brings you overall wit;<br></br>
          But if you yearn to be, fine and healthy,<br></br>
          Let your diet be perfect and lit!<br></br>
        </p>
      </div>
    ),
    authorName: "Upashana Talukdar",
    authorAvatar: "/assets/images/upasana.jpeg",
    authorDesc:
      "I love penning down my thoughts and feelings into microtales and poetries cause it provides me with a way of sharing my contemplation with the world.",
    createdAt: "July 01, 2021",
    cover: "/assets/images/blog2.jpg",
  },
];
