import React from "react";
import {Link} from "react-router-dom";
import Chip from "../../../common/Chip";
import "./styles.css";

const BlogItem = ({
  blog: {
    description,
    title,
    createdAt,
    authorName,
    authorName2,
    authorAvatar,
    cover,
    category,
    id,
  },
}) => {
  return (
    <div class="blogItem-wrap row">
      <div class="col-lg-12">
        <div
          className="blog grid-blog"
          style={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            height: "18vh",
          }}
        >
          <div className="blog-title-wrap" style={{borderRadius: "8px"}}>
            <h3 style={{padding: "15px"}}>
              <Link
                style={{
                  color: "#0072bc",
                  textTransform: "uppercase",
                }}
                to={`/blog/${id}`}
              >
                {title}
              </Link>
            </h3>
          </div>
          <div class="blog-image">
            <img
              class="img-fluid"
              src={cover}
              style={{
                width: "100%",
                height: "30vh",
                objectFit: "cover",
              }}
              alt="Not available"
            />

            <Chip
              label={category}
              style={{height: "80px", position: "absolute"}}
            />
          </div>

          <div
            class="blog-content"
            style={{marginTop: "-20px", backgroundColor: "#fff"}}
          >
            <div
              class="blogItem-desc"
              style={{
                textAlign: "justify",
                height: "90px",
                overflow: "hidden",
              }}
            >
              <p style={{textAlign: "justify", marginBottom: "5px"}}>
                {description}
              </p>
            </div>
            <div className="text-right">
              <Link
                style={{marginTop: "15px", color: "#0072bc"}}
                to={`/blog/${id}`}
                class="read-more"
              >
                Continue reading{" "}
                <i
                  style={{color: "#0072bc"}}
                  class="fa fa-angle-double-right"
                  aria-hidden="true"
                ></i>
              </Link>
            </div>
            <div class="blog-info clearfix">
              <div class="post-left">
                <div className="blogItem-author">
                  <p style={{fontStyle: "italic"}}>
                    - {authorName} {authorName2 == null ? <></> : <>&</>}{" "}
                    {authorName2}
                    {/* {authorAvatar} */}
                  </p>
                </div>
              </div>
              <div class="post-right">
                <ul>
                  <li>
                    <i style={{color: "grey"}} class="fa fa-calendar"></i>
                    <span>{createdAt}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
