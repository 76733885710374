import React, { useState, useEffect, Fragment } from "react";
import { db } from "../firebase";
import loader from "./assets/image/loader.gif";
import GoToTop from "../GoToTop";
import "./assets/css/Team.css";
import ModalGallery from "./ModalGallery";
function Team() {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);

  const ref = db.collection("admins").orderBy("no");

  //REALTIME GET FUNCTION
  function getAdmins() {
    setLoading(true);
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setAdmins(items);
      setLoading(false);
    });
  }

  useEffect(() => {
    getAdmins();
    // eslint-disable-next-line
  }, []);

  //AView Image
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({});
  const handleView = (data) => {
    setOpen(true);
    setModal(data);
  };

  return (
    <Fragment>
      <>
        <GoToTop />

        <section id="team" className="team">
          <div
            class="container-fluid"
            style={{ maxWidth: "200vh", marginTop: "17vh" }}
          >
            <div class="section-title">
              <h2>Founders</h2>
            </div>

            <div class="container-fluid" style={{ cursor: "pointer" }}>
              <div class="row">
                <div className="col-lg-4">
                  <div className="member" style={{ marginTop: "10px" }}>
                    <div>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/Board%2Fsudhanshu.jpg?alt=media&token=270ca6da-4cd0-4e31-8cd9-ad505126b508"
                        className="rounded"
                        style={{
                          width: "auto",
                          height: "32vh",
                          objectFit: "cover",
                          marginBottom: "10px",
                        }}
                        alt=""
                      />
                    </div>

                    <div className="my-2">
                      <h4>Sudhanshu Borthakur</h4>
                      <span>CO-FOUNDER (COO)</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="member" style={{ marginTop: "10px" }}>
                    <div>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/members-76725.appspot.com/o/users%2Ft9LUYzpzGAQqXz4WHl5QUPXjztv1%2Fprofile?alt=media&token=d2db3f3d-cf4e-4f59-b507-cb0907e6bd91"
                        className="rounded"
                        style={{
                          width: "auto",
                          height: "32vh",
                          objectFit: "cover",
                          marginBottom: "10px",
                        }}
                        alt=""
                      />
                    </div>

                    <div className="my-2">
                      <h4>Ankur Jyoti Dutta</h4>
                      <span>CO-FOUNDER (CTO)</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="member" style={{ marginTop: "10px" }}>
                    <div>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/Board%2FArnav.jpeg?alt=media&token=36ba9f71-5a1c-4534-b94a-64e2d0793218"
                        className="rounded"
                        style={{
                          width: "auto",
                          height: "32vh",
                          objectFit: "cover",
                          marginBottom: "10px",
                        }}
                        alt=""
                      />
                    </div>

                    <div className="my-2">
                      <h4>Arnavraj Baruah</h4>
                      <span>CO-FOUNDER (CFO)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="container-fluid"
          style={{ marginTop: "17vh", maxWidth: "200vh", minHeight: "50vh" }}
        >
          <section id="team" className="team">
            <div className="section-title">
              <h2>Board members</h2>
            </div>
            <div className="container-fluid">
              <div className="row">
                {loading ? (
                  <div class="container justify-content-center">
                    <h4 style={{ textAlign: "center" }}>
                      <img
                        src={loader}
                        alt=""
                        style={{ width: "40vh", height: "auto" }}
                      />
                    </h4>
                  </div>
                ) : null}
                {admins.map((data) => (
                  <div
                    className="col-lg-3"
                    key={data.id}
                    style={{ display: data.name === "none" ? "none" : "block" }}
                  >
                    <div className="member" style={{ marginTop: "10px" }}>
                      <div>
                        <img
                          src={data.link}
                          onClick={() => handleView(data)}
                          className="rounded"
                          style={{
                            width: "auto",
                            height: "32vh",
                            objectFit: "cover",
                            cursor: "pointer",
                            marginBottom: "10px",
                          }}
                          alt=""
                        />
                        {open && (
                          <ModalGallery
                            open={open}
                            setOpen={setOpen}
                            // handleDelete={handleDelete}
                            {...modal}
                          />
                        )}
                      </div>

                      <div className="member-info">
                        <h4>{data.name}</h4>
                        <span>{data.dept}</span>

                        <div class="social">
                          <a href={data.fb} target="_blank_">
                            <i class="fa fa-facebook"></i>
                          </a>
                          <a href={data.insta} target="_blank_">
                            <i class="fa fa-instagram"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </>
    </Fragment>
  );
}

export default Team;
