import React from "react";
import BlogItem from "./BlogItem";
import "./styles.css";

const BlogList = ({ blogs }) => {
  return (
    <div
      className="blogList-wrap container-fluid"
      style={{
        backgroundColor: "#f2f2f2",
        paddingBottom: "60px",
        marginBottom: "-48px",
      }}
    >
      {blogs.map((blog) => (
        <BlogItem blog={blog} />
      ))}
    </div>
  );
};

export default BlogList;
