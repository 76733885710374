import React from "react";
import GoToTop from "../GoToTop";
import about from "./assets/image/about.JPG";

function About() {
  return (
    <>
      <GoToTop />

      <section
        id="aboutus"
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: "10vh" }}
      >
        <div className="container" style={{ marginBottom: "30px" }}>
          <div
            className="row justify-content-center"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div className="col-xl-12 col-lg-8">
              <h1>About Us</h1>
              <h2>
                A community-based organization registered under{" "}
                <strong style={{ color: "#11b8f5" }}>UNITED NATIONS</strong>
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section
        id="about-us"
        className="about-us"
        style={{ marginTop: "20px", marginBottom: "50px" }}
      >
        <div className="container-fluid" style={{ maxWidth: "200vh" }}>
          <h2 style={{ fontWeight: "bold" }}>Who we are</h2>
          <div className="row content">
            <div className="col-lg-8">
              <p style={{ textAlign: "justify", fontSize: "14px" }}>
                Wake With Wellness is a community-based organisation,
                established to exercise good actions for the rehabilitation of
                communities regarding health and social awareness without
                ascertaining the foundation of legal norms. The focus is to
                spread and amplify health concerns and conveyance of information
                crucial to a healthy living.
              </p>

              <br></br>
              <p style={{ fontSize: "14px" }}>
                There are seven working departments -
              </p>

              <ul style={{ fontSize: "14px" }}>
                <div className="row">
                  <div className="col-lg">
                    <li
                      style={{
                        backgroundColor: "#cce3f2",
                        padding: "8px",
                        fontWeight: "bold",
                        color: "#0072bc",
                        maxWidth: "50vh",
                        marginTop: "4px",
                      }}
                    >
                      <i class="fa fa-check" aria-hidden="true"></i> Curation
                      Department
                    </li>
                  </div>
                  <div className="col-lg">
                    <li
                      style={{
                        backgroundColor: "#cce3f2",
                        padding: "8px",
                        fontWeight: "bold",
                        color: "#0072bc",
                        maxWidth: "50vh",
                        marginTop: "4px",
                      }}
                    >
                      <i class="fa fa-check" aria-hidden="true"></i> Editorial
                      Department
                    </li>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <li
                      style={{
                        backgroundColor: "#cce3f2",
                        padding: "8px",
                        fontWeight: "bold",
                        color: "#0072bc",
                        maxWidth: "50vh",
                        marginTop: "4px",
                      }}
                    >
                      <i class="fa fa-check" aria-hidden="true"></i> Public
                      Relations Department
                    </li>{" "}
                  </div>
                  <div className="col-lg">
                    <li
                      style={{
                        backgroundColor: "#cce3f2",
                        padding: "8px",
                        fontWeight: "bold",
                        color: "#0072bc",
                        maxWidth: "50vh",
                        marginTop: "4px",
                      }}
                    >
                      <i class="fa fa-check" aria-hidden="true"></i> Social
                      Media Marketing Department
                    </li>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <li
                      style={{
                        backgroundColor: "#cce3f2",
                        padding: "8px",
                        fontWeight: "bold",
                        color: "#0072bc",
                        maxWidth: "50vh",
                        marginTop: "4px",
                      }}
                    >
                      <i class="fa fa-check" aria-hidden="true"></i> Events
                      Department
                    </li>
                  </div>
                  <div className="col-lg">
                    <li
                      style={{
                        backgroundColor: "#cce3f2",
                        padding: "8px",
                        fontWeight: "bold",
                        color: "#0072bc",
                        maxWidth: "50vh",
                        marginTop: "4px",
                      }}
                    >
                      <i class="fa fa-check" aria-hidden="true"></i> Design &
                      Media Department
                    </li>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg">
                    <li
                      style={{
                        backgroundColor: "#ffdfb5",
                        padding: "8px",
                        fontWeight: "bold",
                        color: "#db7c00",
                        maxWidth: "50vh",
                        marginTop: "4px",
                      }}
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>{" "}
                      Coordinating Team
                    </li>
                  </div>
                </div>
                <br></br>
                <p style={{ textAlign: "justify", fontSize: "14px" }}>
                  Working in tandem to our cause of spreading awareness and
                  delivery of palliative and healthcare services beyond bounded
                  limits, we the members of Wake With Wellness intent to serve
                  and care people and generations with a commitment of guiding
                  all fellow beings towards a healthy and robust existence along
                  with an attempt to maximise provisions of health care which
                  effortlessly reaches out to the needful.
                </p>
              </ul>
            </div>
            <div className="col-lg-4 pt-4 pt-lg-0">
              <img src={about} className="img-fluid" alt="" />
            </div>
          </div>
        </div>

        <div
          className="container-fluid"
          style={{ maxWidth: "200vh", textAlign: "justify", fontSize: "14px" }}
        >
          <h5
            style={{
              fontWeight: "bold",
              color: "#0072bc",
              textAlign: "left",
              fontSize: "20px",
            }}
          >
            With this vision of steering people for a hale and hearty life form,{" "}
            <span>‘we are People Caring For People’</span>
          </h5>
        </div>
      </section>

      <br></br>
      <br></br>
      <br></br>

      <section>
        <div className="container-fluid" style={{ maxWidth: "200vh" }}>
          <h2 style={{ fontWeight: "bold" }}>
            The journey of a thousand miles begins with one step
          </h2>
          <div className="row content my-4">
            <div className="col-lg-8 pt-4 pt-lg-0 order-2 order-lg-1 content">
              <p style={{ textAlign: "justify" }}>
                It all started when India was jolted by the second wave of the
                infamous coronavirus and lockdowns were imposed countrywide. The
                major motive was to initiate something that would keep us
                occupied in the lockdown and yet be able to make some
                quantifiable impact on the society at large. Also, we wanted to
                stand out in our approach; initiatives towards societal
                cleanliness, the welfare of orphans, environmental awareness,
                and women's welfare were apparently ubiquitous.
                <br></br> <br></br>
                Surprisingly, we discovered that there weren’t any organizations
                dedicated to the promotion of personal wellbeing and healthcare;
                this was the moment we realized that it could be us taking the
                first step. In the embryonic stages of the CBO, we used social
                media to find like-minded individuals who went on to serve as
                board members and added further value to our CBO by helping with
                the recruitments, operations, administration, ideation, content
                creation, marketing, event management, and the like, gradually
                evolving into a ‘Team’.
                <br></br> <br></br>
                With time the analogy we used changed, and the transition from a
                team to ménage was evident. Today, in the ninth month, the CBO
                has eight major events (offline as well as online) to its credit
                and has become a family of more than fifty members. Moreover,
                WakeWithWellness has also been recognized by the United Nations
                and is working under the aegis of the same. The CBO has set up
                its head office in Beltola, Guwahati; it was inaugurated during
                our first offline drive in the city. Our vision lies in the
                continual growth of the CBO into a larger entity with
                sub-branches penetrating all the underserved regions in the
                field of healthcare in India.
              </p>
            </div>
            <div className="col-lg-4 order-1 order-lg-2">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FL4.jpg?alt=media&token=cc99bb8e-2df5-478b-ae5d-a5fd69c3aa66"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>

          <div className="row content my-2">
            <div className="col-lg my-2">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FL2.jpg?alt=media&token=01624696-123b-4097-9626-19dd37ad977f"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-8">
              <p style={{ textAlign: "justify", marginTop: "5px" }}>
                This would help magnify the efforts we’re putting in today
                towards building up a society thriving with healthy individuals,
                a society that is cognizant of the importance of healthcare and
                personal wellbeing. The prime inducement of the team is to
                improve the perspective of the community about the delivery of
                palliative and health care services. More sustained efforts are
                required to make people believe that social welfare and
                well-being can be provided by community volunteers, not
                necessarily only by professionals
                <br></br> <br></br>
                Thus the most viable way to provide service to a huge number of
                people in need is through community participation. With this
                vision of steering people for a hale and hearty life form, 'we
                are people caring for people. If this narrative has set an
                influence somewhere then this's the right time to prearrange
                your consciousness and start working for a change. Wake With
                Wellness is always open to appointing all the change-makers. So
                be a part of this welfare operation by undertaking the pledge to
                serve people and generations.
                <br></br> <br></br>
              </p>
              {/* <p
                style={{
                  fontSize: "18px",
                  backgroundColor: "#cce3f2",
                  padding: "8px",
                  color: "#0072bc",
                  maxWidth: "50vh",
                  marginTop: "4px",
                }}
              >
                Join us at: <strong>www.wakewithwellness.in</strong>
              </p> */}
            </div>
          </div>
        </div>

        <div
          className="container-fluid"
          style={{ marginTop: "20px", maxWidth: "200vh" }}
        >
          <div className="row">
            <div className="col-lg my-2">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2Fneconvene.jpg?alt=media&token=a6b0a697-11ab-4e22-88bc-a62c7144b45b"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg my-2">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/prabhati%2F9.jpeg?alt=media&token=14372edc-690e-4a4c-8fcc-f8d5cfd7eb78"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg my-2">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2Fcleanliness1a.jpeg?alt=media&token=f9ab2aab-67e0-4871-83cb-8d34e182d241"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg my-2">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FCleanlinessD2d.jpeg?alt=media&token=6c07a572-430d-47b1-8feb-24ad7a92db97"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
