import React from "react";
import "./assets/css/Home.css";
import GoToTop from "../GoToTop";

function Events() {
  return (
    <>
      <GoToTop />
      <section
        id="eventsss"
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: "10vh" }}
      >
        <div className="container" style={{ marginBottom: "30px" }}>
          <div
            className="row justify-content-center"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div className="col-xl-12 col-lg-8">
              <h1>Our Events</h1>
            </div>
          </div>
        </div>
      </section>
      <div
        className="container-fluid"
        style={{ marginTop: "10vh", maxWidth: "200vh" }}
      >
        <div className="row">
          <div
            className="col-lg-4 order-1 order-lg-1"
            style={{ float: "right" }}
          >
            <img
              style={{ borderRadius: "10px" }}
              src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FGyanalaya-Childrens_day%2F1.png?alt=media&token=820d2db6-980e-4bb9-9f9d-72855592a7b6"
              className="img-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-2 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h5
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                fontWeight: "bold",
                backgroundColor: "#cce3f2",
                padding: "8px",
                color: "#0072bc",
              }}
            >
              Children's Day - Project Jivanam
            </h5>
            <p style={{ marginLeft: "10px" }}>
              <i
                style={{ color: "green" }}
                class="fa fa-calendar"
                aria-hidden="true"
              ></i>{" "}
              <strong> 14th November 2023</strong>
              <br></br>
              <i
                style={{ color: "red" }}
                class="fa fa-map-marker"
                aria-hidden="true"
              ></i>{" "}
              <strong>Gyanalaya, Khanapara, Guwahati</strong>
            </p>

            <p style={{ textAlign: "justify" }}>
              On the occasion of Children's Day, a heartening collaboration
              unfolded between WWW and the R.A.Y Foundation, bringing warmth and
              support to Gyanalaya: A Modern Day Gurukul. This unique
              educational haven caters to children from all walks of life,
              fostering holistic growth. In a thoughtful gesture, Wake With
              Wellness and R.A.Y Foundation jointly distributed crucial
              educational items, including notebooks and stationery, providing
              the young learners with the tools for comprehensive education. The
              initiative also included beloved food items, adding an extra layer
              of joy to the occasion. This collaborative effort exemplified a
              commitment to ensuring that every child, irrespective of their
              background, has access to the resources and opportunities needed
              to thrive. The event resonated with the essence of Children's Day,
              celebrating the spirit of childhood and emphasizing the importance
              of inclusive education in shaping a brighter future for all.
            </p>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <div className="row">
          <div className="col-lg-2" style={{ width: "2vh" }}></div>
          <div className="col-lg-4 order-1 order-lg-2">
            <img
              style={{ borderRadius: "10px" }}
              src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FFloodDrive23%2FFlood7.jpg?alt=media&token=5e2e5813-9f85-43bf-b94e-90396dfacd5f"
              className="img-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h5
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                fontWeight: "bold",
                backgroundColor: "#cce3f2",
                padding: "8px",
                color: "#0072bc",
              }}
            >
              Flood Distribution Drive - Project Jivanam
            </h5>
            <p style={{ marginLeft: "10px" }}>
              <i
                style={{ color: "green" }}
                class="fa fa-calendar"
                aria-hidden="true"
              ></i>{" "}
              <strong>21st September 2023</strong>
              <br></br>
              <i
                style={{ color: "red" }}
                class="fa fa-map-marker"
                aria-hidden="true"
              ></i>{" "}
              <strong>Kalgachia, Barpeta, Assam</strong>
            </p>

            <p style={{ textAlign: "justify" }}>
              With the rising of the water level of the mighty river
              Brahmaputra, the state of Assam sees deadly floods each year,
              taking the lives of innocent children and people. The floods
              knocked the doors of the state again and bought a dreadful time
              for the people taking residence in the area affected. In these
              times of calamities, victims of mother nature needed basic
              necessities like non perishable food, clothes, footwear etc.
              <br></br>
              We, the team of Wake With Wellness, a community based
              organisation, which does it good work in rehabilitation of
              communities regarding health and social awareness in collaboration
              with Jonak foundation, an Non-Governmental Organisation which
              undertakes relief, humanitarian aid and community development,
              started the programme “Jivanam”, to provide some relief to the
              victims. We, the team of Wake With Wellness and Jonak Foundation
              and grateful for all the selfless contributions the people did
              towards the society.
            </p>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <div className="row">
          <div
            className="col-lg-4 order-1 order-lg-1"
            style={{ float: "right" }}
          >
            <img
              style={{ borderRadius: "10px" }}
              src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FYogaWorkshop%2Fyoga23a.jpg?alt=media&token=6c13651f-b4a0-4d14-bc88-6db445510b83"
              className="img-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-2 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h5
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                fontWeight: "bold",
                backgroundColor: "#cce3f2",
                padding: "8px",
                color: "#0072bc",
              }}
            >
              Yoga Workshop: Vasudhaiva Kutumbakam
            </h5>
            <p style={{ marginLeft: "10px" }}>
              <i
                style={{ color: "green" }}
                class="fa fa-calendar"
                aria-hidden="true"
              ></i>{" "}
              <strong> 24th June 2023</strong>
              <br></br>
              <i
                style={{ color: "red" }}
                class="fa fa-map-marker"
                aria-hidden="true"
              ></i>{" "}
              <strong>Parijat Academy, Pamohi, Guwahati</strong>
            </p>

            <p style={{ textAlign: "justify" }}>
              <i>
                “Yoga is the JOURNEY of the SELF to the SELF through the SELF”
                -The Bhagvad Gita
              </i>
              <br></br>
              <br></br>
              With this self-recognition, the team of Wake With Wellness
              organised a yoga day event on 24th June 2023 at Parijat Academy
              for the primary level students with the yoga instructor Parishmita
              Mahanta. <br></br>
              The event was organised by skilful leaders and supportive youths
              who look forward to make the society better in every possible way.
              The workshop included performing simple yoga activities and
              creating awareness of benefits of yoga on our daily life.
              <br></br>
              The short session created a warm environment where students could
              learn more about our country’s history with yoga and some simple
              asanas with the yoga instructor. <br></br>
              We look forward for such events with more enthusiast students who
              are eager to learn about the country and new effective ways to
              enhance themselves by utilising time.
            </p>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <div className="row">
          <div className="col-lg-2" style={{ width: "2vh" }}></div>
          <div className="col-lg-4 order-1 order-lg-2">
            <img
              style={{ borderRadius: "10px" }}
              src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/MentalHealthWorkshopsPhotos%2Fmvm3_a.jpeg?alt=media&token=055db482-d3a7-4e0f-a574-2433ccf0c3b0"
              className="img-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h5
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                fontWeight: "bold",
                backgroundColor: "#cce3f2",
                padding: "8px",
                color: "#0072bc",
              }}
            >
              Mental Health Awareness Workshop - Project Jivanam
            </h5>
            <p style={{ marginLeft: "10px" }}>
              <i
                style={{ color: "green" }}
                class="fa fa-calendar"
                aria-hidden="true"
              ></i>{" "}
              <strong>6th May 2023</strong>
              <br></br>
              <i
                style={{ color: "red" }}
                class="fa fa-map-marker"
                aria-hidden="true"
              ></i>{" "}
              <strong>Maharshi Vidya Mandir-III, Guwahati</strong>
            </p>

            <p style={{ textAlign: "justify" }}>
              <i>
                “That’s one small step for a man,a giant leap for mankind” -Neil
                Armstrong
              </i>
              <br></br>With this virtue of making a valuable change in
              society,the team of Wake With Wellness in collaboration with
              Psychspace, has organised a Mental Health Awareness Workshop under
              the project of Jivanam, for the students of Class-IX and X.
              <br></br>
              The event was joined by like-minded leaders and enthusiastic youth
              who have the passion to serve the community to establish
              well-being and awareness.The workshop included interactive
              sessions, brief introduction to the psychological terms,breaking
              myths and taboos about mental illness and certain behaviours and
              so on.
              <br></br> This educative workshop created a safe space for
              children to discuss and learn about different psychological
              aspects and how it’s never too late to seek for help when needed.
              <br></br>We wish to continue this legacy of serving humanity with
              the hope that we can see more active participation in future and
              want to encourage everyone to contribute to society however they
              can no matter how trivial the step is because as it’s said,”it’s a
              leap for mankind”.
            </p>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <div className="row">
          <div
            className="col-lg-4 order-1 order-lg-1"
            style={{ float: "right" }}
          >
            <img
              style={{ borderRadius: "10px" }}
              src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FBookDrive23%2F6.jpeg?alt=media&token=96746b66-59b6-40d7-ba39-82a6ed60b33a"
              className="img-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-2 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h5
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                fontWeight: "bold",
                backgroundColor: "#cce3f2",
                padding: "8px",
                color: "#0072bc",
              }}
            >
              Donation Drive: Project Jivanam
            </h5>
            <p style={{ marginLeft: "10px" }}>
              <i
                style={{ color: "green" }}
                class="fa fa-calendar"
                aria-hidden="true"
              ></i>{" "}
              <strong> 5th February 2023</strong>
              <br></br>
              <i
                style={{ color: "red" }}
                class="fa fa-map-marker"
                aria-hidden="true"
              ></i>{" "}
              <strong>Amar Prayas Gosthi, Guwahati</strong>
            </p>

            <p style={{ textAlign: "justify" }}>
              <i>
                'Giving is not just about making a donation. It is about making
                a difference'
              </i>
              <br></br>
              With this notion to bring a change into this season of share and
              love, 'Wake With Wellness' in collaboration with 'Wommaniaas',
              took the initiative to organise its first donation Drive of 2023
              under the project 'Jivanam', at Amar Prayas Gosthi, Kahilipara,
              Guwahati on 5th February,2023.
              <br></br>
              The drive was successful in distributing books, stationaries and
              snacks, to the little ones, catering to their needs and
              necessities, in order to keep the flame of positivity burning
              among the young minds, as well as to uplift the reading community
              among them.
              <br></br>
              Truly said, “We make a living by what we get, but we make a life
              by what we give” and hence, we would like to thank all the members
              who were a part of this event,for being a reason for someone's
              smile and happiness with their helping gestures and willing minds.
              <br></br>
              Hoping for this very legacy of successful event conduction to
              continue for many more years to come.
            </p>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <div className="row">
          <div className="col-lg-2" style={{ width: "2vh" }}></div>
          <div className="col-lg-4 order-1 order-lg-2">
            <img
              style={{ borderRadius: "10px" }}
              src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FHepah_1.jpg?alt=media&token=354e96da-4dae-485b-a762-bceb26fc2725"
              className="img-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h5
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                fontWeight: "bold",
                backgroundColor: "#cce3f2",
                padding: "8px",
                color: "#0072bc",
              }}
            >
              Hepah : From Welfare to Well-being
            </h5>
            <p style={{ marginLeft: "10px" }}>
              <i
                style={{ color: "green" }}
                class="fa fa-calendar"
                aria-hidden="true"
              ></i>{" "}
              <strong>14th November 2022</strong>
              <br></br>
              <i
                style={{ color: "red" }}
                class="fa fa-map-marker"
                aria-hidden="true"
              ></i>{" "}
              <strong>Brahmaputra Children Home, Guwahati</strong>
            </p>

            <p style={{ textAlign: "justify" }}>
              Children in every verse is a beautiful creation of god and the
              greatest asset. There's no denying the fact that every child
              within itself holds the capability to brighten up the darkest. As
              said by Mother Teresa -{" "}
              <i>
                "The child is the beauty of God present in the world, that
                greatest gift to a family.”
              </i>
              <br></br>
              To celebrate the effulgent creation of god on the occasion of
              children's day, Team Wake With Wellness in collaboration with
              Universe Is Purple visited the Brahmaputra Children Home,
              Gorhghuli under the campaign ‘Hepah - From welfare to well-being.’
              <br></br>
              We spent a day with the lovable munchkins and offered them
              stationaries, blankets, fruits and ration. We carried the ideology
              of every child, rich or poor, is rightful to being celebrated for
              each dawn they embark.
            </p>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <div className="row">
          <div
            className="col-lg-4 order-1 order-lg-1"
            style={{ float: "right" }}
          >
            <img
              style={{ borderRadius: "10px" }}
              src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FCleanlinessD2a.jpeg?alt=media&token=64f039c4-b016-4559-b957-02a9d2e8bb7f"
              className="img-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-2 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h5
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                fontWeight: "bold",
                backgroundColor: "#cce3f2",
                padding: "8px",
                color: "#0072bc",
              }}
            >
              Swachh Prayaakh : A Cleanliness Drive 2.0
            </h5>
            <p style={{ marginLeft: "10px" }}>
              <i
                style={{ color: "green" }}
                class="fa fa-calendar"
                aria-hidden="true"
              ></i>{" "}
              <strong> 6th November 2022</strong>
              <br></br>
              <i
                style={{ color: "red" }}
                class="fa fa-map-marker"
                aria-hidden="true"
              ></i>{" "}
              <strong>Lachit Ghat, Guwahati</strong>
            </p>

            <p style={{ textAlign: "justify" }}>
              The WWW team is pleased to inform you all that the second phase of
              the Cleanliness Drive under the "Swachh Prayaakh" programme has
              been successfully conducted with the amalgamation of the "Serve
              Smile Foundation" on November 6, 2022.<br></br>
              The drive started with the cleaning of the river bank of the
              Lachit Ghat, with the removal of the litter around the area,
              sweeping of the dirty corners, and thus, a drastic before and
              after result, turning the Ghat clean and fresh to look at.
              <br></br>
              As it is said, "cleanliness is next to godliness," and hence, to
              further accentuate the importance of hygiene and cleanliness, we
              tried our level best to help promote this message and act on it.
              We would like to express our sincerest thanks to each and every
              individual who stepped up despite their busy schedule and joined
              us for this initiative.Thank you, one and all!
            </p>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <div className="row">
          <div className="col-lg-2" style={{ width: "2vh" }}></div>
          <div className="col-lg-4 order-1 order-lg-2">
            <img
              style={{ borderRadius: "10px" }}
              src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2Fcleanliness1b.jpeg?alt=media&token=00f7627b-1bfe-4680-bd52-6d7f8e024171"
              className="img-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h5
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                fontWeight: "bold",
                backgroundColor: "#cce3f2",
                padding: "8px",
                color: "#0072bc",
              }}
            >
              Swachh Prayaakh : A Cleanliness Drive
            </h5>
            <p style={{ marginLeft: "10px" }}>
              <i
                style={{ color: "green" }}
                class="fa fa-calendar"
                aria-hidden="true"
              ></i>{" "}
              <strong>9th October 2022</strong>
              <br></br>
              <i
                style={{ color: "red" }}
                class="fa fa-map-marker"
                aria-hidden="true"
              ></i>{" "}
              <strong>Uzanbazar Ghat, Guwahati</strong>
            </p>

            <p style={{ textAlign: "justify" }}>
              The WWW team is pleased to apprise you all that the first phase of
              Cleanliness Drive under 'Swachh Prayaakh' Programme has been
              successfully conducted with the amalgamation of two of the
              organisations- 'Universe is Purple' and 'Serve Smile Foundation',
              on 9th of October, 2022.<br></br>
              The drive started from the cleaning of the River bank of the
              Uzanbazar Ghat, with the removal of the litters around the area,
              sweeping of the dirty corners and thus, making it to a drastic
              before and after result, turning the Ghat, clean and fresh to look
              at.<br></br>
              As it is said 'cleanliness is next to godliness' and hence to
              further accentuate the importance of hygiene and cleanliness, we
              tried our level best to help promote this message and act on it.
              We would like to express our sincerest thanks to each and every
              individual who stepped up despite their busy schedule and joined
              us for this initiative.Thank you one and all!
            </p>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <div className="row">
          <div className="col-lg-4 order-1 order-lg-1">
            <img
              style={{ borderRadius: "10px" }}
              src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/MedicalCampDhmj%2F1.JPG?alt=media&token=b390f6fe-ad10-41f9-9f9f-d815a045cd75"
              className="img-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-2 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h5
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                fontWeight: "bold",
                backgroundColor: "#cce3f2",
                padding: "8px",
                color: "#0072bc",
              }}
            >
              Wellness Drive: Medical & Health CheckUp
            </h5>
            <p style={{ marginLeft: "10px" }}>
              <i
                style={{ color: "green" }}
                class="fa fa-calendar"
                aria-hidden="true"
              ></i>{" "}
              <strong>14th March 2022</strong>
              <br></br>
              <i
                style={{ color: "red" }}
                class="fa fa-map-marker"
                aria-hidden="true"
              ></i>{" "}
              <strong>'Jesthakunja' Oldage Home Dhemaji</strong>
            </p>

            <p style={{ textAlign: "justify" }}>
              <span style={{ fontStyle: "italic" }}>
                "Your body holds deep wisdom trust in it learn from it nourish
                it and watch your life transform"
                <br></br>-----Bella Bleue
              </span>
              <br></br>
              <br></br>
              Wake with Wellness was successfully able to conducted a wellness
              drive at Jesthakunja Oldage Home, Dhemaj on 18th March We were
              supported by a strong medical team from Dhemaji Hospital with
              their help We were able to provide free medical health checkups to
              more than 35 people.
              <br></br>
              We offer our sincere thanks and gratitude to out chief guest-{" "}
              <strong>
                Dr. Paramananda Deori (Chief Medical and Health Officer)
              </strong>{" "}
              without whom this would never had been possible. You are indeed a
              great inspiration sir.
            </p>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <div className="row">
          <div className="col-lg-2" style={{ width: "2vh" }}></div>
          <div className="col-lg-4 order-1 order-lg-2">
            <img
              style={{ borderRadius: "10px" }}
              src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/clothdonationDhmj%2Fclothdonate1.JPG?alt=media&token=6a55a215-5ccf-43a9-865a-c5b61dc61119"
              className="img-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h5
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                fontWeight: "bold",
                backgroundColor: "#cce3f2",
                padding: "8px",
                color: "#0072bc",
              }}
            >
              Wellness Drive: Clothes & Books Donation
            </h5>
            <p style={{ marginLeft: "10px" }}>
              <i
                style={{ color: "green" }}
                class="fa fa-calendar"
                aria-hidden="true"
              ></i>{" "}
              <strong>20th February 2022</strong>
              <br></br>
              <i
                style={{ color: "red" }}
                class="fa fa-map-marker"
                aria-hidden="true"
              ></i>{" "}
              <strong>Enajori Children Home, Dhemaji</strong>
            </p>

            <p style={{ textAlign: "justify" }}>
              You have often heard the quote, ”Sharing is Caring”, but do you
              practice this in real life? If not then you are missing out on the
              most significant feeling in life. The satisfaction you receive
              after bringing a smile to someone’s face is very unreal yet very
              special.
              <br></br>
              Wake With Wellness got lucky on this note. We had the opportunity
              to conduct a drive called ”Wellness Drive- Clothes and Books
              Donation Drive” at Dhemaji. We collected books and clothes from
              the locals and arranged them accordingly for the drive.
              <br></br>
              On 20th February 2022, the collected items were distributed among
              the children at Enjori Children's Home, an orphanage in Borajan,
              Machkhowa, Dhemaji. The innocent smiles of the children made us
              realize that we’ve got a great life with all the necessary
              materials available to us and yet we tend to take things for
              granted.
              <br></br>
              This event surely inspired us and hope that it did to you too.
              Come forward and experience the joy of SHARING.
            </p>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <div className="row">
          <div className="col-lg-4 order-1 order-lg-1">
            <img
              style={{ borderRadius: "10px" }}
              src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/prabhati%2F2.jpeg?alt=media&token=7fa1d527-a5ba-4c99-8b1a-ae20aee96047"
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-2 content">
            <h5
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                fontWeight: "bold",
                backgroundColor: "#cce3f2",
                padding: "8px",
                color: "#0072bc",
              }}
            >
              Prabhati - A Dawn of a Vital Outlook
            </h5>
            <p style={{ marginLeft: "10px" }}>
              <i
                style={{ color: "green" }}
                class="fa fa-calendar"
                aria-hidden="true"
              ></i>{" "}
              <strong>29th November 2021</strong>
              <br></br>
              <i
                style={{ color: "red" }}
                class="fa fa-map-marker"
                aria-hidden="true"
              ></i>{" "}
              <strong>Jalukbari Girls High School, Guwahati</strong>
            </p>

            <p style={{ textAlign: "justify" }}>
              There is no denying the fact that unscientific and inaccurate
              information about menstruation is rife in many parts of India.
              Millions of girls and women still don't have adequate knowledge
              regarding the reproductive tract infections caused due to
              ignorance of personal hygiene during menstruation.<br></br>
              In this regard, team WakeWithWellness has successfully organised
              the Awareness session "Prabhati/প্ৰভাতী/प्रभाती - The Dawn Of A
              Vital Outlook" which target girls in their early teens. The entire
              fraternity had amazing sessions and friendly talks with the
              students. We tried to cover the most critical aspects of the
              subject matter from best of our knowledge like: the signs of first
              periods, all about menstrual cycle, the importance, do's and
              don'ts, when to consult a doctor, hygiene, different methods used
              during periods, home remidies, stereotypes and myths.
            </p>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <div className="row">
          <div className="col-lg-2" style={{ width: "2vh" }}></div>
          <div className="col-lg-4 order-1 order-lg-2">
            <img
              style={{ borderRadius: "10px" }}
              src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/gallery%2FBB.JPG?alt=media&token=e3a5b6bc-650a-4d02-85c6-e5df5bf64f42"
              className="img-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h5
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif ",
                fontWeight: "bold",
                backgroundColor: "#cce3f2",
                padding: "8px",
                color: "#0072bc",
              }}
            >
              Wellness Drive : Food Distribution
            </h5>
            <p style={{ marginLeft: "10px" }}>
              <i
                style={{ color: "green" }}
                class="fa fa-calendar"
                aria-hidden="true"
              ></i>{" "}
              <strong>26th September 2021</strong>
              <br></br>
              <i
                style={{ color: "red" }}
                class="fa fa-map-marker"
                aria-hidden="true"
              ></i>{" "}
              <strong>Paltanbazar, Guwahati</strong>
            </p>

            <p style={{ textAlign: "justify" }}>
              Team Wake With Wellness has successfully extended hands to combat
              hunger and hygiene. We have accomplished the Wellness Drive on
              26th of September 2021 , aimed at distributing food items and
              packed lunch to the underprivileged and needy.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Events;
