import React, { useState, useEffect } from "react";
import { db } from "../../firebase";

function DriveAA() {
  const [members, setMembers] = useState([]);

  const ref = db
    .collection("Donations")
    .doc("FoodDrive2021")
    .collection("Donators");

  //REALTIME GET FUNCTION
  function getMembers() {
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setMembers(items);
    });
  }

  useEffect(() => {
    getMembers();
    // eslint-disable-next-line
  }, []);

  const formatTimestamp = (date) => {
    const datee = date.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return datee.toLocaleString("en-US", options);
  };

  return (
    <>
      <section
        id="donation"
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: "10vh" }}
      >
        <div className="container" style={{ marginBottom: "30px" }}>
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <h1>Wellness Drive : Food Distribution</h1>
              <h2>Paltanbazar, Guwahati</h2>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div
          className="container "
          style={{ marginTop: "2vh", maxWidth: "200vh" }}
        >
          <div
            className="card"
            style={{
              backgroundColor: "#d1f2c7",
              padding: "8px",
              maxWidth: "80vh",
            }}
          >
            <h6
              style={{
                color: "green",
                marginTop: "5px",
                fontSize: "17px",
                fontWeight: "bold",
              }}
            >
              ₹5000+ Raised{" "}
              <span
                style={{
                  color: "#acaeb0",
                  fontSize: "10px",
                  fontWeight: "normal",
                }}
              >
                Goal Reached
              </span>
            </h6>
          </div>
          <h3>Our Contributors</h3>
        </div>
      </section>

      <section>
        <div
          className="container-fluid"
          style={{ marginTop: "1vh", maxWidth: "200vh" }}
        >
          <div className="row my-1">
            {members
              .filter((data) => data.status === "Approved")
              .sort((a, b) => b.amount - a.amount)
              .map((data, index) => (
                <div className="col-lg-3 my-2" key={data.id}>
                  <div
                    class="card"
                    style={{
                      backgroundColor: "white",
                      border: "1px solid rgb(165 231 252)",
                    }}
                  >
                    <div class="row">
                      <div
                        className="col-3"
                        style={{
                          textAlign: "center",
                          paddingTop: "5px",
                          color: "rgb(4, 195, 255)",
                        }}
                      >
                        {/* <img
                          class="img-fluid"
                          style={{ width: "100%" }}
                          src="https://cdn.icon-icons.com/icons2/1161/PNG/512/1487716857-user_81635.png"
                        /> */}
                        <h2>{index + 1}</h2>
                      </div>
                      <div className="col-9">
                        <div class="content">
                          <a class="header">{data.name}</a>
                          <div
                            class="description"
                            style={{ fontSize: "11.5px" }}
                          >
                            <i>{data.date && formatTimestamp(data.date)}</i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default DriveAA;
