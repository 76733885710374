import React from "react";
import GoToTop from "../GoToTop";

import "./assets/css/forms.css";

import voxela from "./assets/image/voxela.png";
import tnec from "./assets/image/tnec.png";
import adroit from "./assets/image/adroit.png";
import naucera from "./assets/image/naucera.png";
import food from "./assets/image/food.png";
import blank from "./assets/image/blank.png";

import ImageGallery from "react-image-gallery";

import "./assets/css/ImgGallery/image-gallery.css";
import "./assets/css/ImgGallery/image-gallery.scss";

const inauguration = [
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F24.JPG?alt=media&token=1850d5bf-c686-4253-8f23-7b17b12afe62",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F24.JPG?alt=media&token=1850d5bf-c686-4253-8f23-7b17b12afe62",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F22.JPG?alt=media&token=95301b3b-e6cf-4045-8ab9-21cc5dd88fba",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F22.JPG?alt=media&token=95301b3b-e6cf-4045-8ab9-21cc5dd88fba",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F23.JPG?alt=media&token=a21c19a2-cf3b-4375-92fe-c3c8d6b3f6be",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F23.JPG?alt=media&token=a21c19a2-cf3b-4375-92fe-c3c8d6b3f6be",
  },

  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F21.JPG?alt=media&token=be91212e-03fa-4921-932d-29cbefd33213",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F21.JPG?alt=media&token=be91212e-03fa-4921-932d-29cbefd33213",
  },

  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F18.JPG?alt=media&token=c8255e8c-47a9-4fc7-bfc3-79d893256057",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F18.JPG?alt=media&token=c8255e8c-47a9-4fc7-bfc3-79d893256057",
  },

  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F37.JPG?alt=media&token=bb92384d-21d3-48ce-87fb-8b7a1ad23208",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F37.JPG?alt=media&token=bb92384d-21d3-48ce-87fb-8b7a1ad23208",
  },

  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F25.JPG?alt=media&token=be453734-8221-4d76-b686-f1318cdcf16d",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F25.JPG?alt=media&token=be453734-8221-4d76-b686-f1318cdcf16d",
  },

  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F35.JPG?alt=media&token=07f66e96-7b95-4edf-b590-34acd74e738f",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F35.JPG?alt=media&token=07f66e96-7b95-4edf-b590-34acd74e738f",
  },

  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F10.JPG?alt=media&token=a1ad1793-5d94-4b28-be89-1deb6c4d4819",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F10.JPG?alt=media&token=a1ad1793-5d94-4b28-be89-1deb6c4d4819",
  },
];
const org = [
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F20.JPG?alt=media&token=33cdb2e1-950f-48fb-a36d-36d7fe1342da",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F20.JPG?alt=media&token=33cdb2e1-950f-48fb-a36d-36d7fe1342da",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F19.JPG?alt=media&token=fb53d6fa-e9f1-4357-855b-c3936ea3b8c8",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F19.JPG?alt=media&token=fb53d6fa-e9f1-4357-855b-c3936ea3b8c8",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F16.JPG?alt=media&token=708ab7c9-db86-4128-86fa-82c067cd6d86",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F16.JPG?alt=media&token=708ab7c9-db86-4128-86fa-82c067cd6d86",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F36.JPG?alt=media&token=5f4e5785-6c61-4a0c-95cc-cf22857a07fc",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F36.JPG?alt=media&token=5f4e5785-6c61-4a0c-95cc-cf22857a07fc",
  },
];

const felicitation = [
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F1.JPG?alt=media&token=fcacefe3-4c93-4422-92d0-4c252eff83c7",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F1.JPG?alt=media&token=fcacefe3-4c93-4422-92d0-4c252eff83c7",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F9.JPG?alt=media&token=294e4434-0aa6-42ee-9d57-77eeaaadd4bf",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F9.JPG?alt=media&token=294e4434-0aa6-42ee-9d57-77eeaaadd4bf",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F2.JPG?alt=media&token=d29b8f5c-cf2d-4f5b-91bc-a25325dc4190",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F2.JPG?alt=media&token=d29b8f5c-cf2d-4f5b-91bc-a25325dc4190",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F6.JPG?alt=media&token=7d880b5a-bab5-421a-a0e4-5dbc8efe4484",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F6.JPG?alt=media&token=7d880b5a-bab5-421a-a0e4-5dbc8efe4484",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F4.JPG?alt=media&token=92b9f533-03c1-4ad7-b5a1-e0b008518c1b",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F4.JPG?alt=media&token=92b9f533-03c1-4ad7-b5a1-e0b008518c1b",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F8.JPG?alt=media&token=2e16f803-de4d-4129-98fe-45bf662189c0",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F8.JPG?alt=media&token=2e16f803-de4d-4129-98fe-45bf662189c0",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F3.JPG?alt=media&token=b48cc269-0d0f-4364-a1d7-0739387a6f2c",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F3.JPG?alt=media&token=b48cc269-0d0f-4364-a1d7-0739387a6f2c",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F5.JPG?alt=media&token=87f00957-b691-4b09-974a-0eb853dc0ded",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F5.JPG?alt=media&token=87f00957-b691-4b09-974a-0eb853dc0ded",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F7.JPG?alt=media&token=c5fc91b3-2183-49a1-a172-33736b4192c4",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F7.JPG?alt=media&token=c5fc91b3-2183-49a1-a172-33736b4192c4",
  },
];

const cultural = [
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F17.JPG?alt=media&token=ae40a5ee-fb4f-4a4f-95bf-9ec97e3f02d8",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F17.JPG?alt=media&token=ae40a5ee-fb4f-4a4f-95bf-9ec97e3f02d8",
  },

  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F28.JPG?alt=media&token=1de36678-19ee-452c-b8f4-36041ee796e7",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F28.JPG?alt=media&token=1de36678-19ee-452c-b8f4-36041ee796e7",
  },

  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F27.JPG?alt=media&token=822bd2f2-7139-4c88-a7d8-0b8bb8a16081",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F27.JPG?alt=media&token=822bd2f2-7139-4c88-a7d8-0b8bb8a16081",
  },

  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F12.JPG?alt=media&token=c64e575e-4bd4-4cd5-bace-b1f86791afd3",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F12.JPG?alt=media&token=c64e575e-4bd4-4cd5-bace-b1f86791afd3",
  },

  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F13.JPG?alt=media&token=6724b49e-007e-4063-a1a9-93a7bb847c75",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F13.JPG?alt=media&token=6724b49e-007e-4063-a1a9-93a7bb847c75",
  },

  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F26.JPG?alt=media&token=617600a9-95cf-463b-a025-4c8168d1a7d6",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F26.JPG?alt=media&token=617600a9-95cf-463b-a025-4c8168d1a7d6",
  },

  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F15.JPG?alt=media&token=eb5d07e3-48c3-4cd5-8f9c-517274deaef4",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F15.JPG?alt=media&token=eb5d07e3-48c3-4cd5-8f9c-517274deaef4",
  },

  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F14.JPG?alt=media&token=908af9a2-e764-440f-9cfb-67baee66a3af",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F14.JPG?alt=media&token=908af9a2-e764-440f-9cfb-67baee66a3af",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F29.JPG?alt=media&token=d1981a00-0011-4f88-90f7-49d3793b3e77",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F29.JPG?alt=media&token=d1981a00-0011-4f88-90f7-49d3793b3e77",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F30.JPG?alt=media&token=fbdd391d-9826-4130-bef6-a256907dd43f",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F30.JPG?alt=media&token=fbdd391d-9826-4130-bef6-a256907dd43f",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F31.JPG?alt=media&token=c3a737b3-6ed4-4923-88d7-32a9f95c196c",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F31.JPG?alt=media&token=c3a737b3-6ed4-4923-88d7-32a9f95c196c",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F32.JPG?alt=media&token=59d57b73-c9b6-4c63-b37b-ba3a570f0e26",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F32.JPG?alt=media&token=59d57b73-c9b6-4c63-b37b-ba3a570f0e26",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F33.JPG?alt=media&token=e327cbd6-1c68-42e7-9ca2-a9c7ce3315e5",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F33.JPG?alt=media&token=e327cbd6-1c68-42e7-9ca2-a9c7ce3315e5",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F34.JPG?alt=media&token=1c3df0c3-339e-48d9-a8e3-1677c89dfab0",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F34.JPG?alt=media&token=1c3df0c3-339e-48d9-a8e3-1677c89dfab0",
  },
];

function NEconvene() {
  return (
    <>
      <GoToTop />

      <section
        id="neconvenep"
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: "10vh" }}
      >
        <div className="container" style={{ marginBottom: "30px" }}>
          <div
            className="row justify-content-center"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div className="col-xl-12 col-lg-8">
              <h1>NE Organization Convene'22</h1>
              <h2>The Annual Social Welfare Convergent</h2>
            </div>
          </div>
        </div>
      </section>

      <section style={{ marginTop: "2vh" }}>
        <div class="container-fluid" style={{ maxWidth: "200vh" }}>
          <div className="row">
            <div className="col-lg-4" style={{ float: "right" }}>
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="3"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="4"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="5"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="6"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="7"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="8"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="9"
                  ></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      class="d-block w-100"
                      src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F11.JPG?alt=media&token=f06a9116-63d9-4096-a3ce-3d4e91809554"
                      alt="1"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F24.JPG?alt=media&token=1850d5bf-c686-4253-8f23-7b17b12afe62"
                      alt="2"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F20.JPG?alt=media&token=33cdb2e1-950f-48fb-a36d-36d7fe1342da"
                      alt="3"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F9.JPG?alt=media&token=294e4434-0aa6-42ee-9d57-77eeaaadd4bf"
                      alt="4"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F18.JPG?alt=media&token=c8255e8c-47a9-4fc7-bfc3-79d893256057"
                      alt="5"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F21.JPG?alt=media&token=be91212e-03fa-4921-932d-29cbefd33213"
                      alt="6"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F19.JPG?alt=media&token=fb53d6fa-e9f1-4357-855b-c3936ea3b8c8"
                      alt="7"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F27.JPG?alt=media&token=822bd2f2-7139-4c88-a7d8-0b8bb8a16081"
                      alt="8"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F28.JPG?alt=media&token=1de36678-19ee-452c-b8f4-36041ee796e7"
                      alt="9"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src="https://firebasestorage.googleapis.com/v0/b/website-818ed.appspot.com/o/NEconvene%2F25.JPG?alt=media&token=be453734-8221-4d76-b686-f1318cdcf16d"
                      alt="10"
                    />
                  </div>
                </div>
                <a
                  class="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a
                  class="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>

            <div
              className="col-lg-8"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "13.3px",
                  marginTop: "5px",
                }}
              >
                <i>
                  “If you can visualize it, if you can dream it, there’s some
                  way to do it.”
                </i>
                <br></br>
                <br></br>
                It is the matter of immense pride that Wake With Wellness, with
                such a wondrous visuality, successfully conducted and hosted the
                Mega Event of <strong>NE Organisation Convene-2022</strong>, on{" "}
                <strong>27th December, 2022</strong>. The event was an
                amalgamation of <strong>12 organisations</strong>, working for
                the upliftment and the betterment of our society.
                <br></br>
                <br></br>
                The event was graced with the auspicious presence of{" "}
                <strong>Arghadeep Baruah</strong> and{" "}
                <strong>Pranamika Bhuyan (RJ Pahi)</strong> as guests of honour
                for the day, and were felicitated with a phulam gamusa, a
                momentous and sapling along with the other organisations.
                <br></br>
                <br></br>
                Eventually, Founder, HR and finance head of WWW, Sudhanshu
                Borthakur briefed and shared a glimpse about the journey of Wake
                With Wellness since inception, followed by the launch of Project{" "}
                <strong style={{ color: "red" }}>
                  जीवनम् (jīvanam): An awareness campaign on lifestyle diseases
                </strong>
                , further, addressing the crowd by the change-makers and
                influential leaders, making the event more insightful and
                informative, and concluded with an Open mic session, with
                special performances by the artists over the spectrum of arts
                and culture which was designed for entertainment & enjoyment.
                <br></br>
                <br></br>
                NE Organisation Convene'22 did not happen overnight. There were
                many tribulations and factors to take into consideration, for
                which we immensely gratified to express how important it was for
                every team to put in their very best of dedication, love and
                unity to make it to a successful end. Hoping for this very
                legacy to continue for many more years to come.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="portfolio" className="portfolio">
        <div className="content container-fluid" style={{ maxWidth: "200vh" }}>
          <div className="row">
            <div
              className="col-lg-3"
              style={{ float: "right", marginTop: "30px" }}
            >
              <div className="content">
                <h5
                  style={{
                    background:
                      "linear-gradient(95.24deg, #038CB7 0%, #04C3FF 137.16%)",
                    fontSize: "15px",
                    padding: "10px",
                    color: "white",
                  }}
                >
                  NE Organisation Convene'22 - Inauguration
                </h5>
              </div>
              <ImageGallery items={inauguration} />
            </div>

            <div
              className="col-lg-3"
              style={{ float: "right", marginTop: "30px" }}
            >
              <div className="content">
                <h5
                  style={{
                    background:
                      "linear-gradient(95.24deg, #038CB7 0%, #04C3FF 137.16%)",
                    fontSize: "15px",
                    padding: "10px",
                    color: "white",
                  }}
                >
                  Campaign launch जीवनम् (jīvanam)
                </h5>
              </div>
              <ImageGallery items={org} />
            </div>

            <div
              className="col-lg-3"
              style={{ float: "right", marginTop: "30px" }}
            >
              <div className="content">
                <h5
                  style={{
                    background:
                      "linear-gradient(95.24deg, #038CB7 0%, #04C3FF 137.16%)",
                    fontSize: "15px",
                    padding: "10px",
                    color: "white",
                  }}
                >
                  Felicitation of the Organizations
                </h5>
              </div>
              <ImageGallery items={felicitation} />
            </div>

            <div
              className="col-lg-3"
              style={{ float: "right", marginTop: "30px" }}
            >
              <div className="content">
                <h5
                  style={{
                    background:
                      "linear-gradient(95.24deg, #038CB7 0%, #04C3FF 137.16%)",
                    fontSize: "15px",
                    padding: "10px",
                    color: "white",
                  }}
                >
                  NE Organisation Convene'22 - Cultural
                </h5>
              </div>
              <ImageGallery items={cultural} />
            </div>
          </div>

          <div className="row">
            <div
              className="col-lg-3"
              style={{ float: "right", marginTop: "30px" }}
            >
              <div className="content">
                <iframe
                  width="100%"
                  height="220vh"
                  src="https://www.youtube.com/embed/GE-deWim7D4"
                  title="তুমি মাথো মোৰ | Tumi Mathu Mur | Aliva Kalita | NE Organization Convene'22 | Wake With Wellness"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div
              className="col-lg-3"
              style={{ float: "right", marginTop: "30px" }}
            >
              <div className="content">
                <iframe
                  width="100%"
                  height="220vh"
                  src="https://www.youtube.com/embed/kLIl8M79_A0"
                  title="RJ Paahi & Arghadeep Bhuyan | Chief guests | NE Organization Convene'22 | Wake With Wellness"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div
              className="col-lg-3"
              style={{ float: "right", marginTop: "30px" }}
            >
              <div className="content"></div>
            </div>
          </div>
        </div>
      </section>

      <br></br>
      <br></br>
      <section id="collab" class="collab">
        <div class="container-fluid" style={{ maxWidth: "200vh" }}>
          <div class="section-title">
            <h2>Our Partners</h2>
          </div>

          <div class="container-fluid" style={{ cursor: "pointer" }}>
            <div class="row">
              <div class="col">
                <img src={voxela} class="img-fluid" alt="voxela" />
              </div>
              <div class="col">
                <img src={tnec} class="img-fluid" alt="tnec" />
              </div>
              <div class="col">
                <img src={adroit} class="img-fluid" alt="adroit" />
              </div>
              <div class="col">
                <img src={naucera} class="img-fluid" alt="naucera" />
              </div>
              <div class="col">
                <img src={food} class="img-fluid" alt="Gopala" />
              </div>
              <div class="col">
                <img src={blank} class="img-fluid" alt="blank" />
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NEconvene;
