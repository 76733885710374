import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Icon, Image, Header } from "semantic-ui-react";
import { db } from "../../firebase";
import { useNavigate, Link } from "react-router-dom";
import GoToTop from "../../GoToTop";
import success from "../assets/image/success.gif";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { serverTimestamp } from "firebase/firestore";

const Donation = () => {
  function refreshPage() {
    window.location.reload(false);
  }

  const navigate = useNavigate();
  function refreshPage() {
    window.location.reload();
  }
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhastapp] = useState("");
  const [kit, setKit] = useState("");
  const [amount, setAmount] = useState("");
  const [amountdisabled, setAmountDisabled] = useState("none");
  const [location, SetLocation] = useState("");
  const [loader, setLoader] = useState(false);

  const handleInputChange = (e) => {
    const selectedValue = e.target.value;
    setKit(selectedValue);

    // Handle logic based on selected value
    if (selectedValue === "Serves 1") {
      setAmount("50");
      setAmountDisabled("none");
    } else if (selectedValue === "Serves 2") {
      setAmount("90");
      setAmountDisabled("none");
    } else if (selectedValue === "Serves 3") {
      setAmount("130");
      setAmountDisabled("none");
    } else if (selectedValue === "Serves 4") {
      setAmount("170");
      setAmountDisabled("none");
    } else if (selectedValue === "Serves 6") {
      setAmount("250");
      setAmountDisabled("none");
    } else if (selectedValue === "Serves 8") {
      setAmount("340");
      setAmountDisabled("none");
    } else if (selectedValue === "Serves 10") {
      setAmount("420");
      setAmountDisabled("none");
    } else if (selectedValue === "Serves 12") {
      setAmount("500");
      setAmountDisabled("none");
    } else if (selectedValue === "Custom") {
      setAmount("");
      setAmountDisabled("block");
    }
  };

  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [coordinates, setCoordinates] = useState(null);

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setCoordinates(latLng);
      SetLocation(selectedAddress);
    } catch (error) {
      console.error("Error selecting location:", error);
    }
  };
  ///////////////////Current Location

  const [locationName, setLocationName] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    // Get the current location using Geolocation API
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);

          // Use Google Maps Geocoding API to get the location name
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDoiXHxMG_vZPHz0eeJj5s661xhfU-Y4_4`
          );

          if (response.ok) {
            const data = await response.json();
            const firstResult = data.results[0];
            const formattedAddress = firstResult.formatted_address;
            setLocationName(formattedAddress);
          } else {
            console.error("Error fetching location name:", response.statusText);
          }
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
    }
  }, []);
  ///////////////////Current Location

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    db.collection("AAPendingPays").add({
      name: name,
      email: email,
      phone: whatsapp,
      date: serverTimestamp(),
      amount: amount,
      kit: kit,
      hometown: location,
      latitude: coordinates.lat,
      currentlat: latitude,
      currentlng: longitude,
      longitude: coordinates.lng,
      event: "Flood Donation Drive, Delhi",
    });

    try {
      // Set up the payment options
      const options = {
        key: "rzp_live_Rq5LxLxhjlzyiU",
        key_secret: "v3o8vc3WaumPosCrLd2WAdaO",
        amount: amount * 100,
        currency: "INR",
        name: "Wake With Wellness",
        description: "Flood Donation Drive",
        prefill: {
          name: name,
          email: email,
          contact: whatsapp,
        },
        notes: {
          address: "Beltola, Guwahati, Assam",
        },
        theme: {
          color: "#16151a",
        },
        handler: async (response) => {
          try {
            await db
              .collection("Donations")
              .doc("FoodDrive2024Delhi")
              .collection("Donators")
              .add({
                name: name,
                email: email,
                phone: whatsapp,
                date: serverTimestamp(),
                amount: amount,
                kit: kit,
                latitude: coordinates.lat,
                longitude: coordinates.lng,
                currentlat: latitude,
                currentlng: longitude,
                hometown: location,
                payId: response.razorpay_payment_id,
                status: "Approved",
              });
            window.location.replace("/flood_drive_2024_");
          } catch (error) {
            console.error("Error processing payment:", error);
          }
        },
        modal: {
          ondismiss: function () {
            alert("Payment cancelled");
            window.location.replace("/donation");
          },
        },
      };

      // Create a new Razorpay instance and open the payment modal
      const pay = new window.Razorpay(options);
      pay.open();
    } catch (error) {
      console.error("Error initiating payment:", error);
    } finally {
      setLoader(false);
      setName("");
      setEmail("");
      setWhastapp("");
      setKit("");
      setAmount("");
      SetLocation("");
    }
  };

  //////////////////////////////////////////////////
  const [totalDonation, setTotalDonation] = useState(0);

  useEffect(() => {
    const fetchTotalDonation = async () => {
      try {
        const donationsRef = db
          .collection("Donations")
          .doc("FoodDrive2024Delhi")
          .collection("Donators"); // Replace with your collection name
        const donationsSnapshot = await donationsRef.get();

        let sum = 0;

        donationsSnapshot.forEach((donationDoc) => {
          const { amount } = donationDoc.data();
          sum += +amount;
        });

        setTotalDonation(sum);
      } catch (error) {
        console.error("Error fetching total donation:", error);
      }
    };

    fetchTotalDonation();
  }, []);
  const progressBarStyle = {
    width: `${(totalDonation / 7000) * 100}%`, // Adjust the divisor based on your goal
    height: "30px",
    backgroundColor: "#4183c4",
    // backgroundColor: "#4183c4",
    borderRadius: "5px",
  };

  const phoneNumber = "+916385334226"; // Replace with the desired phone number
  const message =
    "Hello, I am interested in contributing. Could you please assist me with the contribution process?"; // Replace with your message

  const whatsAppLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  return (
    <>
      <GoToTop />

      <section
        id="donation"
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: "10vh" }}
      >
        <div className="container" style={{ marginBottom: "30px" }}>
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <h1>Flood Donation Drive</h1>
              <h2>Balipur, Baksha, Assam</h2>
            </div>
          </div>
        </div>
      </section>
      <div
        className="container-fluid"
        id="partb"
        style={{ marginTop: "1vh", maxWidth: "200vh" }}
      >
        <div className="content">
          <div className="row">
            <div
              className="col-lg-6 order-2 order-lg-1"
              style={{ marginTop: "2vh" }}
            >
              <div
                style={{
                  backgroundColor: "#dbf1ff",
                  padding: "20px",
                  borderRadius: "5px",
                }}
              >
                <h3 style={{ color: "#04C3FF" }}>
                  United for Assam: Flood Relief Donation Drive
                </h3>
                <p style={{ textAlign: "justify", fontSize: "14px" }}>
                  Wake with Wellness Organization, in collaboration with R.A.Y
                  Foundation and Jonak Foundation, is spearheading a flood
                  donation drive to support the communities affected by the
                  recent devastating floods in Assam. This collaborative effort
                  aims to provide essential relief materials such as food,
                  clothing, medical supplies, and temporary shelter to those who
                  have been displaced or are struggling in the aftermath of the
                  floods. The drive is a call to action for compassionate
                  individuals and organizations to contribute generously,
                  ensuring that the affected families receive timely and
                  adequate assistance.
                  <br />
                  <br />
                  The united efforts of Wake with Wellness, R.A.Y Foundation,
                  and Jonak Foundation underscore the importance of community
                  solidarity and resilience during times of crisis. By pooling
                  resources and leveraging their collective strengths, these
                  organizations aim to make a meaningful difference in the lives
                  of flood victims. The drive not only seeks to address
                  immediate needs but also to foster a sense of hope and
                  rebuilding within the affected communities. Together, we can
                  help Assam rise above this challenge and pave the way for
                  recovery and renewal.
                </p>
              </div>
            </div>

            <div
              className="col-lg-6 order-1 order-lg-2"
              style={{ marginTop: "2vh", borderLeft: "2px solid #04C3FF" }}
            >
              <p style={{ color: "grey" }}>
                <i class="fa fa-check-square-o" aria-hidden="true"></i> Your
                contribution matters!
              </p>
              <div>
                <div
                  style={{
                    border: "1px solid #4183c4",
                    height: "31px",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <div style={progressBarStyle}></div>
                </div>
                <h6
                  style={{
                    color: "#4183c4",
                    marginTop: "5px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  ₹{totalDonation} Raised |{" "}
                  <span style={{ color: "#acaeb0" }}>₹7000 Goal</span>
                </h6>
                <p style={{ color: "green" }}>***Primary Goal Reached</p>
              </div>
              <hr></hr>
              <Form onSubmit={handleSubmit}>
                <div class="col-sm-12 ">
                  <div className="row">
                    <div className="col-lg-6 my-2">
                      <Form.Input
                        label="Name"
                        name="name"
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                    </div>

                    <div className="col-lg-6 my-2">
                      <Form.Input
                        label="Email ID"
                        name="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div className="row">
                    <div className="col-lg my-2">
                      <Form.Input
                        error={
                          errors.whatsapp ? { content: errors.whatsapp } : null
                        }
                        label="Contact"
                        name="whatsapp"
                        type="tel"
                        placeholder="Whatsapp"
                        onChange={(e) => setWhastapp(e.target.value)}
                        value={whatsapp}
                      />
                    </div>

                    <div className="col-lg my-2">
                      <Form.Field
                        error={errors.kit ? { content: errors.kit } : null}
                        label="Serves"
                        name="kit"
                        style={{ height: "35px" }}
                        control="select"
                        onChange={handleInputChange}
                        value={kit}
                      >
                        <option value="">--Select--</option>
                        <option value="Serves 1">Serves 1 (Rs.50)</option>
                        <option value="Serves 2">Serves 2 (Rs.90)</option>
                        <option value="Serves 3">Serves 3 (Rs.130)</option>
                        <option value="Serves 4">Serves 4 (Rs.170)</option>
                        <option value="Serves 6">Serves 6 (Rs.250)</option>
                        <option value="Serves 8">Serves 8 (Rs.340)</option>
                        <option value="Serves 10">Serves 10 (Rs.420)</option>
                        <option value="Serves 12">Serves 12 (Rs.500)</option>
                        <option value="Custom">Custom</option>
                      </Form.Field>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="col-lg-6 my-2"
                      style={{ display: amountdisabled }}
                    >
                      <Form.Input
                        label="Contribution Amount"
                        name="amount"
                        placeholder="Enter you contribution amount"
                        onChange={(e) => setAmount(e.target.value)}
                        value={amount}
                      />
                      {amount && amount < 19 ? (
                        <>
                          <p
                            style={{
                              fontSize: "11px",
                              color: "red",
                              marginTop: "-1.5vh",
                            }}
                          >
                            *Minimum amount should be Rs.20
                          </p>
                        </>
                      ) : null}
                    </div>

                    <div className="col-lg-6 my-2">
                      <LoadScript
                        googleMapsApiKey="AIzaSyDoiXHxMG_vZPHz0eeJj5s661xhfU-Y4_4"
                        libraries={["places"]}
                      >
                        <PlacesAutocomplete
                          value={location}
                          onChange={(value) => SetLocation(value)}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <Form.Input
                                {...getInputProps({
                                  placeholder: "Enter location",
                                  className: "location-input",
                                  label: "HomeTown",
                                })}
                              />

                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => (
                                <>
                                  <div
                                    className="suggestions-container"
                                    style={{
                                      border: "1px solid #e8e6e6",
                                      padding: "10px",
                                    }}
                                  >
                                    <div
                                      {...getSuggestionItemProps(suggestion)}
                                      key={suggestion.placeId}
                                      className="suggestion"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      {suggestion.description}
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </LoadScript>
                    </div>

                    <div className="col-lg-12 my-2">
                      <div
                        className="card"
                        style={{ fontSize: "11px", backgroundColor: "white" }}
                      >
                        <div className="row">
                          <div className="col-lg-3">
                            <i
                              class="fa fa-map-marker"
                              style={{ color: "red" }}
                            ></i>{" "}
                            <b> Current Location:</b>
                          </div>
                          <div
                            className="col-lg-9"
                            style={{ borderLeft: "1px solid #e6e6e6" }}
                          >
                            {locationName}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-right my-4">
                  {isSubmit ? (
                    <div class="ui active centered inline loader">
                      <div class="ui text loader" style={{ color: "grey" }}>
                        Please wait...
                      </div>
                    </div>
                  ) : (
                    <>
                      {!(name && amount > 19 && whatsapp && kit && location) ? (
                        <>
                          <Button disabled color="primary" size="small">
                            Donate Now
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button type="submit" color="primary" size="small">
                            Donate Now
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </Form>{" "}
              <h6>
                <i
                  style={{ color: "orange" }}
                  class="fa fa-bandcamp"
                  aria-hidden="true"
                ></i>{" "}
                Contact Us For Any Support or Help:{" "}
                <a href={whatsAppLink} target="_blank">
                  <i class="fa fa-whatsapp" aria-hidden="true"></i> Whatsapp
                </a>{" "}
                <a style={{ marginLeft: "15px" }} href="tel:+917086952212">
                  <i class="fa fa-phone" aria-hidden="true"></i> Call Now
                </a>
              </h6>
            </div>
          </div>
        </div>

        <div
          class="card my-3"
          style={{ backgroundColor: "#ffe5e3", padding: "10px" }}
        >
          <b>Past Donation Drives</b>
          <div className="row">
            <div className="col-lg-12 my-1">
              <Link
                class="ui orange image label"
                to="/food_drive_2021"
                style={{ textAlign: "left", marginTop: "3px" }}
              >
                <i class="fa fa-calendar-check-o" aria-hidden="true"></i>{" "}
                Wellness Drive : Food Distribution
              </Link>
              <Link
                class="ui orange image label"
                to="/Medical_Health_Checkup"
                style={{ textAlign: "left", marginTop: "3px" }}
              >
                <i class="fa fa-calendar-check-o" aria-hidden="true"></i>{" "}
                Medical & Health Checkup
              </Link>
              <Link
                class="ui orange image label"
                to="/Hepah_From_Welfare_to_Well_being"
                style={{ textAlign: "left", marginTop: "3px" }}
              >
                <i class="fa fa-calendar-check-o" aria-hidden="true"></i> Hepah
                : From Welfare to Well-being
              </Link>
              <a
                class="ui orange image label"
                href="/flood_drive_2023"
                style={{ textAlign: "left", marginTop: "3px" }}
              >
                <i class="fa fa-calendar-check-o" aria-hidden="true"></i> Flood
                Distribution Drive
              </a>
              <Link
                class="ui blue image label"
                to="/flood_drive_2024_"
                style={{ textAlign: "left", marginTop: "3px" }}
              >
                <i class="fa fa-calendar-check-o" aria-hidden="true"></i> Flood
                Donation Drive
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Donation;
