import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {blogList} from "../../config/data";
import EmptyList from "../../components/common/EmptyList";
import "./styles.css";
import GoToTop from "../../../../GoToTop";
import {db} from "../../../../firebase";
import {serverTimestamp, onSnapshot} from "firebase/firestore";
import {Form} from "semantic-ui-react";
import {Alert} from "react-bootstrap";

const Blog = () => {
  const {id} = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    let blog = blogList.find((blog) => blog.id === parseInt(id));
    if (blog) {
      setBlog(blog);
    }
  }, [id]);

  const today = new Date();
  const time =
    today.getHours().toString().padStart(2, "0") +
    ":" +
    today.getMinutes().toString().padStart(2, "0") +
    ":" +
    today.getSeconds().toString().padStart(2, "0");

  const current = new Date();
  const year = current.getFullYear(); // Get the current year
  const month = String(current.getMonth() + 1).padStart(2, "0"); // Get the current month (0-11) and add leading zero if needed
  const day = String(current.getDate()).padStart(2, "0");
  const order = `${year}-${month}-${day}-${time}`;

  const [name, setName] = useState("");
  const [cid, setCid] = useState("");
  const [comment, setComment] = useState("");
  const [success, setsuccess] = useState("");

  function generateUniqueId(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uniqueId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueId += characters.charAt(randomIndex);
    }

    return uniqueId;
  }

  const uniqueId = generateUniqueId(10);

  const handleComment = async () => {
    setsuccess("");
    try {
      db.collection("BlogComments")
        .doc(id)
        .collection("comments")
        .doc(uniqueId)
        .set({
          name,
          comment,
          order,
          cid: uniqueId,
          posted: serverTimestamp(),
        });

      // Reset form fields and close the modal
      // Reset form fields and close the modal
      setsuccess("Comment posted!");

      setComment("");
      setCid("");
      setName("");
      setTimeout(() => {
        setsuccess("");
      }, 2000);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  const slangWords = [
    "fuck",
    "suck",
    "dick",
    "porn",
    "piss",
    "pissed",
    "kela",
    "baal",
    "sudurbhai",
    "sudur",
    "maaksaai",
  ];

  const handleInputChange = (event) => {
    const value = event.target.value;
    setComment(value);

    // Filter out slang words
    let filteredText = value;
    slangWords.forEach((slang) => {
      const regex = new RegExp(`\\b${slang}\\b`, "gi");
      filteredText = filteredText.replace(regex, "");
    });

    setComment(filteredText);
  };

  const [msg, setMsg] = useState([]);

  useEffect(() => {
    const unsub = onSnapshot(
      db
        .collection("BlogComments")
        .doc(id)
        .collection("comments")
        .orderBy("order", "asc"),

      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({id: doc.id, ...doc.data()});
        });
        setMsg(list);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <>
      <GoToTop />

      {blog ? (
        <div className="blog-wrap">
          <section
            id="blog_header"
            style={{backgroundImage: `url(${blog.cover})`}}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="container" style={{marginBottom: "50px"}}>
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-10">
                  <h2>{blog.title}</h2>
                  <p>
                    <span style={{color: "grey"}}>Author:</span>{" "}
                    {blog.authorName}{" "}
                    {blog.authorName2 == null ? <></> : <>&</>}{" "}
                    {blog.authorName2}
                  </p>
                </div>
              </div>
            </div>
          </section>

          <div
            class="page-wrapper container-fluid my-5"
            style={{maxWidth: "175vh"}}
          >
            <div class="content">
              <div class="row">
                <div class="col-md-8">
                  <div class="blog-view">
                    <article class="blog blog-single-post">
                      <div class="blog-info clearfix">
                        <div class="post-left">
                          <ul>
                            <li>
                              <p>
                                <i class="fa fa-calendar"></i>{" "}
                                <span>{blog.createdAt}</span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <i class="fa fa-user-o"></i>{" "}
                                <span>
                                  By {blog.authorName}{" "}
                                  {blog.authorName2 == null ? <></> : <>&</>}{" "}
                                  {blog.authorName2}
                                </span>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="blog-image"></div>
                      <div class="blog-content">
                        <p className="blog-desc">{blog.description}</p>
                        <br></br>
                      </div>
                    </article>
                  </div>

                  {/* //Comments */}
                  <div
                    class="container-fluid blog blog-single-post"
                    style={{padding: "25px"}}
                  >
                    <h6
                      class="btn"
                      style={{
                        color: "white",
                        background:
                          "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,169,210,1) 0%, rgba(0,212,255,1) 100%)",
                      }}
                    >
                      Comments
                    </h6>

                    <div class="ui comments" style={{minWidth: "100%"}}>
                      <div style={{maxHeight: "45vh", overflowY: "scroll"}}>
                        {msg.length > 0 ? (
                          <>
                            {msg.map((item, index) => (
                              <div class="comment" key={item.id}>
                                <a class="avatar">
                                  <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" />
                                </a>
                                <div class="content">
                                  <a class="author" style={{width: "20vh"}}>
                                    {item.name.length < 2 ? (
                                      <span style={{color: "red"}}>
                                        Anonymous
                                      </span>
                                    ) : (
                                      <>{item.name}</>
                                    )}
                                  </a>
                                  <div class="metadata">
                                    <span class="date">
                                      {item.posted &&
                                        formatTimestamp(item.posted)}
                                    </span>
                                  </div>
                                  <div class="text">
                                    <p>{item.comment}</p>
                                  </div>
                                  <div class="actions"></div>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <i style={{color: "grey"}}>No comments yet...</i>
                        )}
                      </div>
                      {success && (
                        <Alert
                          variant="success"
                          style={{fontSize: "11px", textAlign: "center"}}
                        >
                          {success}
                        </Alert>
                      )}
                      <hr></hr>
                      <form class="ui reply form">
                        <div className="row">
                          <div className="col-lg-8 my-2">
                            <Form.Input
                              required={true}
                              placeholder="Write your comment..."
                              onChange={(e) => {
                                setComment(e.target.value);
                                handleInputChange(e);
                              }}
                              value={comment}
                            />
                          </div>
                          <div className="col-lg-4 my-2">
                            <Form.Input
                              placeholder="Username"
                              required={true}
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                            />
                          </div>
                        </div>

                        {comment.length < 3 ? (
                          <>
                            <button
                              style={{marginTop: "5px"}}
                              type="submit"
                              disabled
                              class="ui blue labeled submit icon button"
                            >
                              <i class="icon edit"></i> Add Comment
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              style={{marginTop: "5px"}}
                              type="submit"
                              class="ui blue labeled submit icon button"
                              onClick={handleComment}
                            >
                              <i class="icon edit"></i> Add Comment
                            </button>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                  {/* //Comments */}
                </div>
                <aside class="col-md-4 my-4">
                  <div class="widget post-widget">
                    <h5>About author</h5>
                    <hr></hr>
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-lg-4">
                          <div class="about-author">
                            <div class="about-author-img">
                              <div class="author-img-wrap">
                                <img
                                  class="img-fluid"
                                  alt="authorAvatar"
                                  src={blog.authorAvatar}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-8 my-2">
                          <div
                            class="author-details"
                            style={{
                              fontSize: "18px",
                              textAlign: "left",
                            }}
                          >
                            <span
                              class="blog-author-name"
                              style={{fontWeight: "800"}}
                            >
                              {blog.authorName}
                            </span>

                            <div class="blog-content">
                              <p
                                style={{textAlign: "justify", fontSize: "12px"}}
                              >
                                {blog.authorDesc}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {blog.authorName2 == null ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          <div class="row my-2">
                            <div class="col-lg-4">
                              <div class="about-author">
                                <div class="about-author-img">
                                  <div class="author-img-wrap">
                                    <img
                                      class="img-fluid"
                                      alt="authorAvatar"
                                      src={blog.authorAvatar2}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-8 my-2">
                              <div
                                class="author-details"
                                style={{
                                  fontSize: "18px",
                                  textAlign: "left",
                                }}
                              >
                                <span
                                  class="blog-author-name"
                                  style={{fontWeight: "800"}}
                                >
                                  {blog.authorName2}
                                </span>

                                <div class="blog-content">
                                  <p
                                    style={{
                                      textAlign: "justify",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {blog.authorDesc2}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div class="widget post-widget">
                    <h6
                      class="btn"
                      style={{
                        color: "white",
                        background:
                          "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,169,210,1) 0%, rgba(0,212,255,1) 100%)",
                      }}
                    >
                      Published by WakeWithWellness
                    </h6>
                    <p
                      style={{
                        fontSize: "11px",
                        textAlign: "justify",
                        color: "grey",
                      }}
                    >
                      Wake With Wellness provide a platform for young writers to
                      showcase their work on the website, allowing them to
                      express their ideas on social awareness and promote
                      advocacy. By publishing blogs and promoting their work
                      through social media and other channels, Wake With
                      Wellness is helping to raise awareness about important
                      health topics and encouraging people to take action to
                      improve their health and well-being.
                    </p>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyList />
      )}
    </>
  );
};

export default Blog;
