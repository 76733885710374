import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useCollectionData } from "react-firebase-hooks/firestore";

function DriveD() {
  const [members, setMembers] = useState([]);

  const ref = db
    .collection("Donations")
    .doc("FoodDrive2024Delhi")
    .collection("Donators");

  //REALTIME GET FUNCTION
  function getMembers() {
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setMembers(items);
    });
  }

  useEffect(() => {
    getMembers();
    // eslint-disable-next-line
  }, []);

  const mapContainerStyle = {
    height: "400px",
    width: "100%",
  };

  const center = {
    lat: 20.5937, // Set to the default center latitude
    lng: 78.9629, // Set to the default center longitude
  };

  const zoom = 4; // Set the default zoom level

  // Replace 'locations' with the name of your Firestore collection
  const query = db
    .collection("Donations")
    .doc("FoodDrive2024Delhi")
    .collection("Donators");
  const [locations] = useCollectionData(query, { idField: "id" });
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
  };

  const handleInfoWindowClose = () => {
    setSelectedLocation(null);
  };

  //////////////////////////////////////////////////
  const [totalDonation, setTotalDonation] = useState(0);

  useEffect(() => {
    const fetchTotalDonation = async () => {
      try {
        const donationsRef = db
          .collection("Donations")
          .doc("FoodDrive2024Delhi")
          .collection("Donators"); // Replace with your collection name
        const donationsSnapshot = await donationsRef.get();

        let sum = 0;

        donationsSnapshot.forEach((donationDoc) => {
          const { amount } = donationDoc.data();
          sum += +amount;
        });

        setTotalDonation(sum);
      } catch (error) {
        console.error("Error fetching total donation:", error);
      }
    };

    fetchTotalDonation();
  }, []);
  const progressBarStyle = {
    // width: `5000 / 5000) * 100}%`, // Adjust the divisor based on your goal
    width: `${(totalDonation / 7000) * 100}%`, // Adjust the divisor based on your goal
    height: "49px",
    backgroundColor: "#4183c4",
    borderRadius: "5px",
  };

  return (
    <>
      <section
        id="donation"
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: "10vh" }}
      >
        <div className="container" style={{ marginBottom: "30px" }}>
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <h1>Flood Donation Drive 2024</h1>
              <h2>Balipur, Baksha, Assam</h2>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div
          className="container-fluid"
          style={{ marginTop: "6vh", maxWidth: "200vh" }}
        >
          {/* <div style={{ marginTop: "-20px" }}>
            <LoadScript googleMapsApiKey="AIzaSyDoiXHxMG_vZPHz0eeJj5s661xhfU-Y4_4">
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={zoom}
              >
                {locations &&
                  locations.map((location, index) => (
                    <Marker
                      key={index}
                      data={location}
                      position={{
                        lat: location.latitude, // Replace with your latitude field
                        lng: location.longitude, // Replace with your longitude field
                      }}
                      title={location.district}
                      onClick={() => handleMarkerClick(location)}
                      icon={{
                        url:
                          selectedLocation === location
                            ? "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                            : "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                        scaledSize: new window.google.maps.Size(30, 30),
                      }}
                    />
                  ))}

                {selectedLocation && (
                  <InfoWindow
                    position={{
                      lat: selectedLocation.latitude,
                      lng: selectedLocation.longitude,
                    }}
                    onCloseClick={handleInfoWindowClose}
                  >
                    <div style={{ width: "40vh" }}>
                      <h5>Location: {selectedLocation.district}</h5>
                      <p>Name: {selectedLocation.name}</p>
                     
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            </LoadScript>
          </div>
          <br /> */}
          <div>
            <div
              style={{
                border: "1px solid #4183c4",
                height: "50px",
                width: "100%",
                borderRadius: "7px",
              }}
            >
              <div style={progressBarStyle}></div>
            </div>
            <h6
              style={{
                color: "#4183c4",
                marginTop: "5px",
                fontSize: "19px",
                fontWeight: "bold",
              }}
            >
              ₹{totalDonation} Raised |{" "}
              <span style={{ color: "#acaeb0" }}>₹7000 Goal</span>
            </h6>

            <p style={{ color: "green" }}>***Primary Goal Reached</p>
          </div>

          <h3>Our Contributors</h3>

          <div className="row my-2">
            {members
              .filter((data) => data.status === "Approved")
              .sort((a, b) => b.amount - a.amount)
              .map((data, index) => (
                <div className="col-lg-3 my-2" key={data.id}>
                  <div
                    class="card"
                    style={{
                      backgroundColor: "white",
                      border: "1px solid rgb(165 231 252)",
                    }}
                  >
                    <div class="row">
                      <div
                        className="col-3"
                        style={{
                          textAlign: "center",
                          paddingTop: "5px",
                          color: "rgb(4, 195, 255)",
                        }}
                      >
                        {/* <img
                          class="img-fluid"
                          style={{ width: "100%" }}
                          src="https://cdn.icon-icons.com/icons2/1161/PNG/512/1487716857-user_81635.png"
                        /> */}
                        <h2>{index + 1}</h2>
                      </div>
                      <div className="col-9">
                        <div class="content">
                          <a class="header">{data.name}</a>
                          <div
                            class="description"
                            style={{ fontSize: "11.5px" }}
                          >
                            <i>Place: </i>
                            <b>{data.hometown}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default DriveD;
