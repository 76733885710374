import React, { useState } from "react";
import { Modal, Button, Form, Icon, Image, Header } from "semantic-ui-react";
import { db } from "../firebase";
import { useParams, useNavigate } from "react-router-dom";
import GoToTop from "../GoToTop";
import success from "./assets/image/success.gif";
import { addDoc, updateDoc, doc, collection } from "firebase/firestore";
import groupp from "./assets/image/group.jpeg";

const initialState = {
  name: "",
  email: "",
  phone: "",
  whatsapp: "",
  department: "",
  college: "",
  agree: "Agree",
  time: "",
  status: "Pending",
  exp: "",
};

const Recruitment = () => {
  function refreshPage() {
    window.open("https://chat.whatsapp.com/Jyfg8ZNHTpvKSibznqSG8d", "_blank");
    setTimeout(() => {
      window.location.reload(false);
    }, 5000);
  }

  const [data, setData] = useState(initialState);
  const {
    name,
    email,
    phone,
    whatsapp,
    college,
    department,
    agree,
    time,
    date,
    status,
    exp,
  } = data;

  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const today = new Date();
  data.time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  const current = new Date();
  data.date = `${current.getFullYear()}/${
    current.getMonth() + 1
  }/${current.getDate()}`;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};

    if (!name) {
      errors.name = "Name is required";
    }

    if (!email) {
      errors.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.email = "Invalid email address";
    }

    if (!phone) {
      errors.phone = "Contact is required";
    }

    if (!whatsapp) {
      errors.whatsapp = "Whatsapp is required";
    }

    if (!department) {
      errors.department = "Department is required";
    }

    if (!exp) {
      errors.exp = "Experience is required";
    }
    if (!college) {
      errors.college = "Workplace is required";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = validate();
    if (Object.keys(errors).length) return setErrors(errors);
    setIsSubmit(true);
    if (!id) {
      try {
        await addDoc(collection(db, "Recruitment"), {
          ...data,
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await updateDoc(doc(db, "Recruitment", id), {
          ...data,
        });
      } catch (error) {
        console.log(error);
      }
    }

    setOpen(true);
    setIsSubmit(false);
  };

  return (
    <>
      <GoToTop />
      <Modal
        style={{ position: "relative" }}
        size="tiny"
        onClose={() => setOpen(false)}
        open={open}
      >
        <Modal.Content image>
          <Image size="small" src={success} wrapped />
          <Modal.Description>
            <Header
              style={{
                color: "#19d400",
                fontSize: "40px",
                marginTop: "7px",
              }}
            >
              Success!
            </Header>
            <h4>Registration completed successfully!</h4>
            <p style={{ color: "grey" }}>
              You will be contacted soon,{" "}
              <i style={{ color: "black" }}>{name}</i>!
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Join Whatsapp"
            onClick={() => refreshPage()}
            positive
          />
        </Modal.Actions>
      </Modal>
      <section
        id="recrut"
        className="d-flex align-items-center justify-content-center"
      >
        <div className="container" style={{ marginBottom: "30px" }}>
          <div
            className="row justify-content-center"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div className="col-xl-6 col-lg-8">
              <h1>Recruitment'24</h1>
              <h2>To help with community projects</h2>
            </div>
          </div>
        </div>
      </section>

      <div
        className="container-fluid"
        id="partb"
        style={{ marginTop: "1vh", maxWidth: "200vh" }}
      >
        <div className="content">
          <div className="row">
            <div className="col-lg-6" style={{ marginTop: "5vh" }}>
              <img style={{ width: "100%" }} src={groupp} alt="" />
            </div>

            <div
              className="col-lg-6"
              style={{
                marginTop: "5vh",

                padding: "5px",
              }}
            >
              <div class="container" id="formrecruit">
                <div class="row">
                  <div class="container">
                    <div class="form-content">
                      <div class="form-items">
                        <h3>Register Today</h3>
                        <p>Fill in the data below.</p>
                        <hr></hr>
                        <Form
                          class="requires-validation"
                          form
                          action=""
                          onSubmit={handleSubmit}
                        >
                          <div class="col-md-12">
                            <div className="row">
                              <div className="col-lg my-2">
                                {" "}
                                <Form.Input
                                  error={
                                    errors.name
                                      ? { content: errors.name }
                                      : null
                                  }
                                  label="Full Name"
                                  name="name"
                                  placeholder="Name"
                                  onChange={handleChange}
                                  value={name}
                                />
                              </div>
                              <div className="col-lg my-2">
                                {" "}
                                <Form.Input
                                  error={
                                    errors.email
                                      ? { content: errors.email }
                                      : null
                                  }
                                  label="Email"
                                  name="email"
                                  placeholder="Email"
                                  onChange={handleChange}
                                  value={email}
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div className="row">
                              <div className="col-lg my-2">
                                {" "}
                                <Form.Input
                                  error={
                                    errors.phone
                                      ? { content: errors.phone }
                                      : null
                                  }
                                  label="Contact"
                                  name="phone"
                                  type="tel"
                                  placeholder="Contact"
                                  onChange={handleChange}
                                  value={phone}
                                />
                              </div>
                              <div className="col-lg my-2">
                                {" "}
                                <Form.Input
                                  error={
                                    errors.whatsapp
                                      ? { content: errors.whatsapp }
                                      : null
                                  }
                                  label="Whatsapp"
                                  name="whatsapp"
                                  type="tel"
                                  placeholder="Whatsapp"
                                  onChange={handleChange}
                                  value={whatsapp}
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12 my-2">
                            <Form.Field
                              label="Department"
                              error={
                                errors.department
                                  ? { content: errors.department }
                                  : null
                              }
                              style={{ marginTop: "-1px", height: "4.8vh" }}
                              name="department"
                              control="select"
                              value={department}
                              onChange={handleChange}
                            >
                              <option
                                selected
                                value=" "
                                style={{ color: "grey" }}
                              >
                                --Select--
                              </option>

                              <option value="Curation Department">
                                Curation Department
                              </option>
                              <option value="Editorial Department">
                                Editorial Department
                              </option>
                              <option value="Marketing Department">
                                Social Media Marketing Department
                              </option>
                              <option value="PR Department">
                                Public Relations Department
                              </option>
                              <option value="Events Department">
                                Events Department
                              </option>
                              <option value="Design & Media Department">
                                Design & Media Department
                              </option>
                            </Form.Field>
                          </div>

                          <div class="col-md-12">
                            <Form.Input
                              error={
                                errors.college
                                  ? { content: errors.college }
                                  : null
                              }
                              label="College/workplace"
                              name="college"
                              placeholder="College/workplace"
                              onChange={handleChange}
                              value={college}
                            />
                          </div>

                          <div class="col-md-12 my-3">
                            <Form.TextArea
                              error={
                                errors.exp ? { content: errors.exp } : null
                              }
                              label="Prior Experience in working for NGO/CBO"
                              name="exp"
                              placeholder="Prior Experience in working for NGO/CBO"
                              onChange={handleChange}
                              value={exp}
                            />
                          </div>

                          <div class="col-md my-4">
                            <Form.Checkbox
                              label=" I certify that the information I have provided is
                              true and complete to the best of my knowledge. I
                              am aware that this self declaration statement is
                              subject to review and verification and if such
                              information has been falsified,I may be terminated
                              from further proceedings."
                            />
                          </div>

                          <div class="col-md my-2">
                            <Form.Checkbox label="Agree to our Privacy Policy" />
                          </div>

                          <Form.Input
                            name="agree"
                            readOnly
                            hidden
                            value={agree}
                          />

                          <Form.Input
                            name="date"
                            readOnly
                            hidden
                            value={date}
                          />

                          <Form.Input
                            name="time"
                            readOnly
                            hidden
                            value={time}
                          />

                          <Form.Input
                            name="status"
                            readOnly
                            hidden
                            value={status}
                          />

                          <div class="text-right">
                            {isSubmit ? (
                              <div class="ui active inverted dimmer">
                                <div class="ui text loader">Please wait...</div>
                              </div>
                            ) : (
                              <>
                                <Button
                                  type="submit"
                                  color="primary"
                                  icon
                                  labelPosition="left"
                                  size="small"
                                >
                                  <Icon name="check" />
                                  Apply
                                </Button>
                              </>
                            )}
                            {/* <h5 style={{fontWeight:'500', color:'red', textAlign: 'center'}}>Registration closed</h5> */}
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Recruitment;
