import React, {useState, useEffect, Fragment} from "react";
import {db} from "../firebase";
import loader from "./assets/image/loader.gif";
import GoToTop from "../GoToTop";
import ModalGallery from "./ModalGallery";
function Members() {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);

  const ref = db.collection("members").orderBy("no");

  //REALTIME GET FUNCTION
  function getMembers() {
    setLoading(true);
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setMembers(items);
      setLoading(false);
    });
  }

  useEffect(() => {
    getMembers();
    // eslint-disable-next-line
  }, []);

  //AView Image
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({});
  const handleView = (data) => {
    setOpen(true);
    setModal(data);
  };

  return (
    <Fragment>
      <>
        <GoToTop />
        <div
          className="container-fluid"
          style={{marginTop: "17vh", maxWidth: "200vh", minHeight: "60vh"}}
        >
          <section id="team" className="team">
            <div className="section-title">
              <h2>Member Coordinators</h2>
            </div>
            <div className="container-fluid">
              <div className="row">
                {loading ? (
                  <div class="container justify-content-center">
                    <h4 style={{textAlign: "center"}}>
                      <img
                        src={loader}
                        alt=""
                        style={{width: "40vh", height: "auto"}}
                      />
                    </h4>
                  </div>
                ) : null}
                {members.map((data) => (
                  <div className="col-lg-3" key={data.id}>
                    <div className="member" style={{marginTop: "10px"}}>
                      <img
                        onClick={() => handleView(data)}
                        src={data.link}
                        className="rounded"
                        style={{
                          width: "50%",
                          height: "22vh",
                          cursor: "pointer",
                          objectFit: "cover",
                          marginBottom: "10px",
                        }}
                        alt=""
                      />
                      {open && (
                        <ModalGallery
                          open={open}
                          setOpen={setOpen}
                          // handleDelete={handleDelete}
                          {...modal}
                        />
                      )}

                      <div className="member-info" style={{marginTop: "-22px"}}>
                        <h5
                          class="badge rounded-pill"
                          style={{
                            backgroundColor: "#d7ffd4",
                            fontSize: "16px",
                            marginBottom: "15px",
                            color: "green",
                          }}
                        >
                          Active
                        </h5>
                        <h4
                          style={{
                            marginTop: "-8px",
                            textTransform: "capitalize",
                          }}
                        >
                          {data.name}
                        </h4>
                        <span style={{fontSize: "12px", marginTop: "-5px"}}>
                          ( {data.department} )
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </>
    </Fragment>
  );
}

export default Members;
