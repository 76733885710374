import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Privacy from "./components/Policy/PrivacyPolicy";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import Team from "./components/Team";
import PageNotFound from "./components/PageNotFound";
import Members from "./components/Members";

import Footer from "./components/Footer";
import BHome from "./components/Blogs/pages/Home/index";
import Blog from "./components/Blogs/pages/Blog/index";
import Fundraiser from "./components/Fundraiser";
import Recruitment from "./components/Recruitment";
import Cat from "./components/videos/Cat";
import Events from "./components/Events";
import NEconvene from "./components/NEconvene";
import PaymentSuccess from "./components/PaymentSuccess";
import Success from "./components/SuccessPage";
import BoardRecruit from "./components/BoardRecruit";
import Donation from "./components/Extra/Donation";
import DriveC from "./components/Extra/DriveC";
import DriveB from "./components/Extra/DriveB";
import DriveA from "./components/Extra/DriveA";
import DriveD from "./components/Extra/DriveD";
import DriveAA from "./components/Extra/DriveAA";
import TermsNConditions from "./components/Policy/TermsNConditions";
function App() {
  return (
    <div className="www">
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route
          path="/team"
          element={
            <>
              <Team /> <Members />
            </>
          }
        />
        <Route path="/submission_success" element={<Success />} />

        <Route path="/blogs" element={<BHome />} />
        <Route path="/blog/:id" element={<Blog />} />
        <Route
          path="/fundraiser"
          element={
            <>
              <Fundraiser />
            </>
          }
        />
        <Route path="/recruitment" element={<Recruitment />} />
        {/* <Route path="/volunteership" element={<Volunteership />} /> */}
        <Route path="/videos" element={<Cat />} />

        <Route path="/events" element={<Events />} />
        <Route path="/success" element={<PaymentSuccess />} />
        <Route path="/privacypolicy" element={<Privacy />} />
        <Route path="/terms_conditions" element={<TermsNConditions />} />
        <Route path="/neconvene22" element={<NEconvene />} />

        <Route path="/donation" element={<Donation />} />

        <Route path="*" element={<PageNotFound />} />

        <Route path="/food_drive_2021" element={<DriveAA />} />
        <Route path="/flood_drive_2023" element={<DriveC />} />
        <Route path="/Hepah_From_Welfare_to_Well_being" element={<DriveB />} />
        <Route path="/Medical_Health_Checkup" element={<DriveA />} />
        <Route path="/flood_drive_2024_" element={<DriveD />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
